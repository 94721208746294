import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const [targetDiv, setTargetDiv] = useState<Element | null>(null);

  useEffect(() => {
    setMounted(true);
    const targetDivRef = typeof document && document?.querySelector('#portal');
    setTargetDiv(targetDivRef);
    return () => setMounted(false);
  }, []);


  return mounted && targetDiv
    ? <>{createPortal(children, targetDiv)}</>
    : null;
};

export default Portal;
