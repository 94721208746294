import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: ColorsPalette.coralPinkLight
    },
    borderRadius: 0,
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '160%'
  }
}))(MenuItem);
