import { EGenderType } from '@constants/gender';
import { getMediaUrl } from '@constants/urls';
import {
  IWrappedCategoryMapped,
  IWrappedCategorySectionMapped,
} from '@interfaces/category';
import { IMegaMenuCategory, IMegaMenuLinkDataObject } from '@interfaces/megaMenu';
import { IImportantMenu, IImportantMenuItem, IPageData } from '@interfaces/pageData/reducer';
import { generateUrlForManagementCategory } from '@reducers/pageData/useState';
import { mapGenderIdToType, mapGenderTypeToBottomType } from '@utils/gender';

const getWrappedCategories = (categoryWrapped: IWrappedCategorySectionMapped[], sexId: number): IWrappedCategoryMapped[] => {
  const currentGender: IWrappedCategorySectionMapped | undefined = categoryWrapped.find(
    (category) => category.sexId === sexId
  );

  return currentGender?.popularCategories.filter(({ important }) => important === true) || [];
};

const getImportantMenuItems = (pageData: IPageData, genderType: EGenderType | string): IImportantMenuItem[] => {
  const importantMenu: IImportantMenu = pageData['important-menu'] || {
    bottomKids: [],
    bottomMen: [],
    bottomWomen: [],
    brandsKids: [],
    brandsMen: [],
    brandsWomen: [],
    kids: [],
    men: [],
    women: [],
  };

  return importantMenu[genderType];
};

const getImportantMenuCategories = (
  pageData: IPageData,
  genderType: EGenderType | string,
  isMobile: boolean = false
): IMegaMenuCategory[] => {
  const importantMenuItems: IImportantMenuItem[] = getImportantMenuItems(pageData, genderType);
  const itemsByDeviceSize: IImportantMenuItem[] = importantMenuItems.filter(
    ({ showOnlyOnMobile }) => isMobile || showOnlyOnMobile === false
  );
  return itemsByDeviceSize.map(({ icon, name, url }) => ({
    label: name,
    mobilePictureUrl: icon ? getMediaUrl(icon) : null,
    url,
  }));
};

const getWrappedMenuCategories = (
  categoryWrapped: IWrappedCategorySectionMapped[],
  genderType: EGenderType,
  pageData: IPageData,
  sexId: number
): IMegaMenuCategory[] => {
  const importantCategories: IWrappedCategoryMapped[] = getWrappedCategories(categoryWrapped, sexId);
  const genderPage: IMegaMenuLinkDataObject | null =
    (pageData['mega-menu'] || []).find(
      (dropdownItem) => dropdownItem.dropdownType === genderType && dropdownItem.isRootItem
    ) || null;

  return importantCategories.map((cat) => {
    const { categoryLocal, mobilePictureUrl } = cat;
    const url = generateUrlForManagementCategory(cat, genderPage?.dropdownItems?.page || '') || '';
    return {
      label: categoryLocal,
      mobilePictureUrl,
      url,
    };
  });
};

export const getImportantCategories = (
  dataKey: string,
  sexId: number,
  categoryWrapped: IWrappedCategorySectionMapped[],
  pageData: IPageData,
  isMobile: boolean = false
): IMegaMenuCategory[] | undefined => {
  if (dataKey === 'important') {
    const genderType = mapGenderIdToType(sexId) || EGenderType.women;
    const importantMenuCategories: IMegaMenuCategory[] = getImportantMenuCategories(pageData, genderType, isMobile);
    const wrappedCategories: IMegaMenuCategory[] = getWrappedMenuCategories(
      categoryWrapped,
      genderType,
      pageData,
      sexId
    );

    return [...importantMenuCategories, ...wrappedCategories];
  }

  return undefined;
};

export const getImportantMenuBottomItems = (
  sexId: number,
  pageData: IPageData,
  isMobile: boolean = false
): IMegaMenuCategory[] | undefined => {
  const genderType = mapGenderIdToType(sexId) || EGenderType.women;
  const bottomType = mapGenderTypeToBottomType(genderType) || '';
  return getImportantMenuCategories(pageData, bottomType, isMobile);
};
