import dynamic from 'next/dynamic';
import React from 'react';
import { useSelector } from 'react-redux';

import AccountLayout from '@components/pages/account/Layout';
import WishListLayout from '@components/pages/wishList/Layout';
import { mobileMode } from '@constants/config';
import { PageTypes } from '@constants/pageTypes';
import { IStore } from '@interfaces/store';

const LayoutMain = dynamic(()=>import ('@components/layout/main'));
const LayoutApp = dynamic(()=>import('@components/layout/mobileApp'));

interface ILayoutProps {
  children: any;
}

const Layout: React.FunctionComponent<ILayoutProps> = ({ children }) => {

  const pageType = useSelector((state: IStore) => state.pageData.type);
  if (mobileMode) {
    switch (pageType) {
      case PageTypes.account:
      case PageTypes.account_orders:
      case PageTypes.account_delivery:
      case PageTypes.account_details:
      case PageTypes.account_linkedAccounts:
      case PageTypes.account_changePassword:
      case PageTypes.account_payment:
      case PageTypes.account_notifications:
      case PageTypes.account_help:
        return (<AccountLayout>
          {children}
        </AccountLayout>);
      default:
        return (
          <LayoutApp>
            {children}
          </LayoutApp>
        );
    }
  }

  switch (pageType) {
    case PageTypes.account:
    case PageTypes.account_orders:
    case PageTypes.account_delivery:
    case PageTypes.account_details:
    case PageTypes.account_linkedAccounts:
    case PageTypes.account_changePassword:
    case PageTypes.account_payment:
    case PageTypes.account_notifications:
    case PageTypes.account_help:
      return (<AccountLayout>
        {children}
      </AccountLayout>);
    case PageTypes.wishlist:
      return (<WishListLayout>
        {children}
      </WishListLayout>);
    case PageTypes.cart:
      return (<>
        {children}
      </>);
    default:
      return (
        <LayoutMain>
          {children}
        </LayoutMain>
      );
  }
};

export default Layout;
