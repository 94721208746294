import classNames from 'classnames';
import React from 'react';

import ArrowNextIcon from '@icons/ArrowNext';
import { IAccountMenuItem } from '@interfaces/account';
import { IStore } from '@interfaces/store';
import { pushOrReloadRoute } from '@utils/routes';
import { useSelector } from 'react-redux';

import styles from './styles';

interface IAccountMenuItemProps {
  item: IAccountMenuItem;
}

const AccountMenuItem: React.FunctionComponent<IAccountMenuItemProps> = ({ item }) => {
  const classes = styles();
  const data = useSelector((state: IStore) => state.pageData.data);
  const type = useSelector((state: IStore) => state.pageData.type);
  const goToPage = () => {
    if (item.type && data.pages) {
      const page = data.pages.find((menuItem) => menuItem.type && menuItem.type === item.type);
      if (page && type !== page.type) {
        pushOrReloadRoute(page.url);
      }
    } else if (item.action) {
      item.action();
    }
  };
  return (
    <div className={classes.menuItem} onClick={goToPage}>
      <div className={classes.menuItemIcon}>{item.icon}</div>
      <div className={classNames(classes.menuItemLabel)}>{item.label}</div>
      <div className={classes.menuItemIconForward}>
        <ArrowNextIcon />
      </div>
    </div>
  );
};

export default AccountMenuItem;
