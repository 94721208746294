import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import stylesCart from './../../styles';
import styles from './styles';

export enum EBage {
  green = 'green',
  yellow = 'yellow',
  red = 'red'
}

const Badge: React.FunctionComponent<{ className?: string; color: EBage } & PropsWithChildren> = ({ children, className, color }) => {
  const classes = styles();
  const classesCart = stylesCart();
  return (
    <div className={classNames(classes.bage, classesCart.text14, className, {
      [classes.priceGreen]: color === EBage.green,
      [classes.priceYellow]: color === EBage.yellow,
      [classes.priceRed]: color === EBage.red
    })}>
      {children}
    </div>
  );
};

export default Badge;
