import { mobileSession } from '@constants/config';
import { INativeSettings } from '@reducers/nativeSettings/types';
import { getNativeSettingsCookie, setNativeSettingsCookie } from '@utils/cookies';
import { getNativeSettingsFromLocalStorage, setNativeSettingsToLocalStorage } from '@utils/localStorage';

export const setNativeSettingsToBrowserStorage = (context, value: INativeSettings): void => {
  const valueString = JSON.stringify(value);
  mobileSession ? setNativeSettingsToLocalStorage(valueString) : setNativeSettingsCookie(context, valueString);
};

export const getNativeSettingsFromBrowserStorage = (context): INativeSettings => {
  const value = mobileSession ? getNativeSettingsFromLocalStorage() : getNativeSettingsCookie(context);
  return value ? JSON.parse(value) : {};
};
