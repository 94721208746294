import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import ErrorBoundary from '@components/common/ErrorBoundary';
import Hidden from '@components/common/Hidden';
import LayoutMain from '@components/layout/main';
import LayoutApp from '@components/layout/mobileApp';
import AccountCustomPage from '@components/pages/account/CustomPage';
import AccountMenuUnAuth from '@components/pages/account/Menu/unAuth';
import { accountUnProtectedPages } from '@components/pages/account/pages';
import { mobileMode } from '@constants/config';
import { PageTypes } from '@constants/pageTypes';
import { IStore } from '@interfaces/store';

const AccountLayout: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const user = useSelector((state: IStore) => state.account.user);
  const type = useSelector((state: IStore) => state.pageData.type);
  return (
    <ErrorBoundary>
      {mobileMode ? (
        <LayoutApp>
          {user || accountUnProtectedPages.includes(type as PageTypes) ? children : <AccountMenuUnAuth />}
        </LayoutApp>
      ) : (<>
          <Hidden mdDown>
            <LayoutMain>
              {user ? children : <AccountCustomPage />}
            </LayoutMain>
          </Hidden>
          <Hidden mdUp>
            <LayoutApp>
              {user ? children : <AccountCustomPage />}
            </LayoutApp>
          </Hidden>
        </>
      )}
    </ErrorBoundary>);
};
AccountLayout.displayName = 'AccountLayout';
export default AccountLayout;
