import dynamic from 'next/dynamic';
import React from 'react';

export const LOOK_BOOK_VIEW_LIST = 'list';
export const LOOK_BOOK_VIEW_DETAIL = 'detail';

const LookBookList = dynamic(()=>import('./LookBookList'));
const LookBookDetail = dynamic(()=>import('./LookBookDetail'));

const LookBook: React.FC<{ view: string }> = ({ view }) => {
  return (
    <>
      {view === LOOK_BOOK_VIEW_LIST && <LookBookList />}
      {view === LOOK_BOOK_VIEW_DETAIL && <LookBookDetail />}
    </>
  );
};

export default LookBook;
