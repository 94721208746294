import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { mobileMode } from '@constants/config';
import { headerHeightSm } from '@constants/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme) =>
  createStyles({
    action: {
      '&:hover': {
        color: ColorsPalette.hoverActionCTA,
      },
      '&:hover&$hoverFill': {
        '& path': {
          fill: ColorsPalette.hoverActionCTA,
        },
      },
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 700,
    },
    containerAction: {
      '& img': {
        maxHeight: '32px',
        maxWidth: '32px',
      },
      flex: '1 1 160px',
      width: '160px',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flex: '1 1 57px',
        minWidth: '57px',
        width: 'auto',
      },
    },
    containerActionLeft: {
      alignItems: 'center',
      height: '100%',
      justifyContent: 'center',
    },
    containerActionRight: {
      '& a': {
        color: ColorsPalette.primary,
        textDecorationLine: 'none',
      },
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
    containerHeader: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      fontFamily: FontCollection.raleway,
      justifyContent: 'space-between',
      padding: '16px 10px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        height: headerHeightSm,
        padding: '0',
      },
    },
    containerTitle: {
      color: ColorsPalette.primary,
      display: 'block',
      flex: '1 1 calc(100% - 24px)',
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '160%',
      overflow: 'hidden',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    hoverFill: {
      '& path': {
        fill: ColorsPalette.primary,
      },
    },
    leftActionCoralPink: {
      '& $containerActionLeft svg path': {
        fill: ColorsPalette.coralPinkLight,
        stroke: mobileMode ? 'transparent' : 'none',
      },
      '& $containerTitle': {
        color: ColorsPalette.coralPinkLight,
      },
    },
    stores: {
      '& $containerActionLeft': {
        backdropFilter: 'blur(16px)',
        background: 'rgba(255, 255, 255, 0.2)',
      },
      '& $containerActionLeft svg path': {
        fill: ColorsPalette.neutral2,
        stroke: mobileMode ? 'rgba(255, 255, 255, 0.2)' : 'none',
      },
      '& $containerActionLeft svg:hover path': {
        fill: ColorsPalette.neutral2,
      },
    },
  }));
