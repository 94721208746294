import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from '@interfaces/store';

import { UseScrollContext } from '@context/useScroll';

import HeaderStyles from './Header.styles';

const HeaderPin: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const classes = HeaderStyles({});
  const mobileMenu = useSelector((state: IStore) => state.page.mobileMenu.open);

  return (
    <UseScrollContext.Consumer>
      {({ fixElement, unpinElement }) => (<>
        <header id={'chrome-sticky-header'} className={classNames(classes.header, {
          [classes.headerWrapFixed]: fixElement || mobileMenu,
          [classes.headerWrapFixedMM]: mobileMenu,
          [classes.headerWrapUnpinned]: !fixElement && unpinElement && !mobileMenu,
        })}>
          {children}
        </header>
        {mobileMenu && (<div className={classes.headerCompensation} />)}
      </>)}
    </UseScrollContext.Consumer>


  );
};

export default HeaderPin;
