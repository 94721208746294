import { useDispatch, useSelector } from 'react-redux';

import {
  acPageChangeListSize,
  acPageSideMenuPageAction,
  acPageSideMenuStateAction,
} from '@actions/acPage';
import { TThunkDispatch } from '@interfaces/index';
import { ESideMenuPage, IPageStore } from '@interfaces/page/store';
import { IStore } from '@interfaces/store';

interface IUsePageStore extends IPageStore {
  onChangeListSize: (state: boolean) => void;
  onSideMenuHandle: (state: boolean) => void;
  onSideMenuPageHandle: (page: ESideMenuPage) => void;
}

export const usePageStore: () => IUsePageStore = () => {
  const pageReducer: IPageStore = useSelector((state: IStore) => state.page);
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const result: IUsePageStore = {
    ...pageReducer,
    onChangeListSize: (state) => dispatch(acPageChangeListSize(state)),
    onSideMenuHandle: (state) => dispatch(acPageSideMenuStateAction(state)),
    onSideMenuPageHandle: (page) => dispatch(acPageSideMenuPageAction(page)),
  };
  return result;
};

export default usePageStore;


