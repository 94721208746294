import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { acPageCountrySelectAction } from '@actions/acPage';
import SubmitButton, { EButtonType } from '@components/common/SubmitButton';
import { useTranslation } from '@hooks/useTranslation';
import CartIcon from '@icons/Cart';
import { Check24Icon } from '@icons/check24';
import DenimIcon from '@icons/Denim';
import NotificationsIcon from '@icons/Notifications';
import { TThunkDispatch } from '@interfaces/index';
import { setFirstRun } from '@utils/common';

import stylesDialog from './../styles';
import styles from './style';

const PushNotifications: React.FunctionComponent = () => {
  const classesDialog = stylesDialog();
  const classes = styles();
  const dispatch = useDispatch<TThunkDispatch>();
  const { t } = useTranslation();
  const onCountrySelectHandle = useCallback((state) => dispatch(acPageCountrySelectAction(state)), [dispatch]);
  const [show, changeShow] = useState(true);

  const onSkip = () => {
    changeShow(false);
    setFirstRun('1', null);
  };

  const onContinue = () => {
    if (window) {
      window.location.href = 'registerpush://';
   //   setTimeout(() => changeShow(false), 1000);
    }
   // setFirstRun( '1', null);
  };

  const list = [
    {
      description: t('notifications.promo1'),
      icon: <CartIcon />,
    },
    {
      description: t('notifications.promo2'),
      icon: <Check24Icon />,
    },
    {
      description: t('notifications.promo3'),
      icon: <NotificationsIcon />,
    },
  ];
  return (
    <Modal
      className={classesDialog.modal}
      open={show}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onCountrySelectHandle(false);
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={show}>
        <div className={classesDialog.paper}>
          <div className={classNames(classesDialog.title, classes.title)}>
            {t('notifications.intro')}
          </div>
          <div className={classes.list}>
            {list.map((item, key) => <div key={'notification' + key} className={classes.item}>
              <div className={classes.itemIcon}>{item.icon}</div>
              <div className={classes.itemDescription}>{item.description}</div>
            </div>)}

          </div>
          <div className={classes.buttonsGroup}>
            <SubmitButton onClick={onContinue} startIcon={<DenimIcon />} endIcon={<div />}>
              {t('notifications.AllowButton')}
            </SubmitButton>
            <SubmitButton onClick={onSkip} type={EButtonType.secondary} endIcon={<div />}>
              {t('common.skip')}
            </SubmitButton>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default PushNotifications;
