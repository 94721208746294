import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React, {  useRef } from 'react';
import { useSelector } from 'react-redux';

import { mobileMode } from '@constants/config';
import { IStore } from '@interfaces/store';

const MobileMenu = dynamic(() => import('@components/layout/header/MobileMenu/index'), { ssr: mobileMode });

import MobileMenuStyles from './styles';


const MobileMenuWrapper: React.FunctionComponent<{}> = ({}) => {
  const classes = MobileMenuStyles();
  const mobileMenuOpen = useSelector((state: IStore) => state.page.mobileMenu.open || mobileMode);
  const contentRef = useRef<HTMLObjectElement | null>(null);

  return (
    <div
      ref={contentRef}
      className={classNames(classes.mobileMenuArea, {
        [classes.mobileMenu]: !mobileMode,
        [classes.mobileMenuApp]: mobileMode,
        [classes.mobileMenuShow]: mobileMenuOpen,
      })}
      id="mobile-menu-scrolled">
      {mobileMenuOpen && (<MobileMenu />)}
    </div>
  );
};

export default MobileMenuWrapper;
