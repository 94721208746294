import * as actions from '@constants/actions/translations';
import { ERedisCacheKey } from '@constants/cacheCallApi';
import { RequestMethods } from '@constants/types';
import { authStrapiUrl, defaultTranslationsUrl, translationStrapiUrl } from '@constants/urls';
import { IStore } from '@interfaces/store';
import { isServer } from '@utils/context';
import { CALL_API } from '../../services/SSRService';

export const acAuthInStrapi: (email: string, password: string) => any = (email, password) => ({
  [CALL_API]: {
    body: {
      identifier: email,
      password,
    },
    endpoint: authStrapiUrl(),
    method: RequestMethods.POST_METHOD,
    types: [
      actions.AUTH_STRAPI_REQUEST,
      actions.AUTH_STRAPI_SUCCESS,
      actions.AUTH_STRAPI_FAILED,
    ],
  },
});

export const acGetTranslationStrapi: (key, token) => any = (key, token) => ({
  [CALL_API]: {
    endpoint: translationStrapiUrl(key),
    headers:{
      Authorization: `Bearer ${token}`,
    },
    method: RequestMethods.GET_METHOD,
    types: [
      actions.TRANSLATIONS_GET_STRAPI_REQUEST,
      actions.TRANSLATIONS_GET_STRAPI_SUCCESS,
      actions.TRANSLATIONS_GET_STRAPI_FAILED,
    ],
  },
});

export const acSaveTranslationStrapi: (key, inputValues, token) => any = (key, inputValues, token) => ({
  [CALL_API]: {
    body: {
      ...inputValues
    },
    endpoint: translationStrapiUrl(key),
    headers:{
      Authorization: `Bearer ${token}`,
    },
    method: RequestMethods.PUT_METHOD,
    types: [
      actions.TRANSLATIONS_SAVE_STRAPI_REQUEST,
      actions.TRANSLATIONS_SAVE_STRAPI_SUCCESS,
      actions.TRANSLATIONS_SAVE_STRAPI_FAILED,
    ],
  },
});


const acGetTranslationDefaultAction: (origin) => any = (origin) => ({
  [CALL_API]: {
    cache: true,
    cacheOptions: ERedisCacheKey.getTranslations,
    endpoint: defaultTranslationsUrl(origin),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.TRANSLATIONS_GET_REQUEST,
      actions.TRANSLATIONS_GET_SUCCESS,
      actions.TRANSLATIONS_GET_FAILED,
    ],
  },
});

export const acGetTranslationDefault = () => (dispatch, getState: () => IStore) => {
  const {
    context: { host },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode) {
    const origin = isServer() ? 'http://' + host : window?.location?.origin;
    return dispatch(acGetTranslationDefaultAction(origin));
  }
};



const acGetTranslationDefaultPreviewAction: (origin) => any = (origin) => ({
  [CALL_API]: {
    cache: true,
    cacheOptions: ERedisCacheKey.getTranslations,
    endpoint: defaultTranslationsUrl(origin),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.TRANSLATIONS_PREVIEW_GET_REQUEST,
      actions.TRANSLATIONS_PREVIEW_GET_SUCCESS,
      actions.TRANSLATIONS_PREVIEW_GET_FAILED,
    ],
  },
});

export const acGetTranslationDefaultPreview = () => (dispatch, getState: () => IStore) => {
  const {
    context: { host },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode) {
    const origin = isServer() ? 'http://' + host : window?.location?.origin;
    return dispatch(acGetTranslationDefaultPreviewAction(origin));
  }
};
