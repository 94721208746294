import { mobileMode } from '@constants/config';
import React, { PropsWithChildren } from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import Hidden from '@components/common/Hidden';
import Layout from '@components/layout/main';

const WishListLayout: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (<ErrorBoundary>
    {mobileMode ? (
      <>
        {children}
      </>
    ) : (
      <>
        <Hidden mdDown>
          <Layout>
            {children}
          </Layout>
        </Hidden>
        <Hidden mdUp>
          {children}
        </Hidden>
      </>
    )}
  </ErrorBoundary>);
};

export default WishListLayout;
