import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { TypographyCollection } from '@themes/font';

export default makeStyles<Theme>((theme) =>
  createStyles({
    categoriesLinksContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    megaMenuLink: {
      ...{
        '& a, span': {
          color: ColorsPalette.primary,
          cursor: 'pointer',
          textDecoration: 'none',

          '&::before': {
            content: 'attr(data-content)',
            display: 'block',
            fontWeight: 'bold',
            height: 0,
            overflow: 'hidden',
            visibility: 'hidden',
          },
          '&:hover': {
            fontWeight: 'bold',
          },
        },
      },
      ...TypographyCollection.List1,
    },
    megaMenuLinks: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '59%',
    },
    megaMenuLinksContainer: {
      [theme.breakpoints.up('md')]: {
        marginRight: 60,
      },
      [theme.breakpoints.down('lg')]: {
        marginRight: 12,
      },
      display: 'flex',
      flexDirection: 'column',
    },
    megaMenuLinksHolder: {
      '& span': {
        marginBottom: 16,
        whiteSpace: 'nowrap',
      },
      display: 'flex',
      flexDirection: 'column',
    },
    megaMenuLinksTitle: {
      ...{
        height: '18px',
        marginBottom: 28,
        marginRight: 20,
        whiteSpace: 'nowrap',
      },
      ...TypographyCollection.HeadingMobileXS,
    },
  }),
);
