import React from 'react';

import BackButtonWithText from '@components/dialogs/CountryLanguage/common/BackButtonWithText';
import Languages from '@components/dialogs/CountryLanguage/Language/Languages';
import ModalTemplate from '@components/dialogs/CountryLanguage/ModalTemplate';
import { useTranslation } from '@hooks/useTranslation';
import { ICountrySelectItem } from '@interfaces/pageData/reducer';

interface LanguageSelectProps {
  country: ICountrySelectItem;
  onClose: () => void;
}

const LanguageSelect: React.FunctionComponent<LanguageSelectProps> = ({ country, onClose }) => {
  const { t } = useTranslation();
  return (
      <ModalTemplate body={<Languages country={country} />}
                     header={
                       <BackButtonWithText
                         clickHandler={onClose}
                         text={t('country_and_language_select.language_select_back_button_text') as string}
                       />}
                     title={t('country_and_language_select.language_select_heading', {
                       country: country.title,
                     })}
                     description={t('country_and_language_select.language_select_description')}
      />
  );
};

export default LanguageSelect;
