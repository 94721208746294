import React from 'react';

import Link from '@components/common/Link';
import MegaMenuPopularLink from '@components/layout/header/MegaMenu/Link';
import { useTranslation } from '@hooks/useTranslation';
import { IMegaMenuLinkDataObject, IMegaMenuLinksDataObject } from '@interfaces/megaMenu';

import megaMenuLinksStyles from './megaMenuLinks.styles';

interface IMegaMenuLinkProps {
  linkData: IMegaMenuLinkDataObject;
}

interface IMegaMenuLinksProps {
  links: Partial<IMegaMenuLinksDataObject>;
}

const MegaMenuLink: React.FunctionComponent<IMegaMenuLinkProps> = ({ linkData }) => {
  const classes = megaMenuLinksStyles();

  return (
    <span className={classes.megaMenuLink}>
      <Link legacyBehavior href={linkData.url || ''}>
        <a>{linkData.translation}</a>
      </Link>
    </span>
  );
};

const MegaMenuLinks: React.FunctionComponent<IMegaMenuLinksProps> = ({ links }) => {
  const classes = megaMenuLinksStyles();
  const { t } = useTranslation();

  return (
      <div className={classes.megaMenuLinks}>
        <div className={classes.megaMenuLinksContainer}>
          <span className={classes.megaMenuLinksTitle}>{t('mega_menu.trending')}</span>
          <div className={classes.megaMenuLinksHolder}>
            {links.trending?.slice(0, 8).map((trendingCategory, index) => {
              return <MegaMenuPopularLink linkData={trendingCategory} key={'trending-' + index} />;
            })}
          </div>
        </div>
        <div className={classes.megaMenuLinksContainer}>
          <span className={classes.megaMenuLinksTitle}>{t('mega_menu.categories')}</span>
          <div className={classes.categoriesLinksContainer}>
            <div className={classes.megaMenuLinksHolder}>
              {links.categories?.slice(0, 8).map((category, index) => {
                return <MegaMenuPopularLink linkData={category} key={'categories-' + index} />;
              })}
            </div>
          </div>
        </div>
        <div className={classes.megaMenuLinksContainer}>
          <span className={classes.megaMenuLinksTitle} />
          <div className={classes.megaMenuLinksHolder}>
            {links.categories?.slice(8).map((category, index) => {
              return <MegaMenuPopularLink linkData={category} key={'categories-' + (index + 8)} />;
            })}
          </div>
        </div>
        <div className={classes.megaMenuLinksContainer}>
          <span className={classes.megaMenuLinksTitle}>{t('mega_menu.top_brands')}</span>
          <div className={classes.megaMenuLinksHolder}>
            {links.brands?.map((topBrand, index) => {
              return <MegaMenuLink key={index} linkData={topBrand} />;
            })}
          </div>
        </div>
      </div>
  );
};

export default MegaMenuLinks;
