import { appNavigationHeightSm } from '@constants/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { mobileMenuTop } from '@components/layout/header/MobileMenu/constants';
import { ColorsPalette } from '@themes/colors';
import { defaultTransition } from '@themes/transition';
import { zIndex } from '@themes/zIndex';

export default makeStyles((theme) =>
  createStyles({
    box: {
      paddingTop: 0,
    },
    mobileMenu: {
      height: 'calc(100% - 48px)',
     // left: '-110vw',
      overflow: 'scroll',
      position: 'fixed',
      top: mobileMenuTop,
      transform: 'translateX(-110vw)',
      width: '100vw',
      willChange: 'transform',
      zIndex: zIndex.mobileMenu,
    },
    mobileMenuApp: {
      minHeight: `calc(100vh - ${appNavigationHeightSm}px)`,
      width: '100%',
    },
    mobileMenuArea: {
      backgroundColor: ColorsPalette.neutral2,
      display: 'flex',
      flexDirection: 'column',
      transition: defaultTransition,
    },
    mobileMenuSearch: {
      padding: '21px 16px 0 16px',
    },
    mobileMenuShow: {
    //  left: 0,

      transform: 'translateX(0)',
    },
    tabRoot: {
      borderBottom: '4px solid ' + ColorsPalette.neutral1,
      borderRadius: 0,
    },
    tabsIndicator: {
      height: 4,
    },
    tabsRoot: {
      backgroundColor: ColorsPalette.neutral2,
      marginTop: 24,
      position: 'sticky',
      top: -1,
    },
  }),
);
