import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { acPageCountrySelectAction } from '@actions/acPage';
import { imageUlrSuffix } from '@constants/urls';
import { TThunkDispatch } from '@interfaces/index';
import { ICountrySelectItem } from '@interfaces/pageData/reducer';
import { setLocale } from '@utils/countryLang';
import { pushOrReloadRoute } from '@utils/routes';
import Button from '../common/Button';

interface LanguagesProps {
  country: ICountrySelectItem;
}

const Languages: React.FunctionComponent<LanguagesProps> = ({ country }) => {
  const languages = country.languages;
  const dispatch = useDispatch<TThunkDispatch>();

  const onCountrySelectHandle = useCallback((state) => dispatch(acPageCountrySelectAction(state)), [dispatch]);
  const closeModal = () => onCountrySelectHandle(false);

  const handleLanguageSelect = (languageCode) => {
    const pathUrl = '/' + country.code + '/' + languageCode;
    setLocale(pathUrl);
    pushOrReloadRoute(pathUrl, false);
    closeModal();
  };

  useEffect(() => {
    if (languages.length === 1) {
      handleLanguageSelect(languages[0].code);
    }
  }, []);

  if (country === null) {
    return null;
  }

  return (
    <>
        {languages.map((language) => {
          const flag = language.flag;
          const text = language.title;
          const imageUrl = imageUlrSuffix + flag;
          const code = language.code;
          return (
            <Button
              flag={imageUrl}
              text={text}
              onClickHandler={() => {
                handleLanguageSelect(code);
              }}
              key={code}
            />
          );
        })}
    </>
  );
};

export default Languages;
