import React from 'react';

import { getMediaUrl } from '@constants/urls';

interface IStrapiImageProps {
  url: string;
}

const StrapiImage: React.FunctionComponent<IStrapiImageProps> = ({ url }) => {
  if (url.includes('youtube')) {
    return (
      <iframe
        width="524"
        height="295"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    );
  }
  if (url) {
    const imageUrl = getMediaUrl(url);
    return <img src={imageUrl} />;
  }
  return null;
};

export default StrapiImage;
