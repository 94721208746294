import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acMobileMenuChangeState } from '@actions/acPage';
import ErrorBoundary from '@components/common/ErrorBoundary';
import useScrollBlock from '@hooks/scrollBlock';
import BurgerIcon from '@icons/Burger';
import { Close24Icon } from '@icons/Close24';
import { TThunkDispatch } from '@interfaces/index';
import { IPageData } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { getMenuBarBackground, getMenuBarColor } from '@utils/siteSettings';
import AppBar from './AppBar';

import HeaderStyles from './Header.styles';

const HeaderMobile: React.FunctionComponent<{}> = () => {
  const data: IPageData = useSelector((state: IStore) => state.pageData.data);

  const classes = HeaderStyles({
    menuBarBackground: getMenuBarBackground(data),
    menuBarColor: getMenuBarColor(data),
  });
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();

  const mobileMenu = useSelector((state: IStore) => state.page.mobileMenu.open);
  const { allowScroll, blockScroll } = useScrollBlock();

  const toggleMobileMenu = useCallback(
    (state) => dispatch(acMobileMenuChangeState({ open: state })),
    [dispatch],
  );

  useEffect(() => {
    if (!mobileMenu) {
      allowScroll();
    } else {
      blockScroll();
    }
    return allowScroll;
  }, [mobileMenu]);


  return (
    <ErrorBoundary>
      <AppBar />
      <div className={classes.mobileMenuBar} onClick={() => toggleMobileMenu(!mobileMenu)}>
        {mobileMenu ? <Close24Icon /> : <BurgerIcon />}
      </div>
    </ErrorBoundary>
  );
};

export default HeaderMobile;
