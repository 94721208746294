import React from 'react';

const NotificationIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.707 7.74718C18.531 4.46772 15.5858 2.14754 12.1221 1.77195C8.6585 1.39636 5.28442 3.03129 3.43277 5.98244C1.58112 8.93359 1.57694 12.6829 3.422 15.6382L0.75 21.2502L6.361 18.5782C7.39466 19.2219 8.54714 19.6514 9.75 19.8412"
        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.25 7.75V9.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.25 7.75V9.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.75628 15.0002C8.45391 14.5394 7.4341 13.5078 6.98828 12.2002" stroke="black" strokeWidth="1.5"
            strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M17.8829 11.5001C19.9897 11.4904 21.9056 12.7195 22.7751 14.6386C23.6445 16.5577 23.3053 18.8085 21.9089 20.3861L22.5799 24.2501L19.0799 22.0921C17.0709 22.5533 14.9752 21.8225 13.6887 20.2119C12.4022 18.6014 12.1523 16.3961 13.046 14.5386C13.9396 12.6811 15.8186 11.5 17.8799 11.5001H17.8829Z"
            stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.75 16.375C15.5429 16.375 15.375 16.5429 15.375 16.75C15.375 16.9571 15.5429 17.125 15.75 17.125C15.9571 17.125 16.125 16.9571 16.125 16.75C16.125 16.5429 15.9571 16.375 15.75 16.375"
        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19.5 16.375C19.2929 16.375 19.125 16.5429 19.125 16.75C19.125 16.9571 19.2929 17.125 19.5 17.125C19.7071 17.125 19.875 16.9571 19.875 16.75C19.875 16.5429 19.7071 16.375 19.5 16.375"
        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default NotificationIcon;
