import CssBaseline from '@mui/material/CssBaseline';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import React from 'react';

import { getServerSidePropsBase } from '@actions/getServerSidePropsBase';
import IconsSpriteData from '@components/common/IconsSpriteData';
import TranslationProvider from '@components/common/TranslationProvider';
import PushNotificationsState from '@components/dialogs/PushNotifications/stateWrapper';
import Layout from '@components/layout';
import MetaData from '@components/layout/MetaData';
import SideMenuWrap from '@components/layout/SideMenu/SideMenuWrap';
import InitSessionInLocalStorage from '@components/main/InitSessionInLocalStorage';
import NativeMessages from '@components/main/NativeMessages';
import MainComponent from '@components/pages/Main';
import TitleDescription from '@components/SEO/TitleDescription';
import { mobileMode, mobileSession } from '@constants/config';
import { wrapper } from '../store/withRedux';


const SnackBarComponent = dynamic(() => import('@components/common/SnackBar'), { ssr: false });
const UserConsentState = dynamic(() => import('@components/dialogs/UserConsent'), { ssr: false });
const UserDataState = dynamic(() => import('@components/dialogs/UserData'), { ssr: false });
const InitData = dynamic(() => import('@components/common/InitData'), { ssr: false });
const UserDataRegisterModalState = dynamic(() => import('@components/dialogs/UserDataRegister/state'), { ssr: false });

const Params: NextPage<{}> = ({}) => {
  return (
    <TranslationProvider>
      <TitleDescription />
      <CssBaseline />
      <MetaData />
      <Layout>
        <IconsSpriteData />
        <MainComponent />
      </Layout>
      <SnackBarComponent />
      <InitData />
      <SideMenuWrap />
      {mobileMode && (<>
          {mobileSession && (<InitSessionInLocalStorage />)}
          <NativeMessages />
          <PushNotificationsState />
        </>
      )}
      <UserConsentState />
      <UserDataState />
      <UserDataRegisterModalState />
    </TranslationProvider>
  );
};


export const getServerSideProps = wrapper.getServerSideProps(getServerSidePropsBase);

export default Params;
