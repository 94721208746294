import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Chat from '@components/common/Chat';
import ErrorBoundary from '@components/common/ErrorBoundary';
import SubmitButton from '@components/common/SubmitButton';
import Badge, { EBage } from '@components/pages/cart/components/Badge';
import { useTranslation } from '@hooks/useTranslation';
import DenimIcon from '@icons/Denim';
import LoadingIcon from '@icons/Loading';
import { IStore } from '@interfaces/store';
import { isTrackingDisabledByAppSettings } from '@utils/validation';

import cartStyles from './../../styles';
import styles from './styles';

interface ICustomerSupportProps {
  closeModal: () => void;
}

const CustomerSupport: React.FunctionComponent<ICustomerSupportProps> = ({
  closeModal,
}) => {
  const classes = styles();
  const classesCart = cartStyles();
  const { t, tString } = useTranslation();
  const phoneNumber = `${tString('common.support_info_phone').slice(0, 4)} ${tString('common.support_info_phone').slice(4)}`;
  const [tawkInitState, setTawkInitState] = useState(0);
  const [chatLoading, setChatLoading] = useState(false);
  const trackingDisabled: boolean | null | undefined = useSelector((state: IStore) => state.nativeSettings.trackingDisabled);
  const trackingDisabledByAppPrompt = isTrackingDisabledByAppSettings(trackingDisabled);
  return (
    <ErrorBoundary>
      <div className={classes.customerSupport}>
        <div className={classes.customerSupportMain}>
          <div className={classes.customerSupportStatus}>
            <Badge color={EBage.green}>{t('common.support_status_online')}</Badge>
          </div>
          <div className={classNames(classesCart.title18Inter, classes.customerSupportTitle)}>
            {t('common.support_title')}
          </div>
          <div className={classesCart.text14}>{t('common.support_info_workTime')}</div>
          <a href={`tel:${phoneNumber.replace(/ /g, '')}`} className={classNames(classesCart.text14, classes.phone)}>
            {phoneNumber}
          </a>
        </div>
        <div className={classes.customerSupportButton}>
          <SubmitButton
            endIcon={<div />}
            startIcon={<DenimIcon />}
            onClick={() => (window.location.href = `mailto:${t('common.support_info_email')}`)}
          >
            {t('common.support_action_email')}
          </SubmitButton>
          <SubmitButton
            endIcon={<div />}
            startIcon={<DenimIcon />}
            onClick={() => (window.location.href = `tel:${t('common.support_info_phone')}`)}
          >
            {t('common.support_action_call')}
          </SubmitButton>
          {(!trackingDisabledByAppPrompt) && (
            <SubmitButton endIcon={chatLoading ? <LoadingIcon /> : <div />} startIcon={<DenimIcon />}
            onClick={() => {
            setChatLoading(true);
            setTawkInitState((initState) => { return initState + 1; });
          }}
            >
          {t('common.support_action_chat')}
            </SubmitButton>
          )}
        </div>
      </div>
      {(!trackingDisabledByAppPrompt) && (
      <Chat initState={tawkInitState} onOpen={() => {
          setChatLoading(false);
          closeModal();
        }}
        onClose={() => void 0} />
        )}
    </ErrorBoundary>
  );
};

export default CustomerSupport;
