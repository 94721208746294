import { findNearest } from '@components/pages/stores/Map2/utils';
import * as actions from '@constants/actions/stores';
import { ERedisCacheKey } from '@constants/cacheCallApi';
import { RequestMethods } from '@constants/types';
import {
  storesAvailabilityUrl,
  storesBrandUrl,
  storesCityUrl,
  storesCountryUrl,
  storesMallUrl,
  storesUrl,
} from '@constants/urls';
import { ICallApiAction, IMiddlewareSuccessResponse } from '@interfaces/fetchService';
import { ILocaleParamsShort, ILocaleParamsWithCountryShort } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import {
  IStoresCloseGeolocationErrorModal,
  IStoresCloseMapModal,
  IStoresFilterChange,
  IStoresFilterCountryChange,
  IStoresOpenGeolocationErrorModal,
  IStoresOpenMapModal,
} from '@interfaces/stores/actions';
import { IStoreCountry, IStoresFilter } from '@interfaces/stores/reducer';
import { CALL_API } from '../../services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';

interface TStoresGetListParams extends Partial<IStoresFilter> {
  country: string;
  lang: string;
}

type TStoresGetList = (locale: TStoresGetListParams) => ICallApiAction;
export const acStoresGetList: TStoresGetList = (locale) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(storesUrl(), {
      ...locale,
    }),
    method: RequestMethods.GET_METHOD,
    types: [actions.STORES_GET_LIST_REQUEST, actions.STORES_GET_LIST_SUCCESS, actions.STORES_GET_LIST_FAILED],
  },
});

export const acStoresFilterChange: (params: Partial<IStoresFilter>) => IStoresFilterChange = (params) => ({
  payload: params,
  type: actions.STORES_FILTER_CHANGE,
});

export const acStoresFilterCountryChange: (countryId: number) => IStoresFilterCountryChange = (countryId) => ({
  payload: countryId,
  type: actions.STORES_FILTER_COUNTRY_CHANGE,
});

export const acStoresChangeCountryAndGetList = (countryId: number) => async (dispatch, getState: () => IStore) => {
  await dispatch(acStoresFilterCountryChange(countryId));
  const {
    pageData: { countryCode, languageCode },
  } = getState();
  const params: TStoresGetListParams = {
    country: countryCode || '',
    countryId,
    lang: languageCode || '',
  };
  await dispatch(acStoresGetList(params));
};

type TStoresGetBrands = (locale: ILocaleParamsShort) => ICallApiAction;
export const acStoresGetBrands: TStoresGetBrands = (locale) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(storesBrandUrl(), {
      ...locale,
    }),
    method: RequestMethods.GET_METHOD,
    types: [actions.STORES_GET_BRANDS_REQUEST, actions.STORES_GET_BRANDS_SUCCESS, actions.STORES_GET_BRANDS_FAILED],
  },
});

type TStoresGetCities = (locale: ILocaleParamsWithCountryShort) => ICallApiAction;
export const acStoresGetCities: TStoresGetCities = (locale) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(storesCityUrl(), {
      ...locale,
    }),
    method: RequestMethods.GET_METHOD,
    types: [actions.STORES_GET_CITIES_REQUEST, actions.STORES_GET_CITIES_SUCCESS, actions.STORES_GET_CITIES_FAILED],
  },
});

type TStoresGetCountries = (locale: ILocaleParamsShort) => ICallApiAction;
export const acStoresGetCountries: TStoresGetCountries = (locale) => ({
  [CALL_API]: {
    cache: true,
    cacheOptions: ERedisCacheKey.storesGetCountries,
    endpoint: addParamsToUrl(storesCountryUrl(), {
      ...locale,
    }),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.STORES_GET_COUNTRIES_REQUEST,
      actions.STORES_GET_COUNTRIES_SUCCESS,
      actions.STORES_GET_COUNTRIES_FAILED,
    ],
  },
});

type TStoresGetMalls = (locale: ILocaleParamsShort) => ICallApiAction;
export const acStoresGetMalls: TStoresGetMalls = (locale) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(storesMallUrl(), {
      ...locale,
    }),
    method: RequestMethods.GET_METHOD,
    types: [actions.STORES_GET_MALLS_REQUEST, actions.STORES_GET_MALLS_SUCCESS, actions.STORES_GET_MALLS_FAILED],
  },
});

type TStoresGetAvailability = (locale: ILocaleParamsShort) => ICallApiAction;
export const acStoresGetAvailability: TStoresGetAvailability = (params) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(storesAvailabilityUrl(), {
      ...params,
    }),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.STORES_GET_AVAILABILITY_REQUEST,
      actions.STORES_GET_AVAILABILITY_SUCCESS,
      actions.STORES_GET_AVAILABILITY_FAILED,
    ],
  },
});

export const acGetStores = () => async (dispatch, getState: () => IStore) => {
  const {
    pageData: { countryCode, languageCode },
  } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  await dispatch(acStoresGetCountries(locale)).then(async (response: IMiddlewareSuccessResponse<IStoreCountry[]>) => {
    if (response.result && response.payload) {
      const currentCountry = response.payload.find((country) => country.countryCode === locale.country);
      if (currentCountry) {
        await dispatch(acStoresChangeCountryAndGetList(currentCountry.countryId));
      } else {
        await dispatch(acStoresGetList(locale));
      }
    }
  });
};

export const acStoresInit = () => acGetStores();

export const acStoresInitFilter = () => (dispatch, getState: () => IStore) => {
  const {
    pageData: { countryCode, languageCode },
    stores: {
      filter: { countryId },
    },
  } = getState();
  const params: TStoresGetListParams = {
    country: countryCode || '',
    lang: languageCode || '',
  };
  if (countryId) {
    params.countryId = countryId;
  }
  dispatch(acGetStoresFilterBrands());
  if (params.countryId) {
   return  dispatch(acStoresGetCities(params));
  }
};

export const acStoresInitFilterMall = () => (dispatch, getState: () => IStore) => {
  const {
    pageData: { countryCode, languageCode },
    stores: {
      filter: { countryId, cityId },
    },
  } = getState();
  const params: TStoresGetListParams = {
    cityId,
    country: countryCode || '',
    countryId,
    lang: languageCode || '',
  };
  return dispatch(acStoresGetMalls(params));
};

export const acGetStoresFilterBrands = () => (dispatch, getState: () => IStore) => {
  const {
    pageData: { countryCode, languageCode },
    stores: {
      filter: { countryId, cityId, groupId },
    },
  } = getState();
  const params: TStoresGetListParams = {
    country: countryCode || '',
    countryId,
    lang: languageCode || '',
  };
  if (cityId) {
    params.cityId = cityId;
  }
  if (groupId) {
    params.groupId = groupId;
  }
  return dispatch(acStoresGetBrands(params));
};

export const acStoresOpenMapModal: (id: number | null) => IStoresOpenMapModal = (id) => ({
  payload: id,
  type: actions.STORES_MAP_MODAL_OPEN,
});

export const acStoresCloseMapModal: () => IStoresCloseMapModal = () => ({
  type: actions.STORES_MAP_MODAL_CLOSE,
});

export const acStoresOpenGeolocationErrorModal: (
  error: GeolocationPositionError | null,
) => IStoresOpenGeolocationErrorModal = (id) => ({
  payload: id,
  type: actions.STORES_MODAL_GEOLOCATION_OPEN,
});

export const acStoresCloseGeolocationErrorModal: () => IStoresCloseGeolocationErrorModal = () => ({
  type: actions.STORES_MODAL_GEOLOCATION_CLOSE,
});

export const acFindNearestStore = () => async (dispatch, getState: () => IStore) => {
  const {
    stores: { list },
  } = getState();
  const nearest = await findNearest(list);
  if (nearest.result && nearest.item?.id) {
    dispatch(acStoresOpenMapModal(nearest.item?.id));
  } else {
    dispatch(acStoresOpenGeolocationErrorModal(nearest.error || null));
  }
};
