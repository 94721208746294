import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acSaveOneSignalUserId } from '@actions/acAccount';
import { acAlertShowAction } from '@actions/acAlert';
import { useTranslation } from '@hooks/useTranslation';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import { acPageNativeSettingsSet } from '@reducers/nativeSettings/actions';
import { setNativeSettingsToBrowserStorage } from '@reducers/nativeSettings/browserStorage';
import { pushOrReloadRoute } from '@utils/routes';

declare global {
  interface Window {
    responseHandler: {
      navigationPush: (params) => void;
      permissionDenied: (params) => void;
      setSettings: (params) => void;
    };
  }
}

const NativeMessages: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { t } = useTranslation();
  const onSetSettings = useCallback((params) => dispatch(acPageNativeSettingsSet(params)), [dispatch]);
  const onSetOneSignalUserId = useCallback((userId) => dispatch(acSaveOneSignalUserId(userId)), [dispatch]);

  const nativeSettings = useSelector((state: IStore) => state.nativeSettings);
  const token = useSelector((state: IStore) => state.auth.token);
  const onShowAlert = useCallback((message, actionLabel?, actionFunction?, snackBarClickHandler?) => dispatch(acAlertShowAction(message, actionLabel, actionFunction, snackBarClickHandler)), [dispatch]);

  useEffect(() => {
    if (window) {
      window.responseHandler = {
        navigationPush: (newUrl) => {
          pushOrReloadRoute(newUrl);
        },
        permissionDenied: (permissionType) => {
          if ([10, '10'].includes(permissionType)) {
            onShowAlert(t('notifications.storeDenied'));
          }
        },
        setSettings: (params) => {
          onSetSettings(params);
          setNativeSettingsToBrowserStorage(null, params);
        },
      };
    }
  }, []);

  useEffect(() => {
    if (nativeSettings?.userId && token) {
      onSetOneSignalUserId(nativeSettings.userId);
    }
  }, [nativeSettings?.userId, token]);

  return null;
};

export default NativeMessages;
