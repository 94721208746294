import React from 'react';
import { useSelector } from 'react-redux';

import ErrorBoundary from '@components/common/ErrorBoundary';
import { getMediaUrl } from '@constants/urls';
import { IStore } from '@interfaces/store';
import Button from '../common/Button';

interface ICountriesProps {
  setSelectedCountry: any;
}

const Countries: React.FunctionComponent<ICountriesProps> = ({ setSelectedCountry }) => {
  const data = useSelector((state: IStore) => state.pageData.data);
  const countries = data['country-select'] || [];
  return (
    <ErrorBoundary>
        {countries.map((country) => (
          <Button
            text={country.title}
            flag={getMediaUrl(country.flag)}
            onClickHandler={() => {
              setSelectedCountry(country);
            }}
            key={country.code}
          />
        ))}
    </ErrorBoundary>
  );
};

export default Countries;
