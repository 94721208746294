import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IStore } from '@interfaces/store';
import i18nF from '@utils/translation';

const TranslationProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {

  const translations = useSelector((state: IStore) => state.translations?.translations || []);
  const languageCode = useSelector((state: IStore) => state.pageData?.languageCode || 'et');

  const i18 = useMemo(() => i18nF(translations || {}), [translations]);

  useEffect(() => {
    i18.addResourceBundle(languageCode || 'et', 'namespace1', {
      ...(translations || {}),
    });
  }, [translations]);
  return (<I18nextProvider i18n={i18}>{children}</I18nextProvider>);
};

export default TranslationProvider;
