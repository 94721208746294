import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import Link from '@components/common/Link';
import { IPageData } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { getMenuBarBackground, getMenuBarColor } from '@utils/siteSettings';
import styles from './styles';

interface IMenuBarButtonProps {
  id: number;
  currentUrl: string | undefined;
  isActiveButton: boolean;
  translation: string;
  onMouseEnter: (e, type, id) => void;
  onMouseLeave: (e, type, id) => void;
  type: string;
}

const MenuBarButton: React.FunctionComponent<IMenuBarButtonProps & PropsWithChildren> = ({
  id,
  currentUrl,
  isActiveButton,
  onMouseLeave,
  translation,
  onMouseEnter,
  type,
  children,
}) => {
  const data: IPageData = useSelector((state: IStore) => state.pageData.data);

  const classes = styles({
    menuBarBackground: getMenuBarBackground(data),
    menuBarColor: getMenuBarColor(data),
  });

  const buttonLink = (item) => {
    return (
      <>
        {currentUrl ? (
          <Link legacyBehavior href={currentUrl}>
            <a
              className={classNames(classes.button, classes.buttonLink, classes.buttonContainer, { [classes.buttonActive]: isActiveButton })}
              onMouseEnter={(e) => onMouseEnter(e, type, id)}
              onMouseLeave={(e) => onMouseLeave(e, type, id)}
            >
              {item}
            </a>
          </Link>
        ) : item}
        {isActiveButton && children}
      </>
    );
  };
  return (
    <>
      {buttonLink(
        <>
          {translation}
        </>)}
    </>
  );
};

export default MenuBarButton;
