import Head from 'next/head';
import React from 'react';
import { useSelector } from 'react-redux';

import { PageTypes, productsListTypes } from '@constants/pageTypes';
import { IPageDataReducer } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { useProductsListStore } from '@reducers/productsList/useState';

const TitleDescription: React.FunctionComponent<{}> = () => {
  const pageData: IPageDataReducer = useSelector((state: IStore) => state.pageData);
  const { data: { title: pageDataTitle, seo: pageDataSeo }, type } = pageData;
  const { seo: productListSeo } = useProductsListStore();
  // Initial values
  let title = 'DenimDream';
  let description = '';
  // Values from Strapi
  if (pageDataTitle) {
    title = pageDataTitle;
  }
  if (pageDataSeo?.title) {
    title = pageDataSeo.title;
  }
  if (pageDataSeo?.description) {
    description = pageDataSeo.description;
  }
  // Values By page
  if (type && productsListTypes.includes(type)) {
    if (productListSeo.seoTitle) {
      title = productListSeo.seoTitle;
    }
    if (productListSeo.seoDescription) {
      description = productListSeo.seoDescription;
    }
  }

  return (
    <Head>
      <title>{title}</title>
      {type === PageTypes.productDetail ? (<>
          <meta name="description" content={description} />
          {pageDataSeo?.image && (<meta name="og:image" content={pageDataSeo?.image} />)}
          <meta name="og:title" content={title as string} />
        </>
      ) : (
        <>
          {description && <meta name="description" content={description} />}
        </>
      )}
    </Head>
  );
};

export default TitleDescription;
