import React from 'react';

const SortIcon: React.FunctionComponent<{ className?: string; onClick?: () => void; }> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="19" height="22" viewBox="0 0 19 22" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.75781 5.85718V7.57146H15.9285V5.85718H2.75781ZM2.75781 16.1429H7.14806V14.4286H2.75781V16.1429ZM11.5383 11.8572H2.75781V10.1429H11.5383V11.8572Z"
            fill="black" />
    </svg>

  );
};

export default SortIcon;
