import dynamic from 'next/dynamic';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const AppBarDesktop = dynamic(()=>import('@components/layout/header/AppBar/desktop'));
const AppBarMobile = dynamic(()=>import('@components/layout/header/AppBar/mobile'));

import { acPageSideMenuPageAction, acPageSideMenuStateAction } from '@actions/acPage';
import ErrorBoundary from '@components/common/ErrorBoundary';
import HiddenMy from '@components/common/Hidden';
import { PageTypes } from '@constants/pageTypes';
import { useGoToPage } from '@hooks/useGoToPage';
import { TThunkDispatch } from '@interfaces/index';
import { ESideMenuPage } from '@interfaces/page/store';
import { IStore } from '@interfaces/store';

import headerStyles from '../../header/Header.styles';

interface IAppBarProps {
  asHeader?: boolean;
}

const AppBar: React.FunctionComponent<IAppBarProps> = ({ asHeader = false }) => {
  const classesHeader = headerStyles({});

  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const countryCode = useSelector((state: IStore) => state.pageData.countryCode);
  const languageCode = useSelector((state: IStore) => state.pageData.languageCode);
  const onSideMenuHandle = useCallback((state) => dispatch(acPageSideMenuStateAction(state)), [dispatch]);
  const onSideMenuPageHandle = useCallback((page) => dispatch(acPageSideMenuPageAction(page)), [dispatch]);

  const isAuthorized = useSelector((state: IStore) => state.auth.isAuthorized);

  const homeUrl = '/' + countryCode + '/' + languageCode;

  const { goToPage } = useGoToPage();

  const goToHome = () => {
    goToPage(PageTypes.home);
  };

  const goToAccount = () => {
    goToPage(PageTypes.account);
  };

  const goToCart = () => {
    goToPage(PageTypes.cart);
  };

  const goToWishList = () => {
    goToPage(PageTypes.wishlist);
  };

  const onAccountClick = () => {
    if (isAuthorized) {
      goToAccount();
    } else {
      onSideMenuPageHandle(ESideMenuPage.main);
      onSideMenuHandle(true);
    }
  };

  return (
    <ErrorBoundary>
      <HiddenMy mdDown={true}>
        <AppBarDesktop onAccountClick={onAccountClick} goToHome={goToHome} goToWishList={goToWishList} />
      </HiddenMy>
      <HiddenMy mdUp={true}>
        <>
          {asHeader ? (
            <header id="chrome-sticky-header" className={classesHeader.header}>
              <AppBarMobile homeUrl={homeUrl}
                            onAccountClick={onAccountClick}
                            goToCart={goToCart}
                            goToWishList={goToWishList} />
            </header>
          ) : (
            <AppBarMobile homeUrl={homeUrl}
                          onAccountClick={onAccountClick}
                          goToCart={goToCart}
                          goToWishList={goToWishList} />
          )}

        </>
      </HiddenMy>
    </ErrorBoundary>
  );
};

export default AppBar;
