import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acWishListChangeSorting } from '@actions/acWishList';
import SortDirection from '@components/common/SortDirection/SortOrder';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

interface ISortOrderProps {
  small?: boolean;
}

const SortOrder: React.FunctionComponent<ISortOrderProps> = ({small}) => {
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const onChangeSorting = useCallback((sortDirectionValue) => dispatch(acWishListChangeSorting(sortDirectionValue)), [dispatch]);
  const sortDirection = useSelector((state: IStore) => state.wishList.sortDirection);
  return (
    <SortDirection small={small} onChange={onChangeSorting} value={sortDirection} />
  );
};

export default SortOrder;
