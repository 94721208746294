import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';

import { MEGA_MENU_ITEM_TYPES } from '@components/layout/header/MegaMenu/constants';
import MegaMenuSignUp from '@components/layout/header/MegaMenu/signUpBlock';
import StrapiImage from '@components/layout/header/MegaMenu/StrapiImage';
import {
  getImportantCategories,
} from '@components/layout/header/MobileMenu/mobile-menu-items/getImportantCategories';
import { EGenderType } from '@constants/gender';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import { useTranslation } from '@hooks/useTranslation';
import { IMegaMenuLinkDataObject, IMegaMenuLinksDataObject } from '@interfaces/megaMenu';
import { IImportantMenu, IImportantMenuItem } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { useMegaMenu } from '@reducers/pageData/useState';
import { mapGenderTypeToBrandType, mapTypeToGenderId } from '@utils/gender';

import HomeDeliveryIconSvg from '../../../../public/static/mega-menu/home-delivery.svg';
import MegaMenuLinks from './MegaMenuLinks';
import MegaMenuStyles from './styles';

interface IMegaMenuProps {
  activeButton: number | null;
  open: boolean;
  rootButtons: IMegaMenuLinkDataObject[];
  hideMenu: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const MegaMenu: React.FunctionComponent<IMegaMenuProps> = ({
  activeButton,
  open,
  onMouseEnter,
  onMouseLeave,
  rootButtons,
  hideMenu,
}) => {
  const classes = MegaMenuStyles();
  const { t } = useTranslation();
  const { asPath } = useRouter();

  const categoryWrapped = useSelector((state: IStore) => state.pageData.categoryWrapped);
  const data = useSelector((state: IStore) => state.pageData.data);

  const megaMenu = useMegaMenu();

  useDidUpdateEffect(() => {
    if (open) {
      hideMenu();
    }
  }, [asPath]);

  const importantMenu: IImportantMenu | undefined = data['important-menu'];

  const getMenuData = (index: number | null) => {
    const activeButtonSection: IMegaMenuLinkDataObject | undefined = (rootButtons && index !== null) ? rootButtons[index] : undefined;

    let dropdownLinks = {};
    if (activeButtonSection) {
      const { type, dropdownType } = activeButtonSection;
      const isGender = dropdownType && [EGenderType.man, EGenderType.women, EGenderType.kids].includes(dropdownType as EGenderType);
      const genderId = mapTypeToGenderId(dropdownType, dropdownType === 'kids' ? 4 :null);

      if (type === MEGA_MENU_ITEM_TYPES.dropDown) {
        const brandsType = dropdownType && mapGenderTypeToBrandType(dropdownType) || null;
        const brandsItems: IImportantMenuItem[] | null = brandsType && importantMenu ? importantMenu[brandsType] : null;
        const brandsOnlyOnDesktop: IImportantMenuItem[] = brandsItems && brandsItems.filter(({ showOnlyOnMobile }) => !showOnlyOnMobile) || [];
        const brands = brandsOnlyOnDesktop.map(({ name, url }) => ({
          translation: name,
          url,
        }));
        const videoLink = dropdownType && importantMenu ? importantMenu.banners[dropdownType] : '';
        const categories = isGender ? (dropdownType === 'kids' ? megaMenu.kids.categories : (genderId ? megaMenu[genderId].categories : [])) : [];
        dropdownLinks = {
          brands,
          categories,
          trending: isGender && genderId ? getImportantCategories('important', genderId || 2, categoryWrapped, data, false) : [],
          videoLink,
        };
      }
    }

    return dropdownLinks;
  };

  const links: Partial<IMegaMenuLinksDataObject> = getMenuData(activeButton);

  return (
    <>
      {open && Object.keys(links).length > 0 && (
        <>
          <div
            className={classes.megaMenu}
            id="mega-menu"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {links && (
              <div className={classes.megaMenuContentContainer}>
                <div className={classes.linksContainer}>
                  <div className={classes.linksContainerLeft}>
                    <MegaMenuLinks links={links} />
                    <MegaMenuSignUp />
                  </div>
                  <div className={classes.videoContainer}>
                    <StrapiImage url={links.videoLink ?? ''} />
                    <div className={classes.homeDelivery}>
                      <span>
                        <HomeDeliveryIconSvg />
                      </span>
                      <span className={classes.label}>{t('common.free_delivery')}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MegaMenu;
