import React from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import Hidden from '@components/common/Hidden';
import HeaderPin from '@components/layout/header/HeaderPin';

import HeaderDesktop from '@components/layout/header/desktop';
import HeaderMobile from '@components/layout/header/mobile';

const Header: React.FunctionComponent<{}> = () => {
  return (
    <ErrorBoundary>
      {/* <header id="header" className={classes.header}>*/}
      <HeaderPin>
          <Hidden mdDown={true}>
            <HeaderDesktop />
          </Hidden>
          <Hidden mdUp={true}>
            <HeaderMobile />
          </Hidden>
        </HeaderPin>
      {/*   </header>*/}
    </ErrorBoundary>
  );
};

export default Header;
