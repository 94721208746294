import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { getScrollY } from '@hooks/scroll/utils';
import { scrollToTopSmooth } from '@utils/scroll';

import { UseScrollContext } from '@context/useScroll';
import ArrowIconSvg from '../../../../public/static/footer/arrow.svg';
import toTopButtonStyles from './styles';

const GoTopButton: React.FunctionComponent = () => {
  const classes = toTopButtonStyles();
  const [isScrolledEnough, changeIsScrolledEnough] = useState<boolean>(false);

  const handleScrollEvent = () => {
    const newScrollY = getScrollY();
    const currentWindowHeight = window.innerHeight;
    if(Math.abs(currentWindowHeight * 1.5) < Math.abs(newScrollY)){
      changeIsScrolledEnough(true);
    } else {
      changeIsScrolledEnough(false);
    }
  };

  useEffect(()=>{
    window.addEventListener('scroll', handleScrollEvent, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  return (
    <UseScrollContext.Consumer>
      {({ fixElement, positionBottom, unpinElement }) => (
        <div
          className={classNames(classes.goTopButton, { [classes.goTopButtonFix]: (isScrolledEnough && fixElement && unpinElement) || positionBottom })}
          onClick={scrollToTopSmooth}>
          <ArrowIconSvg />
        </div>)
      }
    </UseScrollContext.Consumer>
  );
};

export default GoTopButton;
