import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { TypographyCollection } from '@themes/font';

export default makeStyles<Theme>((theme) => ({
  buttonsGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    width: '100%',
  },
  item: {
    alignItems: 'center',
    border: '1px solid #E1E1E1',
    borderRadius: 12,
    display: 'flex',
    flex: '1 1 72px',
    height: 72,
    justifyContent: 'flex-start',
    margin: '6px 0',
    width: '100%',
  },
  itemDescription: {
    display: 'flex',
    flex: '0 1 calc(100% - 92px)',
    justifyContent: 'flex-start',
    ...TypographyCollection.BodyRegularL,
  },
  itemIcon: {
    '& svg': {
      maxHeight: 24,
      maxWidth: 24,
    },
    display: 'flex',
    flex: '0 1 92px',
    justifyContent: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 18,
    width: '100%',
  },
  title: {
    marginTop: 60,
  },
}));
