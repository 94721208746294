import React from 'react';

import CustomBannerContainer from '@components/common/customBanners/common/Container';
import PageHeaderNotificationItem from '@components/common/customBanners/types/Notification/notificationItem';
import { ICustomBanner } from '@interfaces/customBanners/actions';

interface Params {
  banner: ICustomBanner;
}

const CustomBannerTypeNotification: React.FC<Params> = ({ banner }) => {

  return (
    <CustomBannerContainer
      banner={banner}>
      <PageHeaderNotificationItem
        item={banner} />
    </CustomBannerContainer>
  );
};

export default CustomBannerTypeNotification;
