import { Fade } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acReplaceRoute } from '@actions/acNavigation';
import { acPageCountrySelectAction } from '@actions/acPage';
import { onPageDataSetLocaleAction } from '@actions/acPageData';
import ErrorBoundary from '@components/common/ErrorBoundary';
import CountrySelect from '@components/dialogs/CountryLanguage/Country';
import LanguageSelect from '@components/dialogs/CountryLanguage/Language';
import { TThunkDispatch } from '@interfaces/index';
import { ICountrySelectItem } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { getLocale } from '@utils/countryLang';

import styles from './styles';

const CountryLanguageSelect: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const classes = styles();
  const countrySelect = useSelector<IStore, boolean>((state) => state.page.countrySelect);
  const onCountrySelectHandle = useCallback((state) => dispatch(acPageCountrySelectAction(state)), [dispatch]);
  const onReplaceRoute = useCallback((path) => dispatch(acReplaceRoute(path, true)), [dispatch]);
  const [selectedCountry, setSelectedCountry] = useState<null | ICountrySelectItem>(null);

  const countryCookies = getLocale(null);
  const withClose = !!countryCookies;

  useEffect(() => {
    setSelectedCountry(null);
  }, [countrySelect]);

  useEffect(() => {
    if (document.location.pathname === '/') {
      const countryLanguagePath = getLocale(null);
      if (countryLanguagePath) {
        const query = document.location.search;
        onReplaceRoute(countryLanguagePath + query);
        const [countryCode, languageCode] = countryLanguagePath.substring(1).split('/');
        onPageDataSetLocaleAction(countryCode, languageCode);
      } else {
        onCountrySelectHandle(true);
      }
    }
  }, []);

  return countrySelect ? (
    <ErrorBoundary>
      <Modal
        className={classes.modal}
        open={countrySelect}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick') {
            onCountrySelectHandle(false);
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          classes: {
            root: classes.backdrop
          },
          timeout: 500,
        }}
      >
        <Fade in={countrySelect}>
          <div className={classes.paper}>
            {selectedCountry ? <LanguageSelect country={selectedCountry} onClose={() => setSelectedCountry(null)} />
              : <CountrySelect setSelectedCountry={setSelectedCountry} withClose={withClose} />}
          </div>
        </Fade>
      </Modal>
    </ErrorBoundary>
  ) : null;
};

export default CountryLanguageSelect;
