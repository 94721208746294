import { useEffect, useMemo, useState } from 'react';

import {
  EScrollDirection,
  EScrollSpeed,
  getScrollBottomState,
  getScrollY,
  ICreateScrollState,
} from '@hooks/scroll/utils';

export interface IUseScroll {
  fixElement: boolean;
  positionBottom: boolean;
  resetState?: () => void;
  isKeyboardOpen?: boolean;
  unpinElement: boolean;
}

interface IUseScrollProps {
  positionTopY?: number;
  unpinElementY?: number;
}

const scrollSpeedOffsetLimit = 18;

export const useScroll: (props: IUseScrollProps) => IUseScroll = ({
  positionTopY = -1,
  unpinElementY = -60,
}): IUseScroll => {

  let scrollY;
  // if it is SSR then check you are now on the client and window object is available
  if (process.browser) {
    scrollY = getScrollY();
  }

  const createScrollState: (lastScrollTop: number) => ICreateScrollState = (lastScrollTop) => {
    const newScrollY = getScrollY();
    const newScrollDirection = lastScrollTop > newScrollY ? EScrollDirection.down : EScrollDirection.up;

    const scrollSpeedOffset = lastScrollTop - newScrollY;
    const scrollSpeedOffsetWithDirection = newScrollDirection === EScrollDirection.down
      ? scrollSpeedOffset
      : scrollSpeedOffset * -1;

    const newScrollSpeed = scrollSpeedOffsetWithDirection > scrollSpeedOffsetLimit ? EScrollSpeed.fast : EScrollSpeed.slow;

    return {
      positionTop: newScrollY > positionTopY,
      scrollDirection: newScrollDirection,
      scrollSpeed: newScrollSpeed,
      scrollY: newScrollY,
      unpinElement: newScrollY < unpinElementY,
    };
  };

  const initData = useMemo(() => createScrollState(0), []);
  const [fixElement, setFixElement] = useState(false);

  const [positionTop, setPositionTop] = useState(initData.positionTop);
  const [positionBottom, setPositionBottom] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(initData.scrollDirection);
  const [scrollSpeed, setScrollSpeed] = useState(initData.scrollSpeed);
  const [unpinElement, setUnpinElement] = useState(initData.unpinElement);
  const [reseted, setReseted] = useState(false);


  const handleScrollEvent = (e) => {
    if (!reseted) {

      const newState = createScrollState(scrollY);
      const isBottom = getScrollBottomState();
      if (newState.positionTop !== positionTop) {
        setPositionTop(newState.positionTop);
      }
      if (newState.scrollDirection !== scrollDirection) {
        setScrollDirection(newState.scrollDirection);
      }
      if (newState.scrollDirection === EScrollDirection.up && newState.scrollSpeed !== scrollSpeed && !fixElement) {
        setScrollSpeed(newState.scrollSpeed);
      }
      if (newState.scrollY !== scrollY) {
        scrollY = newState.scrollY;
      }
      if (newState.unpinElement !== unpinElement) {
        setUnpinElement(newState.unpinElement);
      }
      if (isBottom !== positionBottom) {
        setPositionBottom(isBottom);
      }
    } else {
      setReseted(false);
    }
  };

  const resetState = () => {
    setFixElement(false);
    setReseted(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  useEffect(() => {
    if (scrollDirection === EScrollDirection.down && fixElement) {
      setFixElement(false);
    }
    if (scrollSpeed === EScrollSpeed.fast && scrollDirection === EScrollDirection.up && !fixElement) {
      setFixElement(true);
    }
  }, [scrollDirection, scrollSpeed]);

  const isFixElement = useMemo(() => fixElement && !positionTop, [fixElement, positionTop]);

  return useMemo(() => ({
    fixElement: isFixElement,
    positionBottom,
    resetState,
    unpinElement,
  }), [isFixElement, unpinElement, positionBottom]);
};

export default useScroll;
