import classNames from 'classnames';
import React, { useRef } from 'react';

import SortSelect from '@components/common/SortDirection/SelectSort';
import { ESortType } from '@constants/sort';
import { useTranslation } from '@hooks/useTranslation';

import useStyles from './sortOrder.styles';

interface ISortDirectionProps {
  small?: boolean;
  onChange: (value: ESortType) => void;
  value: string;
}

const SortDirection: React.FunctionComponent<ISortDirectionProps> = ({ onChange, small, value }) => {
  const classes = useStyles({ small });
  const { t } = useTranslation();
  const selectRef = useRef(null);
  const handleSortOrderChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  return (
    <div className={classNames(classes.sortingSelectContainer, {
      [classes.sortingSelectContainerSmall]: small,
      [classes.sortingSelectContainerDefault]: !small,
    })}>
      <SortSelect
        selectClassName={classes.select48}
        ref={selectRef}
        options={[
          { name: t('common.sort_sale'), value: ESortType.sale },
          { name: t('common.sort_new'), value: ESortType.newFirst },
          { name: t('common.sort_price_asc'), value: ESortType.price_asc },
          { name: t('common.sort_price_desc'), value: ESortType.price_desc },
        ]}
        small={small}
        onChange={handleSortOrderChange}
        value={value}
      />
    </div>
  );
};

export default SortDirection;
