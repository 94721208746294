import React from 'react';

const NotificationsIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (

    <svg className={className} width="24" height="26" viewBox="0 0 24 26" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M18.75 14.5H5.25V22.75C5.25 23.5784 5.92157 24.25 6.75 24.25H17.25C18.0784 24.25 18.75 23.5784 18.75 22.75V14.5Z"
            stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3.75 10.75C3.75 10.3358 4.08579 10 4.5 10H19.5C19.9142 10 20.25 10.3358 20.25 10.75V13.75C20.25 14.1642 19.9142 14.5 19.5 14.5H4.5C4.08579 14.5 3.75 14.1642 3.75 13.75V10.75Z"
            stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 10V24.25" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 3.25V1.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.25 4.311L18.311 3.25" stroke="black" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" />
      <path d="M20.3086 7.28689L21.6946 6.71289" stroke="black" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" />
      <path d="M6.75241 4.311L5.69141 3.25" stroke="black" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" />
      <path d="M3.69459 7.28689L2.30859 6.71289" stroke="black" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>

  );
};

export default NotificationsIcon;
