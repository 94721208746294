import React from 'react';

const IconsSpriteData: React.FunctionComponent<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         style={{ position: 'absolute', width: 0, height: 0 }} aria-hidden="true">
      <symbol xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink" id="likeSprite">
        <path
          d="M19.1603 2.61006C18.0986 1.54806 16.691 0.902384 15.1935 0.790439C13.696 0.678494 12.2081 1.10772 11.0003 2.00006C9.72793 1.0537 8.14427 0.624569 6.5682 0.799095C4.99212 0.973621 3.54072 1.73884 2.50625 2.94064C1.47178 4.14245 0.931098 5.69158 0.993077 7.27607C1.05506 8.86057 1.71509 10.3627 2.84028 11.4801L10.2903 18.9301C10.3832 19.0238 10.4938 19.0982 10.6157 19.1489C10.7376 19.1997 10.8683 19.2259 11.0003 19.2259C11.1323 19.2259 11.263 19.1997 11.3849 19.1489C11.5067 19.0982 11.6173 19.0238 11.7103 18.9301L19.1603 11.4801C19.7429 10.8978 20.2051 10.2064 20.5205 9.44542C20.8358 8.68445 20.9982 7.86879 20.9982 7.04506C20.9982 6.22133 20.8358 5.40567 20.5205 4.64469C20.2051 3.88371 19.7429 3.19233 19.1603 2.61006ZM17.7503 10.0701L11.0003 16.8101L4.25028 10.0701C3.65545 9.47276 3.25025 8.71311 3.0855 7.8864C2.92076 7.05969 3.00381 6.20274 3.32423 5.42305C3.64465 4.64336 4.18817 3.97565 4.88662 3.50368C5.58507 3.03171 6.40734 2.7765 7.25028 2.77006C8.37639 2.77282 9.45537 3.22239 10.2503 4.02006C10.3432 4.11379 10.4538 4.18818 10.5757 4.23895C10.6976 4.28972 10.8283 4.31586 10.9603 4.31586C11.0923 4.31586 11.223 4.28972 11.3449 4.23895C11.4667 4.18818 11.5773 4.11379 11.6703 4.02006C12.4886 3.31094 13.5458 2.9392 14.6278 2.98008C15.7099 3.02096 16.736 3.47141 17.4985 4.24025C18.2609 5.00909 18.7029 6.0389 18.7348 7.12125C18.7667 8.2036 18.3862 9.25765 17.6703 10.0701H17.7503Z"
          fill="black" />
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink" id="likeActiveSprite">
        <path
          d="M18.163 1.80252C17.1019 0.767263 15.695 0.137838 14.1982 0.0287113C12.7015 -0.0804155 11.2142 0.338004 10.0071 1.20788C8.73534 0.28534 7.15246 -0.132983 5.57716 0.0371496C4.00187 0.207282 2.55118 0.953231 1.51722 2.12478C0.483262 3.29633 -0.0571552 4.80646 0.00479304 6.35106C0.0667413 7.89567 0.726454 9.36001 1.85108 10.4492L9.2974 17.7116C9.39032 17.803 9.50087 17.8755 9.62267 17.925C9.74447 17.9745 9.87511 18 10.0071 18C10.139 18 10.2696 17.9745 10.3914 17.925C10.5132 17.8755 10.6238 17.803 10.7167 17.7116L18.163 10.4492C18.7454 9.88159 19.2074 9.20762 19.5226 8.4658C19.8378 7.72398 20 6.92886 20 6.12586C20 5.32287 19.8378 4.52775 19.5226 3.78593C19.2074 3.04411 18.7454 2.37014 18.163 1.80252Z"
          fill="#5F1457" />
      </symbol>
    </svg>
  );
};

export default IconsSpriteData;


