import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { acPageCountrySelectAction } from '@actions/acPage';
import Countries from '@components/dialogs/CountryLanguage/Country/Countries';
import ModalTemplate from '@components/dialogs/CountryLanguage/ModalTemplate';
import { useTranslation } from '@hooks/useTranslation';
import { CloseIcon } from '@icons/Close';
import { TThunkDispatch } from '@interfaces/index';

import useStyles from './styles';

const CountrySelect = ({ setSelectedCountry, withClose = false }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch<TThunkDispatch>();
  const onCountrySelectHandle = useCallback((state) => dispatch(acPageCountrySelectAction(state)), [dispatch]);

  return (
    <ModalTemplate
      header={withClose ? (
        <div className={classes.closeBlock} onClick={() => onCountrySelectHandle(false)}>
          <CloseIcon />
        </div>
      ) : null}
      body={<Countries setSelectedCountry={setSelectedCountry} />}
      description={t('country_and_language_select.country_select_description')}
      title={t('country_and_language_select.country_select_heading')}
    />
  );
};

export default CountrySelect;
