import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

type IStyleProps = Record<string, unknown>;

export default makeStyles<Theme, IStyleProps>(() =>
  createStyles({
    banner: {
      width: '100%',
    },
    bannerImages: {
      width: '100%',
    },
  }),
);
