import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme: Theme) =>
  createStyles({
    hoverFill: {
      '& path': {
        fill: ColorsPalette.primary
      }
    },
    hoverStroke: {
      '& path': {
        stroke: ColorsPalette.primary
      }
    },
    menuItem: {
      '&:hover $hoverFill': {
        '& path': {
          fill: ColorsPalette.hoverActionCTA
        }
      },
      '&:hover $hoverStroke': {
        '& path': {
          stroke: ColorsPalette.hoverActionCTA
        }
      },
      '&:hover $menuItemIconForward': {
        '& svg': {
          '& path': {
            fill: ColorsPalette.hoverActionCTA
          }
        },
        color: ColorsPalette.hoverActionCTA
      },
      '&:hover $menuItemLabel': {
        color: ColorsPalette.hoverActionCTA
      },
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flex: '1 0 0',
      flexDirection: 'row',
      height: '56px',
      justifyContent: 'flex-start',
      maxHeight: '56px',
      padding: '8px 19px 8px 16px'
    },
    menuItemIcon: {
      '& svg': {
        maxWidth: '22.5px'
      },
      alignItems: 'center',
      display: 'flex',
      height: '40px',
      justifyContent: 'center',
      width: '40px'
    },
    menuItemIconForward: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      marginLeft: 'auto'
    },
    menuItemLabel: {
      color: ColorsPalette.primary,
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '132%',
      marginLeft: '25px'
    }
  }));
