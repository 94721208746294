import React from 'react';

const CartIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg className={className} width="38" height="48" viewBox="0 0 38 48" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.832 12.3334V12.8334H28.332H35.332C35.8183 12.8334 36.2846 13.0265 36.6284 13.3703C36.9722 13.7141 37.1654 14.1805 37.1654 14.6667V40.3334C37.1654 42.0573 36.4805 43.7106 35.2616 44.9296C34.0426 46.1485 32.3893 46.8334 30.6654 46.8334H7.33203C5.60812 46.8334 3.95482 46.1485 2.73584 44.9296C1.51685 43.7106 0.832031 42.0573 0.832031 40.3334V14.6667C0.832031 14.1805 1.02519 13.7141 1.369 13.3703C1.71282 13.0265 2.17913 12.8334 2.66536 12.8334H9.66536H10.1654V12.3334V10C10.1654 7.65727 11.096 5.41048 12.7526 3.75391C14.4092 2.09734 16.656 1.16669 18.9987 1.16669C21.3414 1.16669 23.5882 2.09734 25.2448 3.75391C26.9014 5.41048 27.832 7.65727 27.832 10V12.3334ZM23.6654 12.8334H24.1654V12.3334V10C24.1654 8.62974 23.621 7.31557 22.6521 6.34664C21.6831 5.3777 20.369 4.83335 18.9987 4.83335C17.6284 4.83335 16.3142 5.3777 15.3453 6.34664C14.3764 7.31557 13.832 8.62974 13.832 10V12.3334V12.8334H14.332H23.6654ZM4.9987 16.5H4.4987V17V40.3334C4.4987 41.0848 4.79721 41.8055 5.32856 42.3368C5.85991 42.8682 6.58058 43.1667 7.33203 43.1667H30.6654C31.4168 43.1667 32.1375 42.8682 32.6688 42.3368C33.2002 41.8055 33.4987 41.0848 33.4987 40.3334V17V16.5H32.9987H28.332H27.832V17V19.3334C27.832 19.8196 27.6389 20.2859 27.2951 20.6297C26.9512 20.9735 26.4849 21.1667 25.9987 21.1667C25.5125 21.1667 25.0462 20.9735 24.7023 20.6297C24.3585 20.2859 24.1654 19.8196 24.1654 19.3334V17V16.5H23.6654H14.332H13.832V17V19.3334C13.832 19.8196 13.6389 20.2859 13.2951 20.6297C12.9512 20.9735 12.4849 21.1667 11.9987 21.1667C11.5125 21.1667 11.0462 20.9735 10.7023 20.6297C10.3585 20.2859 10.1654 19.8196 10.1654 19.3334V17V16.5H9.66536H4.9987Z"
        fill="black" stroke="white" />
    </svg>
  );
};

export default CartIcon;
