import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageTypes } from '@constants/pageTypes';
import { useTranslation } from '@hooks/useTranslation';
import { TThunkDispatch } from '@interfaces/index';
import { ESideMenuPage } from '@interfaces/page/store';
import { IStore } from '@interfaces/store';

import { acPageSideMenuPageAction, acPageSideMenuStateAction } from '@actions/acPage';
import SubmitButton, { EButtonType } from '@components/common/SubmitButton';
import DenimIcon from '@icons/Denim';

import styles from './styles';

const AccountCustomPage: React.FunctionComponent<{}> = () => {
  const classes = styles();
  const { t } = useTranslation();
  const dispatch = useDispatch<TThunkDispatch>();
  const type = useSelector((state: IStore) => state.pageData.type);

  const onSideMenuHandle = useCallback(
    (state: boolean) => dispatch(acPageSideMenuStateAction(state)),
    [dispatch],
  );

  const onSideMenuPageHandle = useCallback(
    (page: ESideMenuPage) => dispatch(acPageSideMenuPageAction(page)),
    [dispatch],
  );

  const renderLoginButtons = () => (
    <div className={classes.loginButtonsWrapper}>
      <SubmitButton
        startIcon={<DenimIcon />}
        endIcon={<div />}
        onClick={() => {
          onSideMenuPageHandle(ESideMenuPage.welcomeBack);
          onSideMenuHandle(true);
        }}
      >
        {t('common.sign_in')}
      </SubmitButton>

      <SubmitButton
        startIcon={<DenimIcon />}
        endIcon={<div />}
        onClick={() => {
          onSideMenuPageHandle(ESideMenuPage.registration);
          onSideMenuHandle(true);
        }}
        type={EButtonType.secondary}
      >
        {t('common.sign_up')}
      </SubmitButton>
    </div>
  );

  const renderNotSignedInPage = (heading: string) => {
    return (
      <div>
        <h1 className={classes.wrap}>{t(heading)}</h1>
        <p className={classes.wrap}>{t('account.loginText')}</p>
        {renderLoginButtons()}
      </div>
    );
  };


  const renderCustomPage = () => {
    let heading: string | null = null;
    switch (type) {
      case PageTypes.account_orders:
        heading = 'account.orders';
        break;
      case PageTypes.account_changePassword:
        heading = 'account.changePassword';
        break;
      case PageTypes.account_delivery:
        heading = 'account.myDeliveryMethods';
        break;
      case PageTypes.account_help:
        heading = 'account.helpAndFAQs';
        break;
      case PageTypes.account_linkedAccounts:
        heading = 'account.linkedAccounts';
        break;
      case PageTypes.account_details:
        heading = 'account.myDetails';
        break;
      case PageTypes.account_notifications:
        heading = 'account.notifications';
        break;
      case PageTypes.account_payment:
        heading = 'account.myPaymentsMethods';
        break;
      case PageTypes.account:
        heading = 'account.title';
        break;
      default:
        return null;
    }
    return heading ? renderNotSignedInPage(heading) : null;
  };


  return renderCustomPage();
};

export default AccountCustomPage;
