import Backdrop from '@mui/material/Backdrop';
import React, { useEffect, useRef, useState } from 'react';

import { IMegaMenuLinkDataObject } from '@interfaces/megaMenu';
import { IPageData } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { getMenuBarBackground, getMenuBarColor } from '@utils/siteSettings';
import { useSelector } from 'react-redux';
import MegaMenu from '../MegaMenu';
import { MEGA_MENU_ITEM_TYPES } from './../MegaMenu/constants';
import MenuBarButton from './MenuBarButton';

import MenuBarStyles from './styles';

interface IMenuBarProps {
  canShowMenu: boolean;
}

const MenuBar: React.FunctionComponent<IMenuBarProps> = ({ canShowMenu }) => {
  const data: IPageData = useSelector((state: IStore) => state.pageData.data);

  const classes = MenuBarStyles({
    menuBarBackground: getMenuBarBackground(data),
    menuBarColor: getMenuBarColor(data),
  });

  const megaMenuData: IMegaMenuLinkDataObject[] | undefined = data['mega-menu'];

  const changingRoute = useSelector((state: IStore) => state.navigation.changingRoute);

  const [isMegaMenuOpened, setIsMegaMenuOpened] = useState(false);
  const [activeButton, setActiveButton] = useState(null);

  const showDropDownTimer = useRef<any | null>(null);
  const hideDropDownTimer = useRef<any | null>(null);

  const rootButtons: IMegaMenuLinkDataObject[] = megaMenuData?.filter((menuButton) => {
    return menuButton?.isRootItem;
  }) || [];

  useEffect(() => {
    if (changingRoute && isMegaMenuOpened) {
      hideMegaMenu();
    }
  }, [changingRoute]);

  useEffect(() => {
    if (!canShowMenu && isMegaMenuOpened) {
      setIsMegaMenuOpened(false);
    }
  }, [canShowMenu]);

  const clearTimer = (timerId) => {
    if (typeof timerId === 'number') {
      clearTimeout(timerId);
    }
  };

  const onMouseEnterHandler = (event, buttonType, id) => {
    const showDropdown = buttonType === MEGA_MENU_ITEM_TYPES.dropDown;
    setActiveButton(id);
    clearTimer(hideDropDownTimer.current);
    if (showDropdown) {
      showDropDownTimer.current = setTimeout(() => {
        setIsMegaMenuOpened(true);
      }, 400);
    } else {
      setIsMegaMenuOpened(false);
    }
  };

  const onMouseEnterMenuHandler = () => {
    clearTimer(hideDropDownTimer.current);
  };

  const hideMegaMenu = () => {
    hideDropDownTimer.current = setTimeout(() => {
      setIsMegaMenuOpened(false);
      setActiveButton(null);
    }, 100);
    clearTimer(showDropDownTimer.current);
  };


  return (
    <div className={classes.menuBar}>
      <div className={classes.menuBarContainer}>
        {rootButtons?.map(({ type, translation, dropdownItems, url }, index) => {
          let dropdownUrl = '';
          if (type === MEGA_MENU_ITEM_TYPES.dropDown) {
            dropdownUrl = dropdownItems?.page || '';
          }
          const currentUrl = url || dropdownUrl;
          return (
            <MenuBarButton
              id={index}
              isActiveButton={activeButton === index}
              key={`button-${translation}`}
              translation={translation}
              currentUrl={currentUrl}
              type={type}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={hideMegaMenu}
            >
              <MegaMenu
                key={`megaMenuSection${index}`}
                activeButton={activeButton}
                hideMenu={hideMegaMenu}
                open={isMegaMenuOpened}
                rootButtons={rootButtons}
                onMouseEnter={onMouseEnterMenuHandler}
                onMouseLeave={hideMegaMenu}
              />
            </MenuBarButton>
          );
        })}
      </div>
      <Backdrop className={classes.menuBackDrop} open={isMegaMenuOpened} transitionDuration={0} />
    </div>
  );
};

export default MenuBar;
