import React from 'react';

import Link from '@components/common/Link';
import megaMenuLinksStyles from '@components/layout/header/MegaMenu/megaMenuLinks.styles';
import { IMegaMenuCategory } from '@interfaces/megaMenu';

interface IMegaMenuLinkProps {
  linkData: IMegaMenuCategory;
}

const MegaMenuPopularLink: React.FunctionComponent<IMegaMenuLinkProps> = ({ linkData }) => {
  const classes = megaMenuLinksStyles();

  return (
    <span className={classes.megaMenuLink}>
      <Link legacyBehavior href={linkData.url || ''}>
        <a data-content={linkData.label}>{linkData.label}</a>
      </Link>
    </span>
  );
};

export default MegaMenuPopularLink;
