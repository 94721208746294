import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { maxWidth } from '@constants/styles';
import { ColorsPalette } from '@themes/colors';
import { TypographyCollection } from '@themes/font';
import { zIndex } from '@themes/zIndex';

export default makeStyles<Theme, { menuBarBackground?: string, menuBarColor?: string }>((theme) =>
  createStyles({
    button: {
      '&$buttonActive': {
        '& a': {
          background: ColorsPalette.neutral2,
          color: ColorsPalette.primary,
          cursor: 'pointer',
        },
        background: ColorsPalette.neutral2,
        color: ColorsPalette.primary,
        cursor: 'pointer',
      },
      ...TypographyCollection.HLinkBoldL,
      ...{
        '& a': {
          color: ColorsPalette.neutral2,
          textDecoration: 'none',
        },
        alignItems: 'center',
        color: ColorsPalette.neutral2,
        display: 'flex',
        height: 36,
        justifyContent: 'center',
        minWidth: 87,
      },
    },
    buttonActive: {},
    buttonContainer: {
      paddingLeft: 16,
      paddingRight: 18,
    },
    buttonLink: {
      color: (props) => props.menuBarColor || ColorsPalette.neutral2,
      textDecoration: 'none',
    },
    menuBackDrop: {
      // marginTop: headerAllHeight,
      height: '100vh',
      position: 'absolute',
      top: '2.5rem',
      zIndex: zIndex.megaMenuBackDrop,
    },
    [theme.breakpoints.up('sm')]: {
      menuBar: {
        alignItems: 'flex-end',
        backgroundColor: (props) => props.menuBarBackground || ColorsPalette.primary,
        display: 'flex',
        height: '2.5rem',
        position: 'relative',
      },
      menuBarContainer: {
        [theme.breakpoints.up('md')]: {
          paddingLeft: '56px',
          paddingRight: '56px',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          paddingLeft: '6px',
          paddingRight: '6px',
        },
        [theme.breakpoints.down('md')]: {
          paddingLeft: '0',
          paddingRight: '0',
        },
        display: 'flex',
        flex: '1 1 100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth,
      },
    },
  }),
);
