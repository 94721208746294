import getConfig from 'next/config';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import tawkTo from 'tawkto-react';

import { mobileMode } from '@constants/config';
import { ECountries } from '@constants/countries';
import { IStore } from '@interfaces/store';
import { isTrackingDisabledByAppSettings } from '@utils/validation';
import { isCookieEnabled, TYPE_TAWK } from '../CookieConsent/cookieConsentUtils';

const { publicRuntimeConfig } = getConfig();

declare global {
  interface Window {
    Tawk_API: any;
  }
}

interface IChatProps {
  initState: number;
  onOpen: () => void;
  onClose: () => void;
}

const Chat: React.FunctionComponent<IChatProps> = ({
  initState,
  onOpen,
  onClose,
}) => {
  const countryCode: string | null = useSelector((state: IStore) => state.pageData.countryCode);
  const trackingDisabled: boolean | null | undefined = useSelector((state: IStore) => state.nativeSettings.trackingDisabled);

  const [tawkPropertyId,  setTawkPropertyId] = useState('');
  const [tawkKey, setTawkKey] = useState('');

  useEffect(()=> {
    switch (countryCode) {
      case ECountries.EE:
        setTawkPropertyId(publicRuntimeConfig.tawkPropertyIdEe);
        setTawkKey(publicRuntimeConfig.tawkKeyEe);
        break;
      case ECountries.FI:
        setTawkPropertyId(publicRuntimeConfig.tawkPropertyIdFi);
        setTawkKey(publicRuntimeConfig.tawkKeyFi);
        break;
      case ECountries.EU:
        setTawkPropertyId(publicRuntimeConfig.tawkPropertyIdEn);
        setTawkKey(publicRuntimeConfig.tawkKeyEn);
        break;
      case ECountries.LV:
        setTawkPropertyId(publicRuntimeConfig.tawkPropertyIdLv);
        setTawkKey(publicRuntimeConfig.tawkKeyLv);
        break;
      case ECountries.LT:
        setTawkPropertyId(publicRuntimeConfig.tawkPropertyIdLt);
        setTawkKey(publicRuntimeConfig.tawkKeyLt);
        break;
      default:
        setTawkPropertyId(publicRuntimeConfig.tawkPropertyIdEn);
        setTawkKey(publicRuntimeConfig.tawkKeyEn);
    }

  }, [countryCode]);


  useEffect(() => {
    if (initState !== 0) {
      loadTawkTo();
    }
  }, [initState]);

  const loadTawkTo = () => {
    const isCookieEnabledValue = isCookieEnabled(TYPE_TAWK);
    const disabledOnAppPrompt = isTrackingDisabledByAppSettings(trackingDisabled);
    if (mobileMode ? disabledOnAppPrompt : isCookieEnabledValue === false) {
      return;
    }
    if (window.Tawk_API && typeof window.Tawk_API.isChatHidden !== 'undefined' && window.Tawk_API.isChatHidden()) {
      window.Tawk_API.showWidget();
      window.Tawk_API.maximize();
      onOpen();
    } else if (tawkPropertyId && tawkKey) {
      tawkTo(tawkPropertyId, tawkKey);
      window.Tawk_API.onLoad = () => {
        window.Tawk_API.showWidget();
        window.Tawk_API.maximize();
        onOpen();
      };
      window.Tawk_API.onChatMinimized = () => {
        window.Tawk_API.hideWidget();
        setTimeout(() => onClose(), 0);
      };
    }
  };
  return (null);
};

export default Chat;
