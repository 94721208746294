import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import CustomBannerContainer from '@components/common/customBanners/common/Container';
import { acNotificationClose } from '@actions/acCustomBanners';
import { TThunkDispatch } from '@interfaces/index';
import { pushOrReloadRoute } from '@utils/routes';
import { CloseIcon } from '@icons/Close';
import { ICustomBanner, INotificationBannerAttributes } from '@interfaces/customBanners/actions';
import styles from './styles';

interface IPageHeaderNotificationItemProps {
  item: ICustomBanner;
}

const PageHeaderNotificationItem: React.FunctionComponent<IPageHeaderNotificationItemProps> = ({ item }) => {
  const classes = styles();
  const { closeButton, link, open_new_window, text } = item.attributes as INotificationBannerAttributes;

  const onClick = useCallback(() => {
    if (link) {
      if (open_new_window && window) {
        window.open(link, '_blank');
      } else {
        pushOrReloadRoute(link);
      }
    }
  }, [link, open_new_window]);

  return (
    <CustomBannerContainer banner={item}
                           className={classNames(classes.notification, { [classes.notificationLink]: link })}
                           onClick={onClick}>
      <div className={classes.notificationContent}>
        <div className={classNames(classes.notificationContentText, { [classes.notificationContentWithClose]: true })}>
          {text}
        </div>
        {closeButton && <PageHeaderNotificationItemClose item={item} />}
      </div>
    </CustomBannerContainer>
  );
};

const PageHeaderNotificationItemClose: React.FunctionComponent<IPageHeaderNotificationItemProps> = ({ item }) => {
  const classes = stylesClose({ color: item.content_settings.color || undefined });
  const dispatch = useDispatch<TThunkDispatch>();
  const onClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(acNotificationClose(item));
  }, [item]);

  return (
    <div className={classes.notificationClose} onClick={onClick}>
      <CloseIcon className={classes.notificationCloseIcon} />
    </div>

  );
};

const stylesClose = makeStyles<Theme, { color?: string; }>((theme) =>
  createStyles({
    notificationClose: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      marginLeft: 'auto',
      padding: '8px',
    },
    notificationCloseIcon: {
      marginRight: 14,
      '& path': {
        fill: (props) => props.color || ColorsPalette.neutral2,
      },
    },
  }),
);

export default PageHeaderNotificationItem;
