import React from 'react';
import { useSelector } from 'react-redux';

import { IGetCustomerResponse } from '@interfaces/auth/reducer';
import { IStore } from '@interfaces/store';

interface IAccountMenuUserNameProps {
  short?: boolean;
}

const AccountMenuUserName: React.FunctionComponent<IAccountMenuUserNameProps> = ({ short = false }) => {

  const user = useSelector<IStore, IGetCustomerResponse | null>((state) => state.auth.user);

  const firstNameF = user?.data?.attributes ? (short ? user?.data?.attributes?.['first-name']?.slice(0, 1) : user?.data?.attributes?.['first-name']) : '';
  const lastNameF = user?.data?.attributes ? (short ? user?.data?.attributes?.['last-name']?.slice(0, 1) : user?.data?.attributes?.['last-name']) : '';

  return user ? (
    <>
      {firstNameF} {lastNameF}
    </>

  ) : null;
};


export default AccountMenuUserName;
