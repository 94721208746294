import { mobileSession } from '@constants/config';
import { getListSizeCookie, setListSizeCookie, TContext } from '@utils/cookies';
import { getLocaleFromLocalStorage, setListSizeInLocalStorage } from '@utils/localStorage';

type TGetLocale = (context?: TContext) => boolean;
export const getListSize: TGetLocale = (context = null) => {
  const value = mobileSession ? getLocaleFromLocalStorage() : getListSizeCookie(context);
  return value !== undefined ? Boolean(value) : true;
};

type TSetLocale = (value: string, context?: TContext) => void;
export const setListSize: TSetLocale = (value, context = null) => {
  mobileSession ? setListSizeInLocalStorage(value) : setListSizeCookie(context, value);
};
