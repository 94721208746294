import * as actions from '@constants/actions/brands';
import { ERedisCacheKey } from '@constants/cacheCallApi';
import { RequestMethods } from '@constants/types';
import { getBrandsUrl } from '@constants/urls';
import { CALL_API } from 'services/SSRService';
import { addParamsToUrl } from 'services/SSRService/utils';

interface IGetBrandsParams {
  country: string;
  lang: string;
  sexId: string;
}
type TGetBrands = (params: IGetBrandsParams) => any;
export const acGetBrands: TGetBrands = (params) => ({
  [CALL_API]: {
    cache: true,
    cacheOptions: ERedisCacheKey.getBrands,
    endpoint: addParamsToUrl(getBrandsUrl(), {
      ...params,
    }),
    method: RequestMethods.GET_METHOD,
    types: [actions.BRANDS_GET_REQUEST, actions.BRANDS_GET_SUCCESS, actions.BRANDS_GET_FAILED],
  },
});
