import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';

export default makeStyles<Theme>((theme: Theme) =>
  createStyles({
    bage: {
      alignItems: 'center',
      borderRadius: '100px',
      display: 'flex',
      fontStyle: 'normal',
      fontWeight: 'bold',
      justifyContent: 'center',
      padding: '4px 10px',
      textAlign: 'center'
    },
    priceGreen: {
      background: ColorsPalette.newLight,
      color: ColorsPalette.newDark
    },
    priceRed: {
      background: ColorsPalette.salesLight,
      color: ColorsPalette.salesDark
    },
    priceYellow: {
      background: ColorsPalette.saffronLight,
      color: ColorsPalette.saffronDark
    }
  }));
