import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';

const useStyles = makeStyles<Theme>((theme) => ({
  formControl: {
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `none`,
    },
    '& .MuiInputBase-root:hover .MuiSelect-select $renderValue': {
      color: ColorsPalette.hoverActionCTA,
    },
    '& .MuiInputBase-root:hover svg path': {
      fill: ColorsPalette.hoverActionCTA,
    },
    '&:not($formControlSmall) .MuiInputBase-root:hover .MuiSelect-select': {
      background: ColorsPalette.neutral1,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    margin: 0,
  },
  formControlSmall: {},
  renderValue: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    [theme.breakpoints.down('md')]: {
      border: `1px solid ` + ColorsPalette.neutral1,
    },
  },
  rootSmall: {
    paddingRight: '0 !important',
  },
  select: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    padding: '9.5px 14px',
  },
  startIcon: {
    flex: '0 0 19px',
    marginRight: 12,
  },
}));

export default useStyles;
