import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';

import PushNotifications from '@components/dialogs/PushNotifications/index';
import { IStore } from '@interfaces/store';
import { INativeSettings } from '@reducers/nativeSettings/types';

const PushNotificationsState: React.FunctionComponent = () => {

  const countrySelect = useSelector((state: IStore) => state.page.countrySelect);
  const firstRun = useSelector<IStore, boolean>((state) => state.page.firstRun);
  const nativeSettings = useSelector<IStore, INativeSettings | null>((state) => state.nativeSettings);

  const isPushNotificationNotDetermined = nativeSettings?.notificationPermissionStatus === 0;

  const { pathname } = useRouter();
  if (countrySelect || !firstRun || pathname === '/') {
    return null;
  }

  return isPushNotificationNotDetermined ? (
    <PushNotifications />
  ) : null;
};

export default PushNotificationsState;
