import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import CustomBannerTypeBanner from './types/Banner';
import CustomBannerTypeNotification from './types/Notification/notification';

import { ECustomBannerLocation, ECustomBannerType } from '@constants/customBanners';
import { ICustomBanner, ICustomBannerClosed, INotificationBannerAttributes } from '@interfaces/customBanners/actions';
import { IStore } from '@interfaces/store';

import styles from './styles';

interface Params {
  location: ECustomBannerLocation;
  className?: any;
}


const renderBanner = (banner: ICustomBanner, key:number): JSX.Element => {
  switch (banner.type) {
    case ECustomBannerType.banner:
      return <CustomBannerTypeBanner key={banner.type+''+key} banner={banner} />;
    case ECustomBannerType.notification:
      return <CustomBannerTypeNotification key={banner.type+''+key} banner={banner} />;
    default:
      return <></>;
  }
};

const CustomBanners: React.FC<Params> = ({ location, className }) => {
  const classes = styles({});
  const banners = useSelector<IStore, ICustomBanner[]>((state) => state.pageData.data.customBanners?.[location] || []);

  const closedNotifications = useSelector<IStore, ICustomBannerClosed[]>((state) => state.common.closedNotifications);
  const closedNotificationsId = closedNotifications.map((item) => item.id + '');

  const filterType = (item: ICustomBanner) => item.type !== ECustomBannerType.sale_switch;
  const filterClosed = (item: ICustomBanner) => (item.type !== ECustomBannerType.notification) || !closedNotificationsId.includes((item.attributes as INotificationBannerAttributes)?.uid + '');

  const targetBanners = Array.isArray(banners) && banners?.filter(filterType).filter(filterClosed);

  return targetBanners ? (
    <div className={classNames(classes.banner, className)}>
      {targetBanners.map(renderBanner)}
    </div>
  ) : null;
};

export default CustomBanners;
