import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { mobileMode } from '@constants/config';
import { headerHeightSm } from '@constants/styles';
import { ColorsPalette } from '@themes/colors';
import { zIndex } from '@themes/zIndex';

export default makeStyles<Theme>((theme) =>
  createStyles({
    headerApp: {
      '& >div': {
        zIndex: mobileMode ? zIndex.headerAction : 'auto',
        [theme.breakpoints.down('md')]: {
          zIndex: zIndex.headerAction,
        },
      },
      '&$headerAppTransparent': {
        backdropFilter: 'none',
        backgroundColor: 'transparent',
      },
      '&$headerAppTransparent$headerAppUnpin': {
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(255, 255, 255, 0.80)',
      },
      alignItems: 'center',
      backgroundColor: ColorsPalette.neutral2,
      boxSizing: 'border-box',
      display: 'flex',
      //   flex: '1 1 100%',
      flexDirection: 'row',
      height: headerHeightSm,
      transition: 'background-color 400ms ease-in-out',
      width: '100%',
     // zIndex: zIndex.header,
      [theme.breakpoints.down('md')]: {
        padding: '0 20px 0 0',
      },
    },
    headerAppBorder: {
      borderBottom: '1px solid ' + ColorsPalette.neutral1,
    },
    headerAppFix: {
      [theme.breakpoints.down('md')]: {
        position: 'sticky',
        top: 0,
        zIndex: zIndex.header,
      },
    },
    headerAppLoadingIcon: {
      height: 24,
    },
    headerAppMarginBottom: {
      marginBottom: -headerHeightSm,
    },
    headerAppTransparent: {},
    headerAppUnpin: {},
    svg: {
      height: 42,
    },
  }));
