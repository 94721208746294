export enum EScrollDirection {
  down = 'down',
  up = 'up'
}

export enum EScrollSpeed {
  fast = 'fast',
  slow = 'slow'
}

const scrollSpeedOffsetLimit = 18;

export interface ICreateScrollState {
  positionTop: boolean;
  scrollDirection: EScrollDirection;
  scrollSpeed: EScrollSpeed;
  scrollY: number;
  unpinElement: boolean;
}

export const getDocumentBoundingClientRect = (documentElement: HTMLElement) =>
  typeof documentElement?.getBoundingClientRect === 'function' ?
    documentElement.getBoundingClientRect() :
    {
      left: 0,
      top: 0,
    };

export const getDocumentElement = (isServer): HTMLElement =>
  !isServer ?
    document.documentElement
    : {
      getBoundingClientRect: getDocumentBoundingClientRect,
      scrollHeight: 0,
      scrollWidth: 0,
    } as HTMLElement;


export const getDocumentBodyBoundingRect = () => {
  const isServer = !process?.browser;
  const documentElement: HTMLElement = getDocumentElement(isServer);
  const bodyBoundingRect: DOMRect = documentElement.getBoundingClientRect();

  return bodyBoundingRect;
};

export const getScrollY = () => {
  const bodyBoundingRect: DOMRect = getDocumentBodyBoundingRect();

  return bodyBoundingRect.top;
};

export const createScrollState: (lastScrollTop: number, positionTopY: number, unpinElementY: number) => ICreateScrollState = (lastScrollTop, positionTopY, unpinElementY) => {
  const scrollY = getScrollY();
  const scrollDirection = lastScrollTop > scrollY ? EScrollDirection.down : EScrollDirection.up;

  const scrollSpeedOffset = lastScrollTop - scrollY;
  const scrollSpeedOffsetWithDirection = scrollDirection === EScrollDirection.down
    ? scrollSpeedOffset
    : scrollSpeedOffset * -1;

  const scrollSpeed = scrollSpeedOffsetWithDirection > scrollSpeedOffsetLimit ? EScrollSpeed.fast : EScrollSpeed.slow;

  return {
    positionTop: scrollY > positionTopY,
    scrollDirection,
    scrollSpeed,
    scrollY,
    unpinElement: scrollY < unpinElementY,
  };
};

export const getScrollBottomState = (): boolean => {
  const bodyBoundingRect: DOMRect = getDocumentBodyBoundingRect();
  const pageHeight = bodyBoundingRect.height;
  const screenHeight = window?.innerHeight;
  const scrollTop = bodyBoundingRect.top;
  if(scrollTop > 0) {
    const diffToBottom = pageHeight + scrollTop - screenHeight;
    return diffToBottom < 5;
  }
  return false;

};
