import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import Hidden from '@components/common/Hidden';
import AccountMenuUserName from '@components/pages/account/Menu/UserName';
import AccountMenuUserBonuses from '@components/pages/account/Menu/userBonuses';
import AccountIcon from '@icons/Account';
import { IGetCustomerResponse } from '@interfaces/auth/reducer';
import { IStore } from '@interfaces/store';
import { getAccountColor } from '@utils/siteSettings';

import styles from './styles';

interface IAccountMenuPageProps {
  menuItems: React.ReactNode;
}

const AccountMenuPage: React.FunctionComponent<IAccountMenuPageProps> = ({ menuItems }) => {

  const user = useSelector<IStore, IGetCustomerResponse | null>((state) => state.auth.user);
  const data = useSelector((state: IStore) => state.pageData.data);
  const backgroundColor = getAccountColor(data);
  const classes = styles({
    backgroundColor,
  });

  return (<>
      <div className={classes.menu}>
        <div className={classes.menuHeader}>
          <div className={classNames(classes.menuUser, { [classes.menuUserUnAuth]: !user })}>
            {user ? (
              <>
                <div className={classNames(classes.menuUserAvatar)}>
                  <AccountMenuUserName short={true} />
                </div>
                <AccountMenuUserBonuses />
              </>
            ) : (
              <div className={classNames(classes.menuUserAvatar)}>
                <AccountIcon />
              </div>
            )}

            {user && (
              <Hidden mdUp>
                <div className={classNames(classes.menuUserEdge, classes.menuUserEdgeEnd)} />
              </Hidden>
            )}
          </div>
        </div>
        <div className={classes.menuSections}>
          {menuItems}
        </div>
      </div>
    </>
  );
};


export default AccountMenuPage;
