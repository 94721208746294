import { ActionCreator } from 'redux';

import * as actions from '@reducers/nativeSettings/const';
import { INativeSettingsSetAction, INativeTrackingDisableStateAction } from '@reducers/nativeSettings/types';

export const acTrackingDisableStateUpdate: ActionCreator<INativeTrackingDisableStateAction> = () => ({
  type: actions.NATIVE_TRACKING_STATE,
});

export const acPageNativeSettingsSet: ActionCreator<INativeSettingsSetAction> = (state: any) => ({
  payload: state,
  type: actions.NATIVE_SETTINGS_SET,
});
