import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { mobileMode } from '@constants/config';
import { appNavigationHeightSm, headerHeightSm } from '@constants/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme, { backgroundColor: string }>((theme: Theme) =>
  createStyles({
    disabled: {
      opacity: '0.3',
      pointerEvents: 'none',
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: FontCollection.raleway,
      marginRight: '16px',
      marginTop: '5px',
      width: '308px',
      [theme.breakpoints.down('md')]: {
        backgroundColor: ({ backgroundColor }) => backgroundColor,
        flex: '1',
        marginRight: '0',
        marginTop: '0',
        width: '100%',
      },
    },
    menuHeader: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        background: 'url("/images/accountBackground.png") no-repeat',
        backgroundColor: ({ backgroundColor }) => backgroundColor,
        backgroundSize: 'cover',
        height: '224px',
        marginTop: -headerHeightSm,
      },
    },
    menuSections: {
      [theme.breakpoints.up('sm')]: {
        marginTop: '40px',
      },
      [theme.breakpoints.down('md')]: {
        '@supports (padding-top: constant(safe-area-inset-bottom))': {
          paddingBottom: `calc(constant(safe-area-inset-bottom) * 2 + ${appNavigationHeightSm}px)`,
        },
        '@supports (padding-top: env(safe-area-inset-bottom))': {
          paddingBottom: `calc(env(safe-area-inset-bottom) * 2  + ${appNavigationHeightSm}px)`,
        },
        paddingBottom: mobileMode ? appNavigationHeightSm + 10 : appNavigationHeightSm,
      },
    },
    menuUser: {
      '&$menuUserUnAuth': {
        justifyContent: 'center',
      },
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '15px',
      marginTop: '40px',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between',
        marginBottom: '16px',
        marginLeft: '16px',
        marginRight: '16px',
        marginTop: '16px',
      },
    },
    menuUserAvatar: {
      '& svg': {
        '& path': {
          fill: ColorsPalette.neutral2,
        },
        height: 33,
        width: 33,
      },
      alignItems: 'center',
      backgroundColor: ColorsPalette.neutral1,
      borderRadius: '50%',
      display: 'flex',
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 700,
      height: '40px',
      justifyContent: 'center',
      lineHeight: '160%',
      marginLeft: '3px',
      marginRight: '14px',
      width: '40px',
      [theme.breakpoints.down('md')]: {
        backdropFilter: 'blur(16px)',
        background: 'rgba(255, 255, 255, 0.08)',
        color: ColorsPalette.coralPinkLight,
        flex: '0 0 96px',
        fontFamily: FontCollection.inter,
        fontSize: '26px',
        fontWeight: 900,
        height: '96px',
        marginLeft: '0px',
        marginRight: '0px',
        order: 1,
        width: '96px',
      },
    },
    menuUserBonus: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('md')]: {
        alignSelf: 'flex-end',
        order: 0,
      },
    },
    menuUserBonusLabel: {
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 400,
      [theme.breakpoints.down('md')]: {
        color: ColorsPalette.coralPinkLight,
      },
    },
    menuUserBonusPrimary: {
      '& $menuUserBonusValue, $menuUserBonusLabel': {
        color: ColorsPalette.primary,
      },
    },
    menuUserBonusValue: {
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 700,
      [theme.breakpoints.down('md')]: {
        color: ColorsPalette.neutral2,
      },
    },
    menuUserEdge: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flex: '1 1 110px',
        maxWidth: '110px',
      },
    },
    menuUserEdgeEnd: {
      order: 2,
    },
    menuUserUnAuth: {},
  }),
);
