import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acSearchGenderChange } from '@actions/acSearch';
import MobileMenuStyles from '@components/layout/header/MobileMenu/styles';
import { EGenderType } from '@constants/gender';
import { useTranslation } from '@hooks/useTranslation';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

import useStyles from './styles';

const MobileSearchGender: React.FunctionComponent<{}> = () => {

  const classesMenu = MobileMenuStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const gender = useSelector((state: IStore) => state.search.gender);
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const onChange = useCallback((value) => dispatch(acSearchGenderChange(value)), [dispatch]);
  return (
    <div className={classes.genderSelect}>
      <Tabs
        classes={{ indicator: classesMenu.tabsIndicator, root: classes.genderSelectTabsRoot }}
        value={gender}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab value={EGenderType.women} classes={{ root: classesMenu.tabRoot }} label={t('mobileMenu.women')} />
        <Tab value={EGenderType.man} classes={{ root: classesMenu.tabRoot }} label={t('mobileMenu.men')} />
        <Tab value={EGenderType.kids} classes={{ root: classesMenu.tabRoot }} label={t('mobileMenu.kids')} />
      </Tabs>

    </div>
  );
};

export default MobileSearchGender;
