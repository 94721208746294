import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';

const useStyles = makeStyles<Theme>((theme) => ({
  select48: {
    [theme.breakpoints.down('md')]: {
      boxSizing: 'border-box',
      height: '48px !important',
    },
  },
  sortingSelectContainer: {
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      border: '0 !important',
    },
    alignItems: 'center',
    backgroundColor: ColorsPalette.neutral2,
    borderRadius: '0.75rem',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      border: 'none',
      marginLeft: '0',
      marginRight: '0',
      width: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      flex: '1 1 37%',
      marginLeft: 6,
      marginRight: 6,
    },

  },
  sortingSelectContainerDefault: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 76px - 120px)',
      width: 'fit-content',
    },
  },
  sortingSelectContainerSmall: {
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
