import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IFilterGroupOption } from '@components/pages/productsList/Filter/Group/filterOption';
import { getSeoInfoByFilterValue } from '@components/pages/productsList/utils';
import { EGender } from '@constants/gender';
import { IWrappedCategoryMapped } from '@interfaces/category';
import { IFilter, IPageData } from '@interfaces/pageData/reducer';
import {
  EProductsListFilter, IFilterAge, IFilterFits, IFilterWaist, IProductBrand, IProductListFilterBrand,
  IProductListFilterCategories, IProductListFilterColor, IProductListSeoAttributes,
  IProductsListDataFilter, ISexItem,
} from '@interfaces/productsList';
import { IProductListDefaultSeo, IProductListFilter } from '@interfaces/productsList/reducer';
import { IStore } from '@interfaces/store';
import {
    checkIsFiltered, getMappedData, getPageSexIdNew, mapFilterData
} from '@reducers/productsList/utils';
import { getDefaultSeoInfo } from '@utils/common';

export interface IFilterData {
  [EProductsListFilter.ageId]: IFilterAge[];
  [EProductsListFilter.brandId]: IProductListFilterBrand[];
  [EProductsListFilter.categoryId]: IWrappedCategoryMapped[];
  [EProductsListFilter.categoryIdP]: IWrappedCategoryMapped[];
  [EProductsListFilter.colorGroupId]: IProductListFilterColor[];
  [EProductsListFilter.fits]?: IFilterFits[];
  [EProductsListFilter.priceFrom]: string | null;
  [EProductsListFilter.priceTo]: string | null;
  [EProductsListFilter.sexId]: ISexItem[];
  [EProductsListFilter.sizes]: string[];
  [EProductsListFilter.waists]?: IFilterWaist[];
}

export interface IFilterDataMapped {
  [EProductsListFilter.ageId]: IFilterGroupOption[];
  [EProductsListFilter.brandId]: IFilterGroupOption[];
  [EProductsListFilter.categoryId]: IFilterGroupOption[];
  [EProductsListFilter.categoryIdP]: IFilterGroupOption[];
  [EProductsListFilter.colorGroupId]: IFilterGroupOption[];
  [EProductsListFilter.fits]?: IFilterGroupOption[];
  [EProductsListFilter.sexId]: IFilterGroupOption[];
  [EProductsListFilter.sexIdMob]: IFilterGroupOption[];
  [EProductsListFilter.sizes]: IFilterGroupOption[];
  [EProductsListFilter.waists]?: IFilterGroupOption[];
}

export interface IProductsListStore {
  filterData: IFilterData;
  filterDataMapped: IFilterDataMapped;
  isFiltered: boolean;
  pageSexId: null | number | undefined;
  seo: IProductListSeoAttributes;
}

export const useProductsListStore: () => IProductsListStore = () => {
  const filterAges = useSelector<IStore, IFilterAge[]>((state) => state.productsList.dataFilter.filterAges);
  const filterBrands = useSelector<IStore, IProductListFilterBrand[]>((state) => state.productsList.dataFilter.filterBrands);
  const filterCategories = useSelector<IStore, IProductListFilterCategories[]>((state) => state.productsList.dataFilter.filterCategories);
  const filterColors = useSelector<IStore, IProductListFilterColor[]>((state) => state.productsList.dataFilter.filterColors);
  const filterMaxPrice = useSelector<IStore, string>((state) => state.productsList.dataFilter.filterMaxPrice);
  const filterMinPrice = useSelector<IStore, string>((state) => state.productsList.dataFilter.filterMinPrice);
  const filterSizes = useSelector<IStore, string[]>((state) => state.productsList.dataFilter.filterSizes);
  const filterFits = useSelector<IStore, IFilterFits[] | null>((state) => state.productsList.dataFilter.filterFits || null);
  const filterWaists = useSelector<IStore, IFilterWaist[] | null>((state) => state.productsList.dataFilter.filterWaists || null);
  const sexes = useSelector<IStore, ISexItem[]>((state) => state.productsList.dataFilter.sexes);

  const productsListFilterReducer: IProductListFilter = useSelector((state: IStore) => state.productsList.filter);

  const pageDataCategoryWrapped = useSelector((state: IStore) => state.pageData?.categoryWrapped);
  const pageDataData: IPageData = useSelector((state: IStore) => state.pageData?.data);
  const pageDataType = useSelector((state: IStore) => state.pageData?.type);
  const pageDataDataFilter: IFilter | undefined = useSelector((state: IStore) => state.pageData?.data?.filter);
  const productsListDefaultSeoReducer: IProductListDefaultSeo = useSelector((state: IStore) => state.productsList.defaultSeoInfo);
  const productsListBrands: IProductBrand[] = useSelector((state: IStore) => state.productsList?.brands || []);


  const productsListDataFilter: IProductsListDataFilter = useMemo(() => ({
    filterAges,
    filterBrands,
    filterCategories,
    filterColors,
    filterFits,
    filterMaxPrice,
    filterMinPrice,
    filterSizes,
    filterWaists,
    sexes,
  }), [filterBrands, filterCategories, filterColors, filterMaxPrice, filterMinPrice, filterSizes, sexes, filterFits, filterWaists]);

  const pageSexId = getPageSexIdNew(sexes, pageDataDataFilter);

  const activeCategories: string[] = filterCategories?.filter((cat) => cat.active).map((cat) => cat.categoryId) || undefined;

  const filterData: IFilterData = useMemo(() => mapFilterData(pageDataType, pageDataData, pageDataCategoryWrapped, productsListDataFilter, productsListFilterReducer), [pageDataType, pageDataData, pageDataCategoryWrapped, JSON.stringify(productsListDataFilter), productsListFilterReducer]);
  const filterDataMapped: IFilterDataMapped = useMemo(() => getMappedData(filterData, activeCategories, pageSexId), [JSON.stringify(filterData), activeCategories]);
  const sexInFilter = productsListFilterReducer[EProductsListFilter.sexId];
  if (
    pageSexId &&
    (sexInFilter === undefined ||
      !sexInFilter.length ||
      (typeof window === 'undefined' && sexInFilter.length === 1 && sexInFilter[0] !== pageSexId))
  ) {
    productsListFilterReducer[EProductsListFilter.sexId] = [`${pageSexId}`];
  } else if (
    !pageSexId &&
    (productsListFilterReducer[EProductsListFilter.sexId] === undefined ||
      !productsListFilterReducer[EProductsListFilter.sexId].length)
  ) {
    productsListFilterReducer[EProductsListFilter.sexId] = [`${EGender.women}`];
  }

  const isFiltered = checkIsFiltered(productsListFilterReducer, filterData);


  const brands = filterBrands.length > 0 ? filterBrands : productsListBrands;
  const seoResult: IProductListSeoAttributes = isFiltered
    ? getSeoInfoByFilterValue(
      brands,
      productsListDefaultSeoReducer,
      productsListFilterReducer,
      filterData[EProductsListFilter.categoryId],
      pageSexId
    )
    : getDefaultSeoInfo(productsListDefaultSeoReducer);

  const result: IProductsListStore = {
    filterData,
    filterDataMapped,
    isFiltered,
    pageSexId,
    seo: seoResult,
  };
  return result;
};

export interface IProductsListMobileFilter {
  filterDataMobile: IFilterData;
  filterDataMobileMapped: IFilterDataMapped;
}

export const useProductsListMobileFilterStore: () => IProductsListMobileFilter = () => {

  const filterAgesMobile = useSelector<IStore, IFilterAge[]>((state) => state.productsList.dataMobileFilter.filterAges);
  const filterBrandsMobile = useSelector<IStore, IProductListFilterBrand[]>((state) => state.productsList.dataMobileFilter.filterBrands);
  const filterCategoriesMobile = useSelector<IStore, IProductListFilterCategories[]>((state) => state.productsList.dataMobileFilter.filterCategories);
  const filterColorsMobile = useSelector<IStore, IProductListFilterColor[]>((state) => state.productsList.dataMobileFilter.filterColors);
  const filterMaxPriceMobile = useSelector<IStore, string>((state) => state.productsList.dataMobileFilter.filterMaxPrice);
  const filterMinPriceMobile = useSelector<IStore, string>((state) => state.productsList.dataMobileFilter.filterMinPrice);
  const filterSizesMobile = useSelector<IStore, string[]>((state) => state.productsList.dataMobileFilter.filterSizes);
  const sexesMobile = useSelector<IStore, ISexItem[]>((state) => state.productsList.dataMobileFilter.sexes);
  const filterFits = useSelector<IStore, IFilterFits[] | null>((state) => state.productsList.dataMobileFilter.filterFits || null);
  const filterWaists = useSelector<IStore, IFilterWaist[] | null>((state) => state.productsList.dataMobileFilter.filterWaists || null);

  const productsListFilterReducer: IProductListFilter = useSelector((state: IStore) => state.productsList.filter);

  const pageDataCategoryWrapped = useSelector((state: IStore) => state.pageData?.categoryWrapped);
  const pageDataData: IPageData = useSelector((state: IStore) => state.pageData?.data);
  const pageDataType = useSelector((state: IStore) => state.pageData?.type);
  const productsListFilterCategories = useSelector((state: IStore) => state.productsList.dataMobileFilter.filterCategories);


  const productsListDataFilterMobile: IProductsListDataFilter = useMemo(() => ({
    filterAges: filterAgesMobile,
    filterBrands: filterBrandsMobile,
    filterCategories: filterCategoriesMobile,
    filterColors: filterColorsMobile,
    filterFits,
    filterMaxPrice: filterMaxPriceMobile,
    filterMinPrice: filterMinPriceMobile,
    filterSizes: filterSizesMobile,
    filterWaists,
    sexes: sexesMobile,
  }), [filterBrandsMobile, filterCategoriesMobile, filterColorsMobile, filterMaxPriceMobile, filterMinPriceMobile, filterSizesMobile, sexesMobile, filterFits, filterWaists]);

  const activeCategories: string[] = productsListFilterCategories?.filter((cat) => cat.active).map((cat) => cat.categoryId) || undefined;

  const filterDataMobile: IFilterData = useMemo(() => mapFilterData(pageDataType, pageDataData, pageDataCategoryWrapped, productsListDataFilterMobile, productsListFilterReducer), [pageDataType, pageDataData, pageDataCategoryWrapped, productsListDataFilterMobile, productsListFilterReducer]);

  const filterDataMobileMapped: IFilterDataMapped = useMemo(() => getMappedData(filterDataMobile, activeCategories), [filterDataMobile, activeCategories]);

  const result: IProductsListMobileFilter = {
    filterDataMobile,
    filterDataMobileMapped,
  };
  return result;
};

