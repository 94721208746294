import { IStoreItem } from '@interfaces/stores/reducer';

interface ICoords {
  x: number;
  y: number;
}

export const calculateDistances = (a: ICoords, b: ICoords) => {
  return Math.sqrt(Math.pow((b.x - a.x), 2) + Math.pow((b.y - a.y), 2));
};

const sortByDistDM = (a, b) => {
  return (a.distance - b.distance);
};

interface IStoreItemWithDistance extends IStoreItem {
  distance: number | boolean;
}

interface IFindNearestResult {
  error?: GeolocationPositionError;
  result: boolean;
  item?: IStoreItemWithDistance;
}


export const findNearest: (itemsList) => Promise<IFindNearestResult> = async (itemsList) => {
  const userPosition: {  error?: GeolocationPositionError; position?: GeolocationPosition; result: boolean } = await new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      resolve({ position, result: true });
    }, async (error) => {
      resolve({ error, result: false });
    });
  });
  if (userPosition.result && userPosition.position && itemsList.length > 0) {
    const userCoords = { x: userPosition.position?.coords.latitude, y: userPosition.position?.coords.longitude };

    const distance = itemsList.map((storeItem) => {
      const { coordinateLng, coordinateLat } = storeItem;
      if (coordinateLat && coordinateLng) {
        const itemCoords = { x: Number(coordinateLat), y: Number(coordinateLng) };
        return ({ ...storeItem, distance: calculateDistances(userCoords, itemCoords) });
      } else {
        return { ...storeItem, distance: false };
      }
    });

    const sorted = distance.filter((item) => item.distance !== false).sort(sortByDistDM);
    return { item: sorted[0], result: true };
  }

  return { error: userPosition.error, result: false };

};
