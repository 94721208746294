import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// import { mobileMode } from '@constants/config';
import { UseScrollContext } from '@context/useScroll';
import useScroll from '@hooks/scroll/scroll';
import { IStore } from '@interfaces/store';
import { setViewPortSettings } from '@utils/cookies';


const LayoutAppScrollContext: React.FunctionComponent<PropsWithChildren> = ({ children }) => {

  const { fixElement, positionBottom, resetState, unpinElement } = useScroll({ positionTopY: -1 });

  const [isKeyboardOpen, changeIsKeyboardOpen] = useState(false);
  const beforePopStateHistory = useSelector((store: IStore) => store.navigation.beforePopStateHistory);

  const getViewportWidth: () => number = () => window.visualViewport?.width || 0;

  const updateViewPortWidth = () => {
    const data = JSON.stringify({ width: Number(getViewportWidth()).toFixed(0) });
    setViewPortSettings(null, data);
  };
  const listener = () => {
    updateViewPortWidth();
    if (typeof window !== 'undefined') {
      const MIN_KEYBOARD_HEIGHT = 200;
      const visualViewport = window?.visualViewport?.height ?? 0;
      const isKeyboardOpenNew = window.screen.height - visualViewport > MIN_KEYBOARD_HEIGHT;
      changeIsKeyboardOpen(isKeyboardOpenNew);
    }
  };

  useEffect(() => {
    if (resetState && beforePopStateHistory) {
      resetState();
    }
  }, [beforePopStateHistory]);


  useEffect(() => {
    if (
    //  mobileMode &&
      window.visualViewport) {
      window.visualViewport.addEventListener('resize', listener);
    }
    return () => {

      if (
   //     mobileMode &&
        window.visualViewport) {
        window.visualViewport.removeEventListener('resize', listener);
      }
    };
  }, []);

  useEffect(() => {
    updateViewPortWidth();
  }, []);

  return (
    <UseScrollContext.Provider
      value={{ fixElement, positionBottom, unpinElement, isKeyboardOpen }}>
      {children}
    </ UseScrollContext.Provider>
  );
};

export default LayoutAppScrollContext;
