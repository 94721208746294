import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import React, { ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acGoBack } from '@actions/acNavigation';
import ErrorBoundary from '@components/common/ErrorBoundary';
import Hidden from '@components/common/Hidden';
import { PageTypes } from '@constants/pageTypes';
import BackArrowIcon from '@icons/BackArrow';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

import styles from './styles';

interface ISectionHeaderProps {
  disableAction?: boolean;
  hideAction?: boolean;
  leftAction?: () => void;
  rightAction?: () => void;
  rightActionLabel?: string | ReactNode;
  title: string | ReactNode;
}

const SectionHeader: React.FunctionComponent<ISectionHeaderProps> = ({
  disableAction = false,
  hideAction = false,
  leftAction,
  rightAction = () => null,
  rightActionLabel,
  title,
}) => {
  const classes = styles();

  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const pageType = useSelector((state: IStore) => state.pageData.type);

  const onGoBack = useCallback(() => dispatch(acGoBack()), [dispatch]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <ErrorBoundary>
      <div className={classes.containerHeader}>
        <div className={classNames(classes.containerAction, classes.containerActionLeft)} onClick={() => {
          if (!disableAction && !(isDesktop && hideAction)) {
            if (leftAction) {
              leftAction();
            } else {
              onGoBack();
            }
          }
        }}>
          {!disableAction && (
            <Hidden mdUp={hideAction}>
              <BackArrowIcon
                className={classNames(classes.action, { [classes.hoverFill]: ![PageTypes.stores].includes(pageType as PageTypes) })}
              />
            </Hidden>
          )}
        </div>
        <div className={classes.containerTitle}>{title}</div>
        <div
          className={classNames(classes.action, classes.containerAction, classes.containerActionRight)}
          onClick={rightAction}
        >
          {rightActionLabel}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default SectionHeader;
