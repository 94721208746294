import React from 'react';

export const Map360Icon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12ZM3.64232 8.65452C4.5557 6.37479 6.3748 4.5557 8.65452 3.64232C8.11964 4.67487 7.70006 5.96224 7.41726 7.41726C5.96224 7.70006 4.67487 8.11964 3.64232 8.65452ZM15.3455 3.64232C15.8804 4.67487 16.2999 5.96224 16.5827 7.41726C18.0378 7.70006 19.3251 8.11964 20.3577 8.65452C19.4443 6.37479 17.6252 4.5557 15.3455 3.64232ZM3 12C3 11.1008 4.62868 10.125 7.11448 9.53819C7.03905 10.3308 7 11.155 7 12C7 12.845 7.03905 13.6692 7.11448 14.4618C4.62868 13.875 3 12.8992 3 12ZM17 12C17 12.845 16.961 13.6692 16.8855 14.4618C19.3713 13.875 21 12.8992 21 12C21 11.1008 19.3713 10.125 16.8855 9.53819C16.961 10.3308 17 11.155 17 12ZM3.64232 15.3455C4.67487 15.8804 5.96224 16.2999 7.41726 16.5827C7.70006 18.0378 8.11964 19.3251 8.65452 20.3577C6.37479 19.4443 4.5557 17.6252 3.64232 15.3455ZM16.5827 16.5827C16.2999 18.0378 15.8804 19.3251 15.3455 20.3577C17.6252 19.4443 19.4443 17.6252 20.3577 15.3455C19.3251 15.8804 18.0378 16.2999 16.5827 16.5827ZM9 12C9 11.0097 9.06211 10.0597 9.17262 9.17262C10.0597 9.06211 11.0097 9 12 9C12.9903 9 13.9403 9.06211 14.8274 9.17262C14.9379 10.0597 15 11.0097 15 12C15 12.9903 14.9379 13.9403 14.8274 14.8274C13.9403 14.9379 12.9903 15 12 15C11.0097 15 10.0597 14.9379 9.17262 14.8274C9.06211 13.9403 9 12.9903 9 12ZM9.53819 16.8855C10.125 19.3713 11.1008 21 12 21C12.8992 21 13.875 19.3713 14.4618 16.8855C13.6692 16.961 12.845 17 12 17C11.155 17 10.3308 16.961 9.53819 16.8855ZM12 3C11.1008 3 10.125 4.62868 9.53819 7.11448C10.3308 7.03905 11.155 7 12 7C12.845 7 13.6692 7.03905 14.4618 7.11448C13.875 4.62868 12.8992 3 12 3Z"
            fill="black" />
    </svg>
  );
};
