import { useDispatch, useSelector } from 'react-redux';

import { acPreGoToPage } from '@actions/acPageData';
import { mobileMode } from '@constants/config';
import { PageTypes } from '@constants/pageTypes';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import { pushOrReloadRoute } from '@utils/routes';

interface IOptions {
  type: 'sexId';
  value: number | string;
}

export const useGoToPage = () => {
  const pages = useSelector((state: IStore) => state.pageData?.data?.pages || []);
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();

  const goToPage = (pageType: PageTypes, options?: IOptions) => {

    const pageToGo = pages?.find((page) => {
      if (options === undefined) {
        return page.type === pageType;
      }

      let conditions = page.type === pageType;
      switch (options.type) {
        case 'sexId':
          conditions = conditions && page.filter?.sexId === options.value;
          break;
      }

      return conditions;
    });

    if (pageToGo) {
      if(mobileMode){
        dispatch(acPreGoToPage(pageType));
      }
      pushOrReloadRoute(pageToGo.url);
    }
  };

  return {
    goToPage,
  };
};
