import { makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';

import { mobileMode } from '@constants/config';

export default makeStyles((theme) => ({
  goTopButton: {
    '& svg': {
      '& path': {
        fill: ColorsPalette._gotop_arrow,
      },
      transform: 'rotate(90deg)',
    },
    '@supports (padding-top: constant(safe-area-inset-bottom))': {
      bottom: mobileMode ? `calc(constant(safe-area-inset-bottom)*2 + 60px)` : 20,
    },
    '@supports (padding-top: env(safe-area-inset-bottom))': {
      bottom: mobileMode ? `calc(env(safe-area-inset-bottom)*2 + 60px)` : 20,
    },
    alignItems: 'center',
    background: ColorsPalette.neutral2,
    borderRadius: '50%',
    bottom: mobileMode ? 60 : 20,
    boxShadow: `0px 2px 5px 1px ${ColorsPalette._gotop_border}`,
    cursor: 'pointer',
    display: 'flex',
    flex: '0 0 40px',
    height: '40px',
    justifyContent: 'center',
    left: -45,
    position: 'fixed',
    transition: 'all 0.5s ease-in-out',
    width: '40px',
    zIndex: 10,
  },
  goTopButtonFix: {
    left: 20,
  },
}));
