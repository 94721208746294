import React from 'react';

import CustomBannerContainer from '@components/common/customBanners/common/Container';
import Hidden from '@components/common/Hidden';
import { mobileMode } from '@constants/config';
import { getMediaUrl } from '@constants/urls';
import { IBannerAttributes, ICustomBanner } from '@interfaces/customBanners/actions';
import Link from '../../../Link';
// import styles from '../../styles';

interface Params {
  banner: ICustomBanner;
}

const CustomBannerTypeBanner: React.FC<Params> = ({ banner }) => {
  // const classes = styles({});
  const attributes: IBannerAttributes = banner?.attributes as IBannerAttributes;
  return (
    <CustomBannerContainer banner={banner}>
      {!mobileMode ? (
        <>
          {attributes.image && (
            <Hidden mdDown>
              {renderLink(attributes.link, attributes.open_new_window, <img src={getMediaUrl(attributes.image)}
                                                                            width="100%" height="auto" />)}
            </Hidden>
          )}
          {attributes.image_mobile && (
            <Hidden mdUp>
              {renderLink(attributes.link, attributes.open_new_window, <img src={getMediaUrl(attributes.image_mobile)}
                                                                            width="100%" height="auto" />)}
            </Hidden>
          )}
        </>
      ) : (attributes.image_app && (
        <>
          {renderLink(attributes.link, attributes.open_new_window, <img src={getMediaUrl(attributes.image_app)}
                                                                        width="100%" height="auto" />)}
        </>
      ))}
    </CustomBannerContainer>);
};


const renderLink = (link: string | undefined, open_new_window: boolean, children: JSX.Element) => {
  if (!link) {
    return children;
  }

  if (open_new_window) {
    return <a href={link} target="_blank">{children}</a>;
  }

  return <Link href={link}>{children}</Link>;
};

export default CustomBannerTypeBanner;
