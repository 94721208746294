import React from 'react';

const ShapeIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M19.5002 15.7502L22.8112 12.4392C23.3968 11.8534 23.3968 10.9039 22.8112 10.3182L20.7002 8.21218C20.0432 9.77096 18.4241 10.697 16.7474 10.4727C15.0708 10.2485 13.7518 8.92956 13.5276 7.25293C13.3034 5.5763 14.2294 3.95711 15.7882 3.30018L13.6822 1.18918C13.0964 0.603607 12.1469 0.603607 11.5612 1.18918L8.25018 4.50018C8.25018 2.42911 6.57125 0.75018 4.50018 0.75018C2.42911 0.75018 0.75018 2.42911 0.75018 4.50018C0.75018 6.57125 2.42911 8.25018 4.50018 8.25018L1.18918 11.5612C0.603607 12.1469 0.603607 13.0964 1.18918 13.6822L3.30018 15.7882C3.95711 14.2294 5.5763 13.3034 7.25293 13.5276C8.92956 13.7518 10.2485 15.0708 10.4727 16.7474C10.697 18.4241 9.77096 20.0432 8.21218 20.7002L10.3182 22.8072C10.9039 23.3928 11.8534 23.3928 12.4392 22.8072L15.7502 19.5002C15.7502 21.5712 17.4291 23.2502 19.5002 23.2502C21.5712 23.2502 23.2502 21.5712 23.2502 19.5002C23.2502 17.4291 21.5712 15.7502 19.5002 15.7502Z"
            stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ShapeIcon;
