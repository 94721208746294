export const defaultMobileMenuValue = {
  bottom: { show: true },
  brands: { show: true, showInApp: true, brands: [] },
  categories: { show: true, showInApp: true },
  important: { showInApp: true },
  notifiction: { show: true, showInApp: true },
  popular_products: { show: true, showInApp: true },
  sign_up: { show: true, showInApp: true },
};

export const mobileMenuTop = 48;
