import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  acPageCountrySelectAction,
  acPageSideMenuPageAction,
  acPageSideMenuStateAction,
} from '@actions/acPage';
import AccountMenuItem from '@components/pages/account/Menu/MenuItem';
import AccountMenuPage from '@components/pages/account/Menu/pageSkelet';
import { mobileMode } from '@constants/config';
import { PageTypes } from '@constants/pageTypes';
import { useTranslation } from '@hooks/useTranslation';
import AccountIcon from '@icons/Account';
import { Map360Icon } from '@icons/Map360';
import NotificationIcon from '@icons/Notification';
import { PinIcon } from '@icons/Pin';
import SettingsIcon from '@icons/Settings';
import ShapeIcon from '@icons/Shape';
import { IAccountMenuItem } from '@interfaces/account';
import { TThunkDispatch } from '@interfaces/index';
import { ESideMenuPage } from '@interfaces/page/store';

import stylesAccount from './../styles';
import stylesMenuItem from './MenuItem/styles';

const AccountMenuUnAuth: React.FunctionComponent<{}> = () => {
  const classesMenuItem = stylesMenuItem();
  const classesAccount = stylesAccount();

  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const { t } = useTranslation();

  const onSideMenuHandle = useCallback((state) => dispatch(acPageSideMenuStateAction(state)), [dispatch]);
  const onSideMenuPageHandle = useCallback((page) => dispatch(acPageSideMenuPageAction(page)), [dispatch]);
  const onAccountClick = () => {
    onSideMenuPageHandle(ESideMenuPage.main);
    onSideMenuHandle(true);
  };

  const onCountrySelectHandle = useCallback(() => dispatch(acPageCountrySelectAction(true)),[dispatch]);

  const menuItems: IAccountMenuItem[] = [
    {
      action: onAccountClick,
      icon: <AccountIcon className={classesMenuItem.hoverFill} />,
      label: t('account.menu_login_signup'),
    },
    {
      icon: <PinIcon />,
      label: t('common.stores'),
      type: PageTypes.stores,
    },
    {
      action: onCountrySelectHandle,
      icon: <Map360Icon />,
      label: t('account.menu_countrySelect'),
    },
    {
      icon: <ShapeIcon />,
      label: t('account.helpAndFAQs'),
      type: PageTypes.account_help,
    },
  ];

  if (mobileMode) {
    menuItems.push(  {
      icon: <NotificationIcon className={classesMenuItem.hoverStroke} />,
      label: t('account.notifications'),
      type: PageTypes.account_notifications,
    },);
    menuItems.push({
      icon: <SettingsIcon />,
      label: t('account.menu_settings'),
      type: PageTypes.account_settings,
    });
  }

  return (
    <div className={classesAccount.container}>
      <AccountMenuPage menuItems={<>{menuItems.map((item, key) => (
        <AccountMenuItem key={`accountMenuItem${key}`} item={item} />
      ))}</>} />
    </div>
  );
};

export default AccountMenuUnAuth;
