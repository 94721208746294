import * as actions from '@constants/actions/lookbook';
import { RequestMethods } from '@constants/types';
import { getLookBookItemsUrl, getProductsByIdsUrl } from '@constants/urls';
import { IStore } from '@interfaces/store';
import { CALL_API } from 'services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';

type TGetLookBookItems = (params: { country: string; lang: string; from: number; itemsPerPage: number }) => any;
export const acLookBookItemsGet: TGetLookBookItems = ({ country, lang, from, itemsPerPage }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getLookBookItemsUrl(), {
      countryCode: country,
      from,
      languageCode: lang,
      nrItems: itemsPerPage,
    }, true),
    method: RequestMethods.GET_METHOD,
    types: from > 0
      ? [actions.LOOKBOOK_GET_ITEMS_NEXT_REQUEST, actions.LOOKBOOK_GET_ITEMS_NEXT_SUCCESS, actions.LOOKBOOK_GET_ITEMS_NEXT_FAILED]
      : [actions.LOOKBOOK_GET_ITEMS_REQUEST, actions.LOOKBOOK_GET_ITEMS_SUCCESS, actions.LOOKBOOK_GET_ITEMS_FAILED],
  },
});


type TLookBookItemsProductsGets = (params: { country: string; lang: string; productId: string [] | number[]; sessionId: string }) => any;
export const acLookBookItemsProductsGet: TLookBookItemsProductsGets = ({ country, lang, productId, sessionId }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getProductsByIdsUrl(), {
      country,
      lang,
      productId,
      sessionId,
    }),
    facebook: true,
    method: RequestMethods.GET_METHOD,
    types: [actions.LOOKBOOK_GET_ITEMS_PRODUCTS_REQUEST, actions.LOOKBOOK_GET_ITEMS_PRODUCTS_SUCCESS, actions.LOOKBOOK_GET_ITEMS_PRODUCTS_FAILED],
  },
});

export const acInitLookBookDetails = () => async (dispatch, getState: () => IStore) => {
  const {
    auth: { sessionId },
    pageData: { countryCode, languageCode, data: { productIds } },
  } = getState();
  if (countryCode && languageCode && productIds && sessionId) {
    await dispatch(acLookBookItemsProductsGet({
      country: countryCode,
      lang: languageCode,
      productId: productIds,
      sessionId,
    }));
  }

};
