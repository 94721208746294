import React, { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acMobileMenuChangeState } from '@actions/acPage';
import { mobileMode } from '@constants/config';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import { TThunkDispatch } from '@interfaces/index';
import { IMobileMenuState } from '@interfaces/page/store';
import { IStore } from '@interfaces/store';
import { setMobileMenuStateSessionStorage } from '@utils/sessionStorage';

const MobileMenuRestore: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();

  const mobileMenu = useSelector((state: IStore) => state.page.mobileMenu);
  const changingRoute = useSelector((state: IStore) => state.navigation.changingRoute);
  const shallowPageChange = useSelector((state: IStore) => state.pageData.shallowPageChange);
  const { open, preventInit = false } = mobileMenu;

  const isMenuOpen = open || mobileMode;
  const onMobileMenuChangeState = useCallback((menuState: Partial<IMobileMenuState>) => dispatch(acMobileMenuChangeState(menuState)), [dispatch]);

  const lastHistoryIndex = useRef(0);
  const saveMenuState = () => {
    if (isMenuOpen) {
      window.history.replaceState({ ...window.history.state, mobileMenu: { ...mobileMenu, open: isMenuOpen } }, '');
      lastHistoryIndex.current = window.history.state.key;
      setMobileMenuStateSessionStorage({ ...mobileMenu, open: isMenuOpen });
    }
  };

  const saveMenuStateClosed = () => {
    if (window.history.state?.mobileMenu?.open) {
      window.history.replaceState({
        ...window.history.state,
        mobileMenu: { ...window.history.state.mobileMenu, open: false },
      }, '');
    }
  };

  const getMenuState = () => {
    const historyState = window?.history.state?.mobileMenu;
    const isSamePage = lastHistoryIndex.current === window?.history.state?.key;
    if (historyState?.open && !preventInit && !isSamePage) {
      onMobileMenuChangeState(historyState);
    }
  };

  useEffect(() => {
    if (!mobileMode) {
      if (changingRoute) {
        saveMenuState();
      } else {
        getMenuState();
      }
    }

  }, [changingRoute]);

  useDidUpdateEffect(() => {
    if (mobileMode) {
      if (shallowPageChange) {
        saveMenuState();
      } else {
        getMenuState();
      }
    }

  }, [shallowPageChange]);

  useEffect(() => {
    if (!isMenuOpen && !mobileMenu) {
      saveMenuStateClosed();
    }
  }, [isMenuOpen]);

  return (
    <>
      {children}
    </>
  );
};

export default MobileMenuRestore;
