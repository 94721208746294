import React, { useMemo } from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import useScroll from '@hooks/scroll/scroll';
import AppBar from './AppBar';
import MenuBar from './MenuBar';

const HeaderDesktop: React.FunctionComponent<{}> = () => {
  const { fixElement, unpinElement } = useScroll({});

  const canSHowMegaMenu = useMemo(() => {
    return (!fixElement && !unpinElement) || (fixElement && unpinElement);
  }, [fixElement, unpinElement]);

  return (
    <ErrorBoundary>
      <AppBar />
      <MenuBar canShowMenu={canSHowMegaMenu} />
    </ErrorBoundary>
  );
};

export default HeaderDesktop;
