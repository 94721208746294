import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { mobileMode } from '@constants/config';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

const useStyles = makeStyles<Theme>((theme) => ({
  description: {
    color: ColorsPalette.primary,
    fontFamily: FontCollection.raleway,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '124%',
    marginBottom: '30px',
    maxWidth: '416px',
    textAlign: 'center',
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    alignItems: 'center',
    backgroundColor: ColorsPalette.neutral2,
    border: '0',
    borderRadius: mobileMode ? 0 : '4px',
    boxShadow: theme.shadows[0],
    display: 'flex',
    flexDirection: 'column',
    height: mobileMode ? '100%' : 'auto',
    justifyContent: 'center',
    outline: 'none',
    padding: 16,
    position: 'relative',
  },
  title: {
    color: ColorsPalette.primary,
    fontFamily: FontCollection.inter,
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '124%',
    margin: 0,
    textAlign: 'center',
  },
}));

export default useStyles;
