const getBackgroundTargetElement = (): Element | null => {
  const elements = ['home-slider', 'home-banner', 'custom-cards', 'youtbe-banner'];
  const foundElements: { top: number, element: any }[] = [];

  for (let i = 0; i < 4; i += 1) {
    const className = elements[i];
    const homeSliders = document.getElementsByClassName(className);
    if (homeSliders.length > 0) {
      const element = homeSliders[0];
      const { top } = element.getBoundingClientRect();
      foundElements.push({ top, element });
    }
  }
  if (foundElements.length > 0) {
    return foundElements.sort((a, b) => a.top - b.top).shift()?.element;
  }

  return null;
};

const getHeaderElement = (): Element | null => {
  return document.getElementById('chrome-sticky-header');
};

const getElementHeight = (element: any): number => {
  const style = element.currentStyle || window.getComputedStyle(element);
  const height = element.offsetHeight;
  const margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom);
  const padding = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
  const border = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);

  return height + margin + padding + border;
};

interface Position {
  x: number;
  y: number;
}

const getPositionAtBottom = (element: any): Position => {
  const { top, left, width, height } = element.getBoundingClientRect();
  return {
    x: left + width,
    y: top + height,
  };
};

const getDistanceBetweenElements = (el1: any, el2: any, el2Height: number): number => {
  const el1Position = getPositionAtBottom(el1);
  const el2Position = getPositionAtBottom(el2);
  const el1PositionY = el1Position.y;
  const el2PositionY = el2Position.y;

  return el2PositionY - el2Height - el1PositionY;
};

export const getBackgroundHeight = (): number => {
  if (typeof window === 'undefined') {
    return 0;
  }

  const headerElement = getHeaderElement();
  const element = getBackgroundTargetElement();
  if (!headerElement || !element) {
    return 0;
  }

  const homePaddingTop = 40;
  const headerHeight = getElementHeight(headerElement);
  const elementHeight = getElementHeight(element);

  const distance = getDistanceBetweenElements(headerElement, element, elementHeight);
  return headerHeight + homePaddingTop + distance + elementHeight * 0.7;
};
