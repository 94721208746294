import React, { useState } from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import ModalComponent from '@components/common/Modal';
import CustomerSupport from '@components/pages/cart/components/CustomerSupport';
import DialogIcon from '@icons/messages-people-person-bubble-circle-1.svg';

import headerStyles from './../Header/styles';

const CartHeaderChat: React.FunctionComponent<{}> = () => {

  const classesHeaderStyles = headerStyles();

  const [showModal, handleModal] = useState(false);

  return (
    <ErrorBoundary>
      <div className={classesHeaderStyles.headerActiveStroke} onClick={() => handleModal(true)}>
        <DialogIcon width="24px" height="24px" />
      </div>
      {showModal && (
        <ModalComponent open={showModal} title={''} onClose={() => handleModal(false)}>
          <CustomerSupport closeModal={() => handleModal(false)} />
        </ModalComponent>
      )}
    </ErrorBoundary>
  );
};

export default CartHeaderChat;
