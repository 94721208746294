import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from '@interfaces/store';
import { getFrontpageColor } from '@utils/siteSettings';
import { getBackgroundHeight } from './layoutUtils';

import LayoutStyles from './styles';

const LayoutMainHomeBg: React.FunctionComponent<{}> = () => {

  const data = useSelector((state: IStore) => state.pageData.data);
  const classes = LayoutStyles({ backgroundColor: getFrontpageColor(data) });

  const [bgHeight, setBgHeight] = useState<number>(681);

  useEffect(() => {
    setTimeout(() => {
      setBgHeight(getBackgroundHeight());
    }, 100);
  }, []);

  return (
    <div className={classes.homeTopBackground} style={{
      height: `${bgHeight}px`,
    }} />
  );
};

export default LayoutMainHomeBg;
