import { mobileMode } from '@constants/config';
import { appNavigationHeightSm } from '@constants/styles';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme: Theme) =>
  createStyles({
    bodyDescription: {
      alignItems: 'flex-start',
      color: ColorsPalette.primary,
      display: 'flex',
      flex: '0 1 65px',
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '160%',
      padding: '10px 21px 5px 11px',
      textAlign: 'center',
    },
    bodyTitle: {
      alignItems: 'flex-end',
      display: 'flex',
      flex: '0 1 67px',
      fontFamily: FontCollection.inter,
      fontSize: '18px',
      fontWeight: 700,
      [theme.breakpoints.down('md')]: {
        flex: '0 1 30px',
      },
    },
    button: {},
    container: {
      display: 'flex',
      flex: '1 0 0',
      flexDirection: 'row',
      fontFamily: FontCollection.raleway,
      height: '100%',
      marginTop: '64px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        '@supports (padding-bottom: constant(safe-area-inset-bottom))': {
          minHeight: mobileMode ? `calc(100vh - ${appNavigationHeightSm}px - ${appNavigationHeightSm}px - (constant(safe-area-inset-bottom) * 2))` : `calc(100vh - ${appNavigationHeightSm}px)`,
        },
        '@supports (padding-bottom: env(safe-area-inset-bottom))': {
          minHeight: mobileMode ? `calc(100vh - ${appNavigationHeightSm}px - ${appNavigationHeightSm}px - (env(safe-area-inset-bottom) * 2))` : `calc(100vh - ${appNavigationHeightSm}px)`,
        },
        flexDirection: 'column',
        marginTop: '0',
        minHeight: mobileMode ? `calc(100vh - ${appNavigationHeightSm}px - ${appNavigationHeightSm}px)` : `calc(100vh - ${appNavigationHeightSm}px)`,
      },
    },
    containerRight: {
      border: '1px solid ' + ColorsPalette.neutral1,
      borderRadius: '12px',
      boxSizing: 'border-box',
      display: 'flex',
      flex: '1 1 0',
      flexDirection: 'column',
      padding: '32px 32px',
      [theme.breakpoints.down('md')]: {
        border: 'none',
        padding: !mobileMode ? '0 16px' : '0 16px '+appNavigationHeightSm+'px 16px',
        width: '100%',
      },
    },
    deleteAction: {
      '&:hover': {
        color: ColorsPalette.hoverActionCTA,
      },
      cursor: 'pointer',
      fontWeight: 700,
      marginTop: '32px',
    },
    error: {
      color: ColorsPalette.salesDark,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '328px',
      width: '100%',
    },
    loginButtonsWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      maxWidth: '350px',
      width: '90%',
    },
    wrap: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: '32px',
      [theme.breakpoints.down('md')]: {
        padding: '12px 0',
      },
    },
  }),
);
