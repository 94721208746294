import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acCustomerDeliveryMethodChangePage } from '@actions/acAccount';
import { acChangePagePrev } from '@actions/acCart';
import { acGoToPage } from '@actions/acNavigation';
import HeaderApp from '@components/layout/MobileApp/Header/index';
import CartHeaderChat from '@components/pages/cart/components/ChatAction';
import SortOrder from '@components/pages/wishList/ProdusctsList/SortOrder';
import { EUserAgent } from '@constants/app';
import { mobileMode } from '@constants/config';
import { PageTypes } from '@constants/pageTypes';
import { orderTrack } from '@constants/routes';
import { orderInvoiceUrl, orderRetailInvoiceUrl } from '@constants/urls';
import { useTranslation } from '@hooks/useTranslation';
import LoadingIcon from '@icons/Loading';
import { EDeliveryMethodPage } from '@interfaces/account/reducer';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useProductsListStore } from '@reducers/productsList/useState';
import { replaceRoute } from '@utils/routes';
import { getUserAgentType } from '@utils/userAgent';

import sectionHeaderStyles from '@components/pages/account/common/SectionHeader/styles';
import { addParamsToUrl } from '../../../../services/SSRService/utils';
import styles from './styles';

const HeaderAppComponent: React.FunctionComponent = () => {
  const classes = styles();
  const sectionHeaderClasses = sectionHeaderStyles();

  const dispatch = useDispatch<TThunkDispatch>();
  const { t } = useTranslation();
  const queryParams = useSelector((state: IStore) => state.context?.query?.params || []);
  const paramsLength = queryParams.length || 0;
  const pages = useSelector((state: IStore) => state.pageData?.data?.pages || []);
  const deliveryMethodPage = useSelector((state: IStore) => state.account.deliveryMethodPage);
  const pageType = useSelector((state: IStore) => state.pageData.type);
  const wishListLength = useSelector((state: IStore) => state.wishList.list.length || 0);
  const cartPage: number = useSelector((state: IStore) => state.cart.page);
  const orderDetails = useSelector((state: IStore) => state.account.orderDetails);
  const ordersRetailDetails = useSelector((state: IStore) => state.account.ordersRetailDetails);
  const contextQuery = useSelector((state: IStore) => state.context.query);
  const { seo } = useProductsListStore();
  const loadingProductList = useSelector((state: IStore) => state.productsList.loading);
  const filter = useSelector((state: IStore) => state.productsList.filter);
  const count = useSelector((state: IStore) => state.productsList.data.count);
  const isAuthorized = useSelector((state: IStore) => state.auth.isAuthorized);
  const shipmentMethods = useSelector((state: IStore) => state.account?.shipmentMethods || []);
  const deliveryMethodDetails = useSelector((state: IStore) => state.account?.deliveryMethodDetails || null);

  const countryCode = useSelector((state: IStore) => state.pageData?.countryCode);
  const languageCode = useSelector((state: IStore) => state.pageData?.languageCode);
  const { textSearch } = filter;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const userAgent = useSelector((state: IStore) => state.context.userAgent);
  const uaString: string = userAgent?.ua ?? '';
  const uaType = getUserAgentType(uaString);

  const titleProductList = textSearch ? (loadingProductList ?
    <LoadingIcon className={classes.headerAppLoadingIcon} /> : t('search.titleCount', { count })) : seo.seoH1Tag;

  const getTitleAccountDelivery = () => {
    switch (deliveryMethodPage) {
      case EDeliveryMethodPage.newMethodDetails :
        return t('account.deliveryItemAddTerminal');
      case EDeliveryMethodPage.newMethod :
        return t('account.deliveryItemAdd');
      default:
        return contextQuery?.params && contextQuery?.params?.length > 1 ? t('account.myDeliveryMethodDetails') : t('account.myDeliveryMethods');
    }
  };

  const getTitle = () => {
    switch (pageType) {
      case PageTypes.cart:
        return t('cart.title');
      case PageTypes.fresh:
      case PageTypes.productsList:
      case PageTypes.search:
      case PageTypes.outlet:
      case PageTypes.kids:
        return titleProductList;
      case PageTypes.wishlist:
        return wishListLength > 0 ? t('wishlist.title') : `${t('wishlist.title')} (${wishListLength})`;
      case PageTypes.account_changePassword:
        return t('account.changePassword');
      case PageTypes.account_loyalty:
        return t('account.loyalty');
      case PageTypes.account_linkedAccounts:
        return t('account.linkedAccounts');
      case PageTypes.account_help:
        return t('account.helpAndFAQs');
      case PageTypes.account_delivery:
        return getTitleAccountDelivery();
      case PageTypes.account_payment:
        return t('account.myPaymentsMethods');
      case PageTypes.account_notifications:
        return t('account.notifications');
      case PageTypes.account_details:
        return t('account.myDetails');
      case PageTypes.account_settings:
        return t('account.settingsTitle');
      case PageTypes.account_orders:
        if (paramsLength === 5 && queryParams[4] === orderTrack) {
          return t('account.orderTracking', { orderId: orderDetails?.orderId || ordersRetailDetails?.orderNumber });
        }
        return paramsLength > 3 ? t('account.orderNr', { orderId: orderDetails?.orderId || ordersRetailDetails?.orderNumber }) : t('account.orders');
      case PageTypes.account:
        return isAuthorized ? t('account.title') : '';
      case PageTypes.productDetail:
        return '';
      default:
        return '';
    }
  };

  const getDisableAction = (): boolean => {
    switch (pageType) {
      case PageTypes.home:
      case PageTypes.order_confirmed:
        return true;
      case PageTypes.cart:
        return cartPage === 1 || cartPage > 3;
      case PageTypes.account_loyalty:
        return mobileMode;
      default:
        return false;
    }
  };

  const getRightActionLabel = () => {
    switch (pageType) {
      case PageTypes.wishlist:
        return wishListLength > 0 ? <SortOrder small={true} /> : null;
      case PageTypes.cart:
        return <CartHeaderChat />;
      case PageTypes.account_delivery:
        if (deliveryMethodPage === EDeliveryMethodPage.main) {
          if (deliveryMethodDetails) {
            const currentShipmentMethod = shipmentMethods.find(
              (methodItem) =>
                Number(methodItem.shipmentMethodId) === Number(deliveryMethodDetails?.attributes['ext-service-code']),
            );
            return currentShipmentMethod ? <img src={currentShipmentMethod.methodLogo} /> : null;
          }
          return deliveryMethodPage === EDeliveryMethodPage.main && t('common.add');
        }
        return null;
      case PageTypes.account_orders:
        if (paramsLength === 4 && (orderDetails?.invoiceName || ordersRetailDetails?.orderNumber)) {
          const invoiceUrl = orderDetails?.invoiceName ? orderInvoiceUrl(orderDetails?.invoiceName) : ordersRetailDetails?.orderNumber ? orderRetailInvoiceUrl(ordersRetailDetails?.retailOrderNumber) : null;
          return invoiceUrl && (
            <a href={addParamsToUrl(invoiceUrl, { country: countryCode, lang: languageCode })} target={mobileMode && uaType === EUserAgent.android ? '_self' : '_blank'}>
              {isMobile ? t('account.pdf') : t('account.pdfDownload')}
            </a>
          );
        }
        return null;
      default:
        return null;
    }
  };

  const onCustomerDeliveryMethodChangePage = useCallback((page: EDeliveryMethodPage) => dispatch(acCustomerDeliveryMethodChangePage(page)), [dispatch]);
  const getRightAction = () => {
    switch (pageType) {
      case PageTypes.account_delivery:
        return () => onCustomerDeliveryMethodChangePage(EDeliveryMethodPage.newMethod);
      default:
        return undefined;
    }
  };

  const getBorderState = (): boolean => {
    switch (pageType) {
      case PageTypes.cart:
        return true;
      default:
        return false;
    }
  };

  const getClassName = useMemo((): string => {
    switch (pageType) {
      case PageTypes.account:
        return classNames(sectionHeaderClasses.leftActionCoralPink);
      case PageTypes.stores:
        return classNames(sectionHeaderClasses.stores);
      case PageTypes.productDetail:
        return classNames(classes.headerAppTransparent);
      default:
        return '';
    }
  }, [pageType]);

  const onChangeCartPagePrev = useCallback(() => dispatch(acChangePagePrev()), [dispatch]);
  const onBackFromDeliveryDetails = () => {
    const mainDeliveryMenu = pages?.find((menuItem) => menuItem.type === PageTypes.account_delivery);
    if (mainDeliveryMenu) {
      replaceRoute(mainDeliveryMenu.url);
    }
  };
  const getLeftActionAccountDelivery = () => {
    switch (deliveryMethodPage) {
      case EDeliveryMethodPage.newMethodDetails:
        return () => onCustomerDeliveryMethodChangePage(EDeliveryMethodPage.newMethod);
      case EDeliveryMethodPage.newMethod:
        return () => onCustomerDeliveryMethodChangePage(EDeliveryMethodPage.main);
      case EDeliveryMethodPage.main:
        if (deliveryMethodDetails) {
          return onBackFromDeliveryDetails;
        }
        return undefined;
      default:
        return undefined;
    }
  };

  const getLeftAction = () => {
    switch (pageType) {
      case PageTypes.cart:
        if (cartPage === 4) {
          return () => dispatch(acGoToPage(PageTypes.home, true));
        }
        return cartPage > 1 ? onChangeCartPagePrev : undefined;
      case PageTypes.account_delivery:
        return getLeftActionAccountDelivery();
      /*
    case PageTypes.account_orders:
      if (paramsLength === 3 && queryParams[2] === orderTrack) {
        return onBackToOrderDetails;
      }
      if (paramsLength === 2) {
        return () => dispatch(acGoToPage(PageTypes.account_orders));
      }
      return undefined;
       */
      default:
        return undefined;
    }
  };
  const getHideAction = () => {
    switch (pageType) {
      case PageTypes.account_orders:
        return paramsLength === 1;
      default:
        return false;
    }
  };

  const title = getTitle();
  const disableAction = useMemo(()=> getDisableAction(), [pageType, cartPage]);
  const rightActionLabel = getRightActionLabel();
  const rightAction = getRightAction();
  const border = getBorderState();
  const className = getClassName;
  const leftAction = getLeftAction();
  const hideAction = getHideAction();

  return (<HeaderApp
    className={className}
    border={border}
    disableAction={disableAction}
    hideAction={hideAction}
    leftAction={leftAction}
    rightAction={rightAction}
    rightActionLabel={rightActionLabel}
    title={title}
  />);
};

export default HeaderAppComponent;
