import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export const commonClasses = {
  paddingXL: {
    paddingLeft: '80px',
    paddingRight: '80px'
  },
  title18Inter: {
    fontFamily: FontCollection.inter,
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '132%'
  },
  title40: {
    fontFamily: FontCollection.inter,
    fontSize: '40px',
    fontWeight: 900,
    lineHeight: '124%'
  }
};

export default makeStyles<Theme>((theme: Theme) =>
  createStyles({
    ...commonClasses,
    boldText: {
      fontWeight: 'bold'
    },
    font: {
      fontFamily: FontCollection.raleway,
      fontSize: '14px'
    },
    link14: {
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '160%',
      textDecorationLine: 'underline'
    },
    paddingX: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '0',
        paddingRight: '0'
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: '16px',
        paddingRight: '16px'
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '16px',
        paddingRight: '16px'
      },
      [theme.breakpoints.down(360)]: {
        paddingLeft: '6px',
        paddingRight: '6px'
      }
    },
    paddingXL: {
      ...commonClasses.paddingXL,
      [theme.breakpoints.down('md')]: {
        paddingLeft: '16px',
        paddingRight: '16px'
      }
    },
    text14: {
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '160%'
    },
    text14Bold: {
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '124%'
    },
    text14Inter: {
      fontFamily: FontCollection.inter,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '160%'
    },
    title16: {
      fontFamily: FontCollection.raleway,
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '124%'
    },
    title18Inter: {
      ...commonClasses.title18Inter,
      color: ColorsPalette.primary
    },
    title32: {
      color: ColorsPalette.primary,
      fontFamily: FontCollection.inter,
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '124%'
    },
    title40: {
      ...commonClasses.title40,
      color: ColorsPalette.primary
    }
  }));
