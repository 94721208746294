import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getClientSideProps } from '@actions/getServerSidePropsBase';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

const InitSessionInLocalStorage: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const sessionLoading = useSelector<IStore, boolean>((state) => state.page.sessionLoading);

  useEffect(() => {
    if (sessionLoading) {
      dispatch(getClientSideProps());
    }
  }, [sessionLoading]);

  return null;
};

export default InitSessionInLocalStorage;
