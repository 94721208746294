import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme) => ({
  chatIcon: {
    marginBottom: '4px'
  },
  contacts: {
    justifyContent: 'space-between',
    marginLeft: 'auto',
    width: '284px'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '88px',
    [theme.breakpoints.down('md')]: {
      flex: '0 0 56px',
      height: '56px',
      justifyContent: 'space-between',
      padding: '0 15px 0 20px'
    }
  },
  headerActive: {
    cursor: 'pointer'
  },
  headerActiveFill: {
    '&:hover svg path':{
      fill: ColorsPalette.hoverActionCTA
    },
    cursor: 'pointer'
  },
  headerActiveStroke: {
    '&:hover svg path':{
      stroke: ColorsPalette.hoverActionCTA
    },
    cursor: 'pointer'
  },
  infoBlock: {
    '&:hover $chatIcon path':{
      stroke: ColorsPalette.hoverActionCTA
    },
    '&:hover $phoneIcon path':{
      fill: ColorsPalette.hoverActionCTA
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
    // width: '142px'
  },
  infoBlockText: {
    color: ColorsPalette.primary,
    cursor: 'pointer',
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '160%',
    margin: '0 0 0 11px',
    textDecorationLine: 'underline'
  },
  logo: {
    cursor: 'pointer',
    width: '120px',
    [theme.breakpoints.down('md')]: {
      marginRight: '10px',
      marginTop: '4px',
      width: '88px'
    }
  },
  phoneIcon: {
    marginBottom: '7px'
  }
}));
