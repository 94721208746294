import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { mobileMode } from '@constants/config';
import { appNavigationHeightSm, headerHeight, headerHeightSm } from '@constants/styles';
import { ColorsPalette } from '@themes/colors';
import { defaultTransition } from '@themes/transition';
import { zIndex } from '@themes/zIndex';

export default makeStyles<Theme, { backgroundColor: string }>((theme) =>
  createStyles({
    body: {
      height: '100%',
      minHeight: `calc(100vh - ${headerHeight}px)`,
      [theme.breakpoints.down('md')]: {
        '&$bodyWithoutHeader': {
          minHeight: mobileMode ? `100vh` : `calc(100vh - ${headerHeightSm}px)`,
        },
        '&:before': {
          backgroundColor: ColorsPalette.neutral2,
          content: '""',
          display: 'block',
          height: 0,
          position: 'absolute',
          transition: defaultTransition,
          width: 0,
          zIndex: zIndex.megaMenu - 1,
        },
        '@supports (padding-top: constant(safe-area-inset-bottom))': {
          paddingBottom: mobileMode ? `calc(constant(safe-area-inset-bottom) * 2 + ${appNavigationHeightSm}px)` : 0,
        },
        '@supports (padding-top: env(safe-area-inset-bottom))': {
          paddingBottom: mobileMode ? `calc(env(safe-area-inset-bottom) * 2 + ${appNavigationHeightSm}px)` : 0,
        },
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        minHeight: mobileMode ? `100vh` : `calc(100vh - ${headerHeightSm}px)`,
        paddingBottom: mobileMode ? 0 : 0,

      },
    },
    bodyLock: {
      [theme.breakpoints.down('lg')]: {
        '&:before': {
          height: '100%',
          width: '100%',
        },
      },
    },
    bodyWithoutHeader: {},
    contactsPage: {
      '& .MuiContainer-maxWidthXl': {
        padding: 0,
      },
      maxWidth: '100%',
    },
    pageBody: {
      [theme.breakpoints.down('lg')]: {
        marginLeft: 14,
        marginRight: 18,
        marginTop: 32,
      },
    },
    pageBodyY48: {
      [theme.breakpoints.down('md')]: {
        marginTop: mobileMode ? 0 : 48,
      },
    },
    pageBodyY48Banner: {
      marginTop: 16,
    },
    productsPage: {
      overflowX: 'inherit',
    },

    homeTopBackground: {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      display: 'block',
      left: '0px',
      maxHeight: '100%',
      position: 'absolute',
      top: '0px',
      width: '100%',
      // @ts-ignore
      zIndex: '-1',

      '@media (max-width: 1440px)': {
        height: 'calc(40vw + 125px)',
      },

      [theme.breakpoints.down('md')]: {
        backgroundColor: 'transparent !important',
      },
    },
  }),
);
