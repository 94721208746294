import * as actions from '@constants/actions/giftCard';
import { RequestMethods } from '@constants/types';
import { getGiftCardDesignOptionsUrl, getGiftCardUrl } from '@constants/urls';
import { EGiftCartIssueField, ICartItem } from '@interfaces/cart';
import { ICallApiAction } from '@interfaces/fetchService';
import { IGiftCardInitForm, IGiftCardUpdateForm } from '@interfaces/giftCard/actions';
import { IStore } from '@interfaces/store';
import { CALL_API } from '../../services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';
import { acCartGetAction } from '../acCart';

type TGiftCardProduct = (
  countryCode: string,
  languageCode: string,
  sessionId?: string,
) => ICallApiAction;
export const acGiftCardProduct: TGiftCardProduct = (countryCode, languageCode, sessionId) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getGiftCardUrl(), {
      country: countryCode,
      lang: languageCode,
      sessionId,
    }),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.GIFT_CARD_GET_PRODUCT_REQUEST,
      actions.GIFT_CARD_GET_PRODUCT_SUCCESS,
      actions.GIFT_CARD_GET_PRODUCT_FAILED,
    ],
  },
});

type acGiftCardProductsActionType = () => (dispatch, getState: () => IStore) => any;
export const acGiftCardProductAction: acGiftCardProductsActionType = () => (dispatch, getState) => {
  const {
    auth: { sessionId },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode) {
    return dispatch(acGiftCardProduct(countryCode, languageCode, sessionId ?? undefined));
  }
  return false;
};

export const acGiftCardInit = (params: string | string[] | undefined) => async (dispatch, getState: () => IStore) => {
  dispatch(acGiftCardInitStatus(true));
  const { pageData: { countryCode, languageCode } } = getState();
  await dispatch(acGiftCardGetDesignOptions(countryCode || 'EE', languageCode || 'et'));
  await dispatch(acGiftCardProductAction());
  if (params && typeof params === 'object' && params.length > 3) {
    await dispatch(acCartGetAction());
    const { cart: { form: { cartItems } } } = getState();
    if (cartItems.length > 0) {
      const giftCardGenerationCode = params[3];
      const giftCardToEdit = cartItems.find(
        (item) =>
          item.giftCardIssue &&
          item.giftCardIssue[EGiftCartIssueField.giftCardGenerationCode] === giftCardGenerationCode,
      );
      if (giftCardToEdit) {
        await dispatch(acGiftCardUpdate(giftCardToEdit));
      } else {
        await dispatch(acGiftCardSetDefaultDesign());
      }
    } else {
      await dispatch(acGiftCardSetDefaultDesign());
    }
  } else {
    await dispatch(acGiftCardSetDefaultDesign());
  }
  await dispatch(acGiftCardInitStatus(false));
};


export const acGiftCardSetDefaultDesign = () => async (dispatch, getState: () => IStore) => {
  const { giftCard: { giftCard, giftCardDesignOptions } } = getState();

  if (giftCard.giftCardIssue && giftCardDesignOptions?.[0]?.giftCardDesignIdFk) {
    await dispatch(acGiftCardUpdate({
      giftCardIssue: {
        ...giftCard.giftCardIssue,
        [EGiftCartIssueField.giftCardDesign]: giftCardDesignOptions[0].giftCardDesignIdFk,
      },
    }));
  }
};


export const acGiftCardUpdate = (payload: Partial<ICartItem>): IGiftCardUpdateForm => ({
  payload,
  type: actions.GIFT_CARD_UPDATE_FORM,
});


type TGiftCardGetDesignOptions = (
  countryCode: string,
  languageCode: string,
) => ICallApiAction;
export const acGiftCardGetDesignOptions: TGiftCardGetDesignOptions = (countryCode, languageCode) => ({
  [CALL_API]: {
    endpoint: getGiftCardDesignOptionsUrl({ countryCode, languageCode }),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.GIFT_CARD_GET_DESIGN_OPTIONS_REQUEST,
      actions.GIFT_CARD_GET_DESIGN_OPTIONS_SUCCESS,
      actions.GIFT_CARD_GET_DESIGN_OPTIONS_FAILED,
    ],
  },
});

const acGiftCardInitStatus = (payload: boolean): IGiftCardInitForm => ({
  payload,
  type: actions.GIFT_CARD_INIT_FORM,
});

