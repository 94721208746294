import React, { ReactNode } from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';

import useStyles from './styles';

interface IModalTemplateProps {
  body: ReactNode;
  description: string | ReactNode;
  header: ReactNode;
  title: string | ReactNode;
}

const ModalTemplate: React.FunctionComponent<IModalTemplateProps> = ({ body, description, header, title }) => {
  const classes = useStyles();

  return (
    <ErrorBoundary>
      <div className={classes.header}>
        {header}
      </div>
      <div className={classes.body}>
        <div className={classes.bodyText}>
        <div className={classes.title}>
          {title}
        </div>
        <div className={classes.description}>
          {description}
        </div>
        </div>
        <div className={classes.options}>
        {body}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ModalTemplate;
