import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { mobileMode } from '@constants/config';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme) => ({
  backdrop: {
    backdropFilter: 'blur(4px)',
    background: 'rgba(255, 255, 255, 0.64)',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
  },
  bodyText: {
    display: 'flex',
    flex: '1 1 20%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  description: {
    color: ColorsPalette.primary,
    fontFamily: FontCollection.raleway,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '124%',
    marginBottom: '30px',
    maxWidth: '416px',
    textAlign: 'center',
  },
  header: {
    [theme.breakpoints.up('md')]: {
      marginBottom: 24,
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: 56,
    justifyContent: 'space-between',
    marginBottom: 40,
    padding: '8px',
    width: '100%',
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  options: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flex: '1 1 60%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      minHeight: 320,
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      borderRadius: 4,
      maxWidth: 768,
      minHeight: 560,
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      paddingBottom: 60,
    },
    alignItems: 'center',
    backgroundColor: ColorsPalette.neutral2,
    border: '0',
    boxShadow: theme.shadows[0],
    display: 'flex',
    flexDirection: 'column',
    height: mobileMode ? '100%' : 'auto',
    justifyContent: 'flex-start',
    outline: 'none',
    position: 'relative',
    width: '100%',
  },
  title: {
    color: ColorsPalette.primary,
    fontFamily: FontCollection.inter,
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '124%',
    margin: 0,
    textAlign: 'center',
  },
}));
