import cookie from 'js-cookie';
import { ActionCreator } from 'redux';

import { acSetRecentlyViewedState } from '@actions/acCommon';
import * as actions from '@constants/actions/productDetails';
import { ERedisCacheKey } from '@constants/cacheCallApi';
import { anyProductWasViewed } from '@constants/index';
import { RequestMethods } from '@constants/types';
import { getProductsByIdsUrl, markProductAsViewedUrl } from '@constants/urls';
import { ESessionStatuses } from '@interfaces/fetchService';
import { ISeo } from '@interfaces/pageData/reducer';
import { IProductColor, IProductInList, IProductPicture, IProductSize } from '@interfaces/product';
import {
  IProductDetailsChangeOptions, IProductDetailsPreFill,
  IProductDetailsUnmount,
  IProductDetailsUnselectedError,
} from '@interfaces/productDetails/actions';
import { IStore } from '@interfaces/store';
import { getColorsWithSizes } from '@utils/common';
import { getProductData } from '@utils/gtag';
import { acCustomerShipmentMethodsGet } from 'actions/acAccount';
import { acStoresGetCountries } from 'actions/acStores';
import { CALL_AD, ICallADPayload } from '../../services/AnalyticEvent';
import { EAnalyticEventName } from '../../services/AnalyticEvent/types';
import { CALL_API } from '../../services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';
import { onPageDataSetSEOAction } from '../acPageData';

export const acProductDetailsChangeOptions: (options: {
  color: IProductColor | null;
  size: IProductSize | null;
}) => IProductDetailsChangeOptions = (options) => ({
  payload: options,
  type: actions.PRODUCT_DETAILS_CHANGE_OPTION,
});

type TGetCart = (params: { country: string; lang: string; productId: number | string }) => any;
export const acProductDetailsGet: TGetCart = ({ country, lang, productId }) => ({
  [CALL_API]: {
    cache: true,
    cacheOptions: ERedisCacheKey.default,
    endpoint: addParamsToUrl(getProductsByIdsUrl(), {
      country,
      includeRecommends: true,
      lang,
      productId,
    }),
    facebook: true,
    method: RequestMethods.GET_METHOD,
    types: [
      actions.PRODUCT_DETAILS_GET_REQUEST,
      actions.PRODUCT_DETAILS_GET_SUCCESS,
      actions.PRODUCT_DETAILS_GET_FAILED,
    ],
  },
});

export const acProductDetailsInit = () => async (dispatch, getState: () => IStore) => {
  const {
    pageData: {
      countryCode,
      data: { productId },
      languageCode,
    },
  } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };

  if (productId) {
    await Promise.allSettled([
      dispatch(acProductDetailsGet({ ...locale, productId })),
      dispatch(acStoresGetCountries(locale)),
      dispatch(acCustomerShipmentMethodsGet()),
    ]);

    // dispatch(acProductMarkAsViewed({ ...locale, productId }));
    const { productDetails: { activeProduct: { color }, productDetails } } = getState();
    const colorsWithSizes = getColorsWithSizes(productDetails?.colors);
    if (!!colorsWithSizes.length) {
      const defaultColor =
        color || colorsWithSizes.find(({ productId: colorProductId }) => Number(colorProductId) === Number(productId)) || colorsWithSizes[0];
    //  const defaultSize = defaultColor.sizes.length === 1 ? defaultColor.sizes[0] : null;
      const defaultSize = defaultColor.sizes[0] || null;
      await dispatch(acProductDetailsChangeOptions({ color: defaultColor, size: defaultSize }));
    }
    await dispatch(acDefineProductDetailSeo());
  }
};

export const acDefineProductDetailSeo = () => async (dispatch, getState: () => IStore) => {
  const { productDetails: { activeProduct, productDetails }, pageData: { data, categoryWrapped } } = getState();
  const pageDataSeo = data.seo;
  const pageDataTitle = pageDataSeo?.title;
  const currentColor: IProductColor | null = activeProduct?.color || productDetails?.colors[0] || null;
  const pictures: IProductPicture[] | null = currentColor?.pictures || null;
  const mainPicture = pictures !== null ? pictures[0] : null;
  const productData = getProductData(productDetails, currentColor);

  const mainSexCategory = categoryWrapped.find((categoryWr) => Number(categoryWr.sexId) === Number(productDetails?.sex.sexId));
  const mainCategory = mainSexCategory?.categories.find((categorySex) => Number(categorySex.categoryId) === Number(productDetails?.category.parent.categoryId)) || null;
  let category = '';
  if (mainCategory && mainCategory.seoAttributes?.seoUrlSlug) {
    category = mainCategory.categoryLocal;

    const subCategory = mainCategory?.children?.find((categorySex) => Number(categorySex.categoryId) === Number(productDetails?.category.categoryId)) || null;
    if (subCategory && subCategory?.categoryId) {
      category = subCategory.categoryLocal;
    }
  }

  const title =
    (productData?.variant || '') +
    ' ' +
    (productData?.model || '') +
    ' ' +
    (productData?.brand || '') +
    ', ' +
    (productData?.sex || '') +
    ' ' +
    (category || '') +
    ` ${pageDataTitle || '| Denim Dream E-pood'}`;

  const seo: ISeo = {
    image: mainPicture?.urlLarge || null,
    title,
  };
  await dispatch(onPageDataSetSEOAction(seo));
};

export const acProductDetailsUnselectedOptionsError: (state: boolean) => IProductDetailsUnselectedError = (state) => ({
  payload: state,
  type: actions.PRODUCT_DETAILS_UNSELECTED_ERROR,
});

export const acProductDetailsUnmount: ActionCreator<IProductDetailsUnmount> = () => ({
  type: actions.PRODUCT_DETAILS_UNMOUNT,
});

type TProductMarkAsViewed = (params: { country: string; lang: string; productId: number | string }, adPayload?: ICallADPayload) => any;
export const acProductMarkAsViewed: TProductMarkAsViewed = ({ country, lang, productId }, adPayload) => ({
  [CALL_AD]: {
    event: EAnalyticEventName.view_item,
    payload: adPayload,
  },
  [CALL_API]: {
    endpoint: addParamsToUrl(markProductAsViewedUrl(productId), {
      country,
      lang,
    }),
    facebook: true,
    method: RequestMethods.PUT_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    types: [
      actions.PRODUCT_DETAILS_MARK_REQUEST,
      actions.PRODUCT_DETAILS_MARK_SUCCESS,
      actions.PRODUCT_DETAILS_MARK_FAILED,
    ],
  },
});

export const acProductMarkAsViewedAction = () => (dispatch, getState: () => IStore) => {
  const {
    pageData: {
      countryCode,
      data: { productId },
      languageCode,
    },
    productDetails: { activeProduct: { color }, productDetails },
  } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  if (productId) {

    const currentColor: IProductColor | null = color || productDetails?.colors[0] || null;
    const productData = getProductData(productDetails, currentColor);
    return dispatch(acProductMarkAsViewed({ ...locale, productId }, { productData })).then(() => {
      cookie.set(anyProductWasViewed, '1');
      dispatch(acSetRecentlyViewedState());
    });
  }
};

export const acProductDetailsPreFillAction: (item: IProductInList) => IProductDetailsPreFill = (item) => ({
  payload: item,
  type: actions.PRODUCT_DETAILS_PREFILL,
});
