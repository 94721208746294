import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';

import LeftArrowSvg from '../../../../public/static/common/left-arrow.svg';
import { ColorsPalette } from '@themes/colors';

const styles = makeStyles<Theme>((theme) => ({
  button: {
    alignItems: 'center',
    background: ColorsPalette.neutral2,
    border: '0',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    outline: 'none',
    padding: '12px',
    width: 40,
  },
  text: {
    display: 'block',
    position: 'relative',
    textAlign: 'center',
  },
}));

interface IBackButtonWithTextProps {
  clickHandler: () => void;
  text: string;
}

const BackButtonWithText: React.FunctionComponent<IBackButtonWithTextProps> = ({
  clickHandler,
  text,
}) => {
  const classes = styles();

  return (
    <>
      <button className={classes.button} onClick={clickHandler}>
        <LeftArrowSvg />
      </button>
      <div className={classes.text}>{text}</div>
      <div className={classes.button} />
    </>
  );
};

export default BackButtonWithText;
