import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from '@hooks/useTranslation';
import { IGetCustomerResponse } from '@interfaces/auth/reducer';
import { IStore } from '@interfaces/store';
import { getAccountColor } from '@utils/siteSettings';

import styles from './styles';

interface IAccountMenuUserBonusesProps {
  primaryColor?:boolean;
}

const AccountMenuUserBonuses: React.FunctionComponent<IAccountMenuUserBonusesProps> = ({primaryColor = false}) => {

  const user = useSelector<IStore, IGetCustomerResponse | null>((state) => state.auth.user);
  const data = useSelector((state: IStore) => state.pageData.data);
  const backgroundColor = getAccountColor(data);
  const classes = styles({
    backgroundColor,
  });

  const { t } = useTranslation();

  return user ? (
    <div className={classNames(classes.menuUserBonus, classes.menuUserEdge, {[classes.menuUserBonusPrimary]: primaryColor})}>
      <div className={classNames(classes.menuUserBonusLabel)}>{t('account.bonusMoney_balance')}</div>
      <div className={classNames(classes.menuUserBonusValue)}>{user?.data?.attributes?.balance || 0.0}€
      </div>
    </div>

  ) : null;
};


export default AccountMenuUserBonuses;
