import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) => ({
  button: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      width: '216px',
    },
  },
  customerSupport: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  customerSupportButton: {
    marginBottom: '18px',
    marginTop: '40px',
  },
  customerSupportMain: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    maxHeight: '50%',
    paddingTop: '41%',
  },
  customerSupportStatus: {
    marginBottom: '15px',
  },
  customerSupportTitle: {
    marginBottom: '20px',
  },
  phone: {
    color: 'inherit',
    textDecoration: 'underline',
  },
}));
