import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acSearchPanelClose } from '@actions/acSearch';
import MobileSearchGender from '@components/layout/header/AppBar/Search/MobileSearch/genderSelect';
import SearchInputWithButton from '@components/layout/header/AppBar/Search/SearchInput/SearchInputContainer';
import { CloseIcon } from '@icons/Close';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

const SearchInfo = dynamic(() => import ('../SearchInfo/SearchInfo'));

import useStyles from './styles';

const MobileSearch: React.FunctionComponent<{}> = () => {
  const classes = useStyles();

  const isOpenPanel = useSelector((state: IStore) => state.search.isOpenPanel);
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const closeSearchPanel = useCallback(() => dispatch(acSearchPanelClose()), [dispatch]);
  const mobilePageUpdate = useSelector((state: IStore) => state.page.mobilePageUpdate);

  useEffect(() => {
    if (isOpenPanel) {
      closeSearchPanel();
    }
  }, [mobilePageUpdate]);

  const allowScroll = () => {
    const nextDiv = document.querySelector('#__next');
    if (nextDiv) {
      nextDiv.classList.remove('searchOpened');
    }
  };

  const blockScroll = () => {
    const nextDiv = document.querySelector('#__next');
    if (nextDiv) {
      nextDiv.classList.add('searchOpened');
    }
  };

  useEffect(() => {
    if (isOpenPanel) {
      blockScroll();
    } else {
      allowScroll();
    }
    return () => allowScroll();
  }, [isOpenPanel]);

  return (
    <div className={classNames(classes.mobileSearchContainer, { [classes.mobileSearchContainerShow]: isOpenPanel })}>
      {isOpenPanel && (
        <>
          <MobileSearchGender />
          <div className={classes.contentContainer}>
            <div className={classes.contentContainerHeader}>
              <div className={classes.closeContainer}>
                <CloseIcon onClick={closeSearchPanel} />
              </div>
              <SearchInputWithButton className={classes.padding16} />
            </div>
            <SearchInfo />
          </div>
        </>
      )}
    </div>
  );
};

export default MobileSearch;
