import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';
import { TypographyCollection } from '@themes/font';
import { zIndex } from '@themes/zIndex';

export default makeStyles<Theme>((theme) =>
  createStyles({
    homeDelivery: {
      '& svg path': {
        stroke: ColorsPalette.newDark,
      },
      '&:hover': {
        color: ColorsPalette.hoverActionCTA,
      },
      alignItems: 'center',
      background: ColorsPalette.newLight,
      borderRadius: '0.75rem',
      color: ColorsPalette.newDark,
      display: 'flex',
      height: '3rem',
      marginLeft: 'auto',
      marginTop: 'auto',
      paddingLeft: '1rem',
      width: '100%',
    },
    iconContainer: {
      marginRight: '0.5rem',
    },
    label: {
      [theme.breakpoints.up('md')]: {
        ...{
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        ...TypographyCollection.BodyBoldS,
      },
      [theme.breakpoints.down('lg')]: {
        ...{
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        ...TypographyCollection.HLinkBoldXS,
      },
    },
    labelContainer: {
      marginLeft: '0.5rem',
    },
    linksContainer: {
      alignContent: 'space-between',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    linksContainerLeft: {
      display: 'flex',
      flexDirection: 'column',
    },
    megaMenu: {
      '& + .menuButton': {
        '& + .megaMenuBackground': {
          background: 'rgba(0, 0, 0, 0.24)',
          height: '200vh',
          left: '0',
          position: 'absolute',
          top: '2.5rem',
          width: '100%',
          zIndex: '5',
        },
        '& a, .element-text': {
          color: ColorsPalette.primary,
        },
        background: ColorsPalette.neutral2,
        cursor: 'pointer',
      },
      background: ColorsPalette.neutral2,
      borderBottom: `2px solid ${ColorsPalette.neutral1}`,
      borderBottomLeftRadius: '0.75rem',
      borderBottomRightRadius: '0.75rem',
      borderLeft: `2px solid ${ColorsPalette.neutral1}`,
      borderRight: `2px solid ${ColorsPalette.neutral1}`,
      color: ColorsPalette.primary,
      cursor: 'default',
      display: 'flex',
      left: '50%',
      maxWidth: '90rem',
      padding: '3rem 5rem 2rem',
      position: 'absolute',
      top: '2.5rem',
      transform: 'translateX(-50%)',
      width: '100%',
      zIndex: zIndex.megaMenu,

      [theme.breakpoints.down('lg')]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    megaMenuContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
    },
    signUp: {
      width: 'calc(100% - 20px)',
      [theme.breakpoints.up('md')]: {
        ...{
          '& div': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          },
          alignItems: 'center',
          border: `1px solid ${ColorsPalette.neutral1}`,
          borderRadius: '0.75rem',
          display: 'flex',
          height: '3rem',
          justifyContent: 'space-between',
          marginTop: 4,
          paddingLeft: '1rem',
          paddingRight: '0.25rem',
        },
        ...TypographyCollection.HLinkBoldS,
      },
      [theme.breakpoints.down('lg')]: {
        ...{
          '& div': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          },
          alignItems: 'center',
          border: `1px solid ${ColorsPalette.neutral1}`,
          borderRadius: '0.75rem',
          display: 'flex',
          height: '3rem',
          justifyContent: 'space-between',
          paddingLeft: '1rem',
          paddingRight: '0.25rem',
        },
        ...TypographyCollection.HLinkBoldXS,
      },
    },
    signUpButton: {
      '&:hover': {
        background: ColorsPalette.hoverActionCTA,
      },
      background: ColorsPalette.primary,
      borderRadius: '0.75rem',
      color: ColorsPalette.neutral2,
      cursor: 'pointer',
      height: '2.5rem',
      padding: '0 8px',
    },
    videoContainer: {
      '& iframe': {
        marginBottom: 20,
        maxWidth: '100%',
      },
      '& img': {
        maxWidth: '100%',
      },
      display: 'flex',
      flex: '1 1 41%',
      flexDirection: 'column',
      maxWidth: '41%',
    },
  }),
);
