import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

const styles = makeStyles<Theme>((theme) => ({
  button: {
    alignItems: 'center',
    background: '#FFFFFF',
    border: '2px solid #000000',
    borderRadius: '100px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    justifyContent: 'space-between',
    margin: '0 auto 16px auto',
    outline: 'none',
    position: 'relative',
    width: '179px',
  },
  edge: {
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: 20,
  },
  flag: {
    '& img': {
      '-ms-interpolation-mode': 'nearest-neighbor',
      height: '20px',
      imageRendering: 'pixelated',
      verticalAlign: 'baseline'
    },
    background: '#CCC',
    border: 0,
    borderRadius: '20px',
    display: 'block',
    flex: '0 0 20px',
    height: '20px',
    justifyContent: 'center',
    marginLeft: 7,
    overflow: 'hidden',
    width: '20px',
  },
  text: {
    alignItems: 'center',
    color: ColorsPalette.primary,
    display: 'flex',
    flex: '1 0 auto',
    fontFamily: FontCollection.raleway,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    justifyContent: 'center',
    lineHeight: '124%',
    textAlign: 'center',
  },
}));

const Button = ({ flag, text, onClickHandler }) => {
  const classes = styles();

  return (
    <button id={'country-language-select-' + text} className={classes.button} onClick={onClickHandler}>
      <div className={classes.flag}>
        <img src={flag} alt={text} />
      </div>
      <div className={classes.text}>{text}</div>
      <div className={classes.edge} />
    </button>
  );
};

export default Button;
