import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import classNames from 'classnames';
import React from 'react';

import Hidden from '@components/common/Hidden';
import { useTranslation } from '@hooks/useTranslation';
import SortIcon from '@icons/Sort';

import { StyledMenuItem } from './selectItem';
import styles from './styles';

interface ISortOption {
  name: string | JSX.Element;
  value: string | number;
}

interface SelectSmallProps {
  onChange: (e: SelectChangeEvent) => void;
  options: ISortOption[];
  ref?: React.ForwardedRef<any>;
  selectClassName?: string;
  small?: boolean;
  value: any;
}

const SortSelect: React.FunctionComponent<SelectSmallProps> = React.forwardRef(({
  onChange,
  options,
  selectClassName,
  small,
  value,
}, ref) => {

  const { t } = useTranslation();

  {/*
  const showValueName = (valueP) => {
    const activeOption = options.find((option) => option.value === valueP);
    return activeOption ? activeOption.name : '';
  };
*/}
  const classes = styles();
  return (
    <FormControl classes={{ root: classNames(classes.formControl, { [classes.formControlSmall]: small }) }}
                 size="small">
      <Select
        classes={{
          select: classNames(classes.select, selectClassName),
        }}
        ref={ref}
        id="sort-select"
        inputProps={
          { className: classNames({ [classes.root]: !small, [classes.rootSmall]: small }) }
        }
        value={value}
        onChange={onChange}
        renderValue={(valueP) => small ? <SortIcon /> : (<><Hidden
          mdUp={true}><SortIcon className={classes.startIcon} /></Hidden>
          {/* <div className={classes.renderValue}>{showValueName(valueP)}</div> */}
          <div className={classes.renderValue}>{t('common.sort')}</div>
        </>)}

        IconComponent={small ? () => null : undefined}
      >
        {options.map((option, key) => <StyledMenuItem key={`sortOption${key}`}
                                                      value={option.value}>{option.name}</StyledMenuItem>)}
      </Select>
    </FormControl>
  );
});

SortSelect.displayName = 'SortSelect';
export default SortSelect;
