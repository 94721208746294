import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from '@hooks/useTranslation';
import { ESideMenuPage } from '@interfaces/page/store';
import { IStore } from '@interfaces/store';
import { usePageStore } from '@reducers/page/useState';

import SmileIconSvg from '../../../../public/static/mega-menu/smile.svg';
import MegaMenuStyles from './styles';

const MegaMenuSignUp: React.FunctionComponent<{}> = ({}) => {
  const classes = MegaMenuStyles();
  const { t } = useTranslation();
  const isAuthorized = useSelector((state: IStore) => state.auth.isAuthorized);

  const { onSideMenuPageHandle, onSideMenuHandle } = usePageStore();

  const onRegister = () => {
    onSideMenuHandle(true);
    onSideMenuPageHandle(ESideMenuPage.registration);
  };
  return (
    <div className={classes.signUp}>
      <div>
        <div className={classes.iconContainer}>
          <SmileIconSvg />
        </div>
        <div className={classes.labelContainer}>
          {isAuthorized ? t('common.shop_amongst_x_products_authorized') : t('common.shop_amongst_x_products')}
        </div>
      </div>
      {!isAuthorized && <div className={classes.signUpButton} onClick={onRegister}>{t('common.sign_up')}</div>}
    </div>
  );
};

export default MegaMenuSignUp;
