import { mobileMode } from '@constants/config';
import { headerHeightSm } from '@constants/styles';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { defaultTransition } from '@themes/transition';
import { zIndex } from '@themes/zIndex';

export default makeStyles<Theme>((theme) => ({
  closeContainer: {
    '& svg': {
      height: 14,
    },
    alignItems: 'center',
    display: 'flex',
    height: headerHeightSm,
    justifyContent: 'center',
    marginRight: -15,
    textAlign: 'center',
    width: headerHeightSm,
  },
  contentContainer: {
    paddingTop: mobileMode ? 16 : 0,
    position: 'relative',
  },
  contentContainerHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  genderSelect: {
    display: 'flex',
    flexDirection: 'row',
  },
  genderSelectButton: {
    display: 'flex',
    padding: '0 4px',
  },
  genderSelectTabsRoot: {
    width: '100%'
  },
  mobileSearchContainer: {
    // backdropFilter: 'blur(16px)',
    background: 'rgba(255, 255, 255, 0)',
    bottom: '0',
    height: 0,
    left: '0',
    overflow: 'auto',
    position: 'fixed',
    right: '0',
    top: '0',
    transition: defaultTransition,
    width: '100%',
    zIndex: zIndex.searchContainerMobile,
  },
  mobileSearchContainerShow: {
    '& $contentContainer': {
      paddingTop: 16,
    },
    background: ColorsPalette.neutral2,
    height: '100%',
  },
  mobileSearchHeaderApp: {
    background: 'rgba(255, 255, 255, 0)',
    position: 'relative',
    transition: 'all 0.5s ease-in-out',
    zIndex: zIndex.searchContainerMobile,
  },
  padding16: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));
