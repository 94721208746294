import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) => ({
  closeBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 20px 0 0 ',
    width: '100%',
  }
}));
