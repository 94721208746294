import React from 'react';

const ArrowNextIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg className={className} width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.328125 12.4204L1.50813 13.6004L8.10813 7.00039L1.50813 0.400391L0.328125 1.58039L5.74813 7.00039L0.328125 12.4204Z"
        fill="black" />
    </svg>

  );
};

export default ArrowNextIcon;
