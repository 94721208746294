import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { ReactNode, useContext } from 'react';
import { useSelector } from 'react-redux';

import SectionHeader from '@components/pages/account/common/SectionHeader';
import { PageTypes } from '@constants/pageTypes';
import { UseScrollContext } from '@context/useScroll';
import { IStore } from '@interfaces/store';
import styles from './styles';

interface IHeaderAppProps {
  border?: boolean;
  className?: string;
  disableAction?: boolean;
  hideAction?: boolean;
  title?: JSX.Element | string | null;
  leftAction?: () => void;
  rightAction?: (e?) => void;
  rightActionLabel?: string | ReactNode;
  sticky?: boolean;
}

const HeaderApp: React.FunctionComponent<IHeaderAppProps> = ({
  border = false,
  className,
  disableAction,
  hideAction,
  title,
  leftAction,
  rightAction,
  rightActionLabel,
  sticky = true,
}) => {
  const { back } = useRouter();
  const classes = styles();

  const pageType = useSelector((state: IStore) => state.pageData.type);
  const shallowPageChange = useSelector<IStore, boolean>((state) => state.pageData.shallowPageChange);
  const mobilePageUpdate = useSelector<IStore, boolean>((state) => state.page.mobilePageUpdate);

  const { fixElement, unpinElement } = useContext(UseScrollContext);
  const rulePageUpdate = !shallowPageChange && !mobilePageUpdate;
  return <>
    <div className={classNames(classes.headerApp, className, {
      [classes.headerAppFix]: sticky && fixElement && ![PageTypes.stores, PageTypes.account].includes(pageType as PageTypes) && rulePageUpdate,
      [classes.headerAppBorder]: border,
      [classes.headerAppUnpin]: unpinElement && rulePageUpdate,
    })}>
      <SectionHeader
        hideAction={hideAction}
        disableAction={disableAction}
        title={title || ''}
        leftAction={leftAction ? leftAction : back}
        rightAction={rightAction}
        rightActionLabel={rightActionLabel}
      />
    </div>
  </>;
};

export default HeaderApp;
