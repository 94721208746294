import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


import { LOOK_BOOK_VIEW_DETAIL, LOOK_BOOK_VIEW_LIST } from '@components/pages/LookBook';
/*
import AccountConfirmPage from '@components/pages/accountConfirm';
import Blog from '@components/pages/blog/Blog';
import Blogs from '@components/pages/blogs/Blogs';
import ChangeEmail from '@components/pages/changeEmail';
import ContactsPage from '@components/pages/contacts';
import Content from '@components/pages/Content/Content';
import CountrySelect from '@components/pages/CountrySelect';
import ErrorPage from '@components/pages/Error';
import Family from '@components/pages/Family';
import FaqPage from '@components/pages/faq';
import GiftCard from '@components/pages/giftCard';
import Home from '@components/pages/home/Home';
import LoadingPage from '@components/pages/Loading';
import LookBook, { LOOK_BOOK_VIEW_DETAIL, LOOK_BOOK_VIEW_LIST } from '@components/pages/LookBook';
import MenuPage from '@components/pages/menu';
import OrderConfirmed from '@components/pages/orderConfirmed';
import Products from '@components/pages/productsList';
import StoresPage from '@components/pages/stores';
import WishListPage from '@components/pages/wishList';


import LotteryPage from './lottery/register';
*/

import { mobileSession } from '@constants/config';
import { PageTypes } from '@constants/pageTypes';
import { IContextQuery } from '@interfaces/context/reducer';
import { IStore } from '@interfaces/store';

const Product = dynamic(() => import ('@components/pages/product'));
// import AccountPage from '@components/pages/account';
const AccountPage = dynamic(() => import ('@components/pages/account'));
// import Cart from '@components/pages/cart/cart';
const Cart = dynamic(() => import ('@components/pages/cart/cart'));


const AccountConfirmPage = dynamic(() => import ('@components/pages/accountConfirm'), {});
const Blog = dynamic(() => import ( '@components/pages/blog/Blog'));
const Blogs = dynamic(() => import ( '@components/pages/blogs/Blogs'));
const ChangeEmail = dynamic(() => import ( '@components/pages/changeEmail'));
const ContactsPage = dynamic(() => import ( '@components/pages/contacts'));
const Content = dynamic(() => import ( '@components/pages/Content/Content'));
const CountrySelect = dynamic(() => import ( '@components/pages/CountrySelect'));
const ErrorPage = dynamic(() => import ( '@components/pages/Error'));
const Family = dynamic(() => import ( '@components/pages/Family'));
const FaqPage = dynamic(() => import ( '@components/pages/faq'));
const GiftCard = dynamic(() => import ( '@components/pages/giftCard'));
const Home = dynamic(() => import ( '@components/pages/home/Home'));
const LoadingPage = dynamic(() => import ( '@components/pages/Loading'));
const LookBook = dynamic(() => import ( '@components/pages/LookBook'));
const MenuPage = dynamic(() => import ( '@components/pages/menu'));
const OrderConfirmed = dynamic(() => import ( '@components/pages/orderConfirmed'));
const Products = dynamic(() => import ( '@components/pages/productsList'));
const StoresPage = dynamic(() => import ( '@components/pages/stores'));
const WishListPage = dynamic(() => import ( '@components/pages/wishList'));
const LotteryPage = dynamic(() => import ( './lottery/register'));


const MainComponent: React.FunctionComponent<{}> = () => {
  const [lastPage, changePage] = useState<PageTypes | null>(PageTypes.home);

  const type: PageTypes | null = useSelector((state: IStore) => state.pageData.type || null);
  const sessionLoading: boolean = useSelector((state: IStore) => state.page.sessionLoading);
  const contextQuery: IContextQuery = useSelector((state: IStore) => state.context.query);

  useEffect(() => {
    if (type !== PageTypes.loading) {
      changePage(type);
    }
  }, [type]);
  if (!mobileSession || (!sessionLoading && (contextQuery?.params?.length ?? 0 > 2))) {

    switch (type) {
      case PageTypes.blog:
        return <Blog />;
      case PageTypes.blogs:
        return <Blogs />;
      case PageTypes.content:
      case PageTypes.lottery_success:
        return <Content />;
      case PageTypes.countrySelect:
        return <CountrySelect />;
      case PageTypes.faq:
        return <FaqPage />;
      case PageTypes.family:
        return <Family />;
      case PageTypes.fresh:
      case PageTypes.productsList:
      case PageTypes.outlet:
      case PageTypes.kids:
        return <Products />;
      case PageTypes.productDetail:
        return <Product />;
      case PageTypes.lookbookList:
        return <LookBook view={LOOK_BOOK_VIEW_LIST} />;
      case PageTypes.lottery:
        return <LotteryPage />;
      case PageTypes.lookbookDetail:
        return <LookBook view={LOOK_BOOK_VIEW_DETAIL} />;
      case PageTypes.account:
      case PageTypes.account_orders:
      case PageTypes.account_delivery:
      case PageTypes.account_details:
      case PageTypes.account_linkedAccounts:
      case PageTypes.account_changePassword:
      case PageTypes.account_payment:
      case PageTypes.account_notifications:
      case PageTypes.account_help:
      case PageTypes.account_settings:
      case PageTypes.account_loyalty:
        return <AccountPage />;
      case PageTypes.account_confirm:
        return <AccountConfirmPage />;
      case PageTypes.cart:
        return <Cart />;
      case PageTypes.changeEmail:
        return <ChangeEmail />;
      case PageTypes.contacts:
        return <ContactsPage />;
      case PageTypes.giftCard:
        return <GiftCard />;
      case PageTypes.home:
        return <Home />;
      case PageTypes.order_confirmed:
        return <OrderConfirmed />;
      case PageTypes.stores:
        return <StoresPage />;
      case PageTypes.wishlist:
        return <WishListPage />;
      case PageTypes.loading:
        return <LoadingPage lastPage={lastPage} />;
      case PageTypes.menu:
        return <MenuPage />;
      case null:
      case PageTypes.error:
        return <ErrorPage />;
      default:
        return <Home />;
    }

  } else {
    return <LoadingPage />;
  }
};

export default MainComponent;
